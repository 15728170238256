import { useEffect, useState } from "react";
import {
  Container,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import api from "./lib/api-client";
import { loadStore, selectRoot, setGlobalAlert, setUserPreference, setTagForPresentationSearch, setShowRecommended } from "./rootSlice";
import PaperListOR from "./components/session/PaperListOR";
import PaperCards from "./components/session/PaperCards";
import { useHistory, useParams } from "react-router-dom";
import { Input, Pagination, Radio, Select, Tag } from 'antd';
import 'antd/lib/radio/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/pagination/style/index.css';
import 'antd/lib/select/style/index.css';
import { getTagColor } from "./lib/utility";
import useBreakpoint from "./lib/hooks/useBreakpoint";

const SearchInput = ({ setSearchTerm, setSearchMode }) => {
  return (
    <div className="mb-2">
      <Input.Search
        allowClear={true}
        onSearch={e => setSearchTerm(e)}
        enterButton="Search"
        addonBefore={<Select defaultValue="everything" onChange={(e) => setSearchMode(e)}>
          <Select.Option value="everything">Everything</Select.Option>
          <Select.Option value="authors">Author Name</Select.Option>
          <Select.Option value="keywords">Keywords</Select.Option>
        </Select>}
      ></Input.Search>
    </div>
  )
}

const PresentationTypeDropdown = ({
  presentationType,
  setPresentationType,
}) => {
  return (
    <div className="mb-2">
      View:{' '}
      <Radio.Group defaultValue={presentationType} buttonStyle="solid" onChange={e => setPresentationType(e.target.value)}>
        <Radio.Button value="All">All</Radio.Button>
        <Radio.Button value="expo">Expo</Radio.Button>
        <Radio.Button value="poster">Poster</Radio.Button>
        <Radio.Button value="presentation">Presentation</Radio.Button>
        <Radio.Button value="qa">{"Q&A"}</Radio.Button>
      </Radio.Group>
    </div>
  )
};

const ViewDropdown = ({
  view,
  setView,
}) => {
  return (
    <div className="mb-2">
      View:{' '}
      <Radio.Group defaultValue={view} buttonStyle="solid" onChange={e => setView(e.target.value)}>
        <Radio.Button value="list">List</Radio.Button>
        <Radio.Button value="card">Card</Radio.Button>
      </Radio.Group>
    </div>
  );
};

const TagSearch = ({ tagContent, clearTagSearch }) => {
  if (!tagContent) return null
  return (
    <>
      <style>
        {
          `
                .ant-tag-close-icon{
                    vertical-align:baseline;
                }
              `
        }
      </style>
      <Tag color={getTagColor(tagContent)} closable onClose={clearTagSearch}>{tagContent}</Tag>
    </>
  )
}

const Presentations = () => {
  const {
    conferenceGroupInfo,
    userPreference,
    userInfo,
    tagForPresentationSearch,
    // tagsChanged,
    showRecommended
  } = useSelector(selectRoot);
  const [papers, setPapers] = useState([]);
  // const [totalPages, setTotalPages] = useState(10);
  const [totalPaperCount, setTotalPaperCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  let { groupId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [numPerPage, setNumPerPage] = useState(9);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchMode, setSearchMode] = useState("everything");
  const [presentationType, setPresentationType] = useState(userPreference.presentationType ?? "All");
  const [view, setView] = useState(userPreference.presentationView ?? "list")
  const issm = useBreakpoint("sm")

  const getPapersForPage = async (pageNum) => {
    if (!conferenceGroupInfo.id) {
      setPapers([]);
      // setTotalPages(0);
      return;
    }
    setIsLoading(true);
    setCurrentPage(pageNum);
    try {
      let result;
      if (tagForPresentationSearch) {
        result = await api.get('/tags', {
          tag: tagForPresentationSearch,
          details: "forumContent",
          invitation: `${conferenceGroupInfo.id}/-/.*`,
          limit: numPerPage,
          offset: numPerPage * (pageNum - 1),
        }, {
          accessToken: userInfo.token
        })
        setPapers(result.tags.map(p => p.forumContent).filter(q => q.invitation === `${conferenceGroupInfo.id}/-/Presentation`)); // filter because mix of sessions and papers
        // setTotalPages(Math.ceil(result.count / numPerPage));
        setTotalPaperCount(result.count)
        return;
      }
      else if (searchTerm.length) {
        result = await api.get("/notes/search", {
          invitation: `${conferenceGroupInfo.id}/-/Presentation`,
          limit: numPerPage,
          offset: numPerPage * (pageNum - 1),
          query:
            presentationType === "All"
              ? searchTerm
              : `content.presentation_type:${presentationType} AND ${searchTerm}`,
          content: searchMode !== "everything" ? searchMode : undefined
        });
      } else {
        result = await api.get("/notes", {
          invitation: `${conferenceGroupInfo.id}/-/Presentation`,
          limit: numPerPage,
          details: 'tags',
          offset: numPerPage * (pageNum - 1),
          "content.presentation_type":
            presentationType === "All" ? undefined : presentationType,
        }, { accessToken: userInfo.token });
      }
      setTotalPaperCount(result.count)
      setPapers(result.notes);
      // setTotalPages(Math.ceil(result.count / numPerPage));
    } catch (error) {
      dispatch(setGlobalAlert({ type: "error", content: error.message }))
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewChange = (view) => {
    setView(view)
    dispatch(setUserPreference({ name: "presentationView", value: view }))
  }
  const handlePresentationTypeChange = (presentationType) => {
    setPresentationType(presentationType)
    dispatch(setUserPreference({ name: "presentationType", value: presentationType }))
  }

  const setRecommendationTag = async () => {
    try {
      const result = await api.get('/tags', {
        tag: "Recommended",
        invitation: `${conferenceGroupInfo.id}/-/Recommendation`
      }, {
        accessToken: userInfo.token
      })
      if (result.count !== 0) dispatch(setTagForPresentationSearch("Recommended"))
    } catch (error) {
      dispatch(setGlobalAlert({ type: "error", content: error.message }))
    }
  }

  useEffect(() => {
    if (!groupId) return;
    if (Object.keys(conferenceGroupInfo).length && conferenceGroupInfo.id === groupId) return;
    dispatch(loadStore({ id: groupId, history: history }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    getPapersForPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conferenceGroupInfo, numPerPage, searchTerm, presentationType, tagForPresentationSearch]);

  useEffect(() => { //changing search mode alone should not trigger search
    if(!searchTerm) return;
    getPapersForPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMode]);

  useEffect(() => {
    // show recommended papers on initial load
    if (userInfo?.token && showRecommended) setRecommendationTag()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])

  return (
    <Container className="page-presentations">
      <Row className="d-flex justify-content-between mb-2 flex-lg-row flex-lg-row flex-xl-row flex-column mx-sm-2 m-2">
        <TagSearch tagContent={tagForPresentationSearch} clearTagSearch={() => { dispatch(setTagForPresentationSearch('')); if (tagForPresentationSearch === "Recommended") dispatch(setShowRecommended()) }} />
      </Row>
      <Row className="d-flex justify-content-between flex-lg-row flex-lg-row flex-xl-row flex-column mx-sm-2 m-2">
        <SearchInput setSearchTerm={setSearchTerm} setSearchMode={setSearchMode} />
        <PresentationTypeDropdown
          presentationType={presentationType}
          setPresentationType={handlePresentationTypeChange}
        />
        <ViewDropdown view={view} setView={handleViewChange} />
      </Row>
      <Row className="justify-content-center my-5 mx-1">
        {
          view === 'card'
            ? <PaperCards notes={papers} isLoading={isLoading} shouldFilterByTag={{ filter: true, prompt: false }} />
            : <PaperListOR notes={papers} isLoading={isLoading} />
        }
      </Row>
      <Row className="justify-content-center">
        {totalPaperCount !== 0 &&
          <Pagination
            responsive={true}
            hideOnSinglePage={true}
            total={totalPaperCount}
            defaultCurrent={1}
            current={currentPage}
            defaultPageSize={9}
            simple={issm ? false : true}
            pageSizeOptions={[9, 21, 36]}
            showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} papers`}
            onShowSizeChange={(_, size) => setNumPerPage(size)}
            onChange={(page, _) => getPapersForPage(page)}
          />}
      </Row>
    </Container>
  );
};

export default Presentations;
