import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "./lib/api-client";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import duration from "dayjs/plugin/duration";
import { validateToken } from "./lib/utility";
// import Cookies from 'universal-cookie'

dayjs.extend(weekOfYear);
dayjs.extend(duration);

// const accessTokenId = 'openreview.accessToken'

export const fetchSessionNotesByInvitation = createAsyncThunk(
  "sessions/fetchByInvitation",
  async (sessionInvitation) => {
    const result = await api.get("/notes", { invitation: sessionInvitation });
    return result.notes?.sort((p, q) => p.content.start - q.content.start);
  }
);

export const login = createAsyncThunk(
  "login",
  async ({ userId, password, saveUserId }, { dispatch, getState }) => {
    try {
      const result = await api.post("/login", { id: userId, password });
      //dispatch(setUserInfo(result))
      dispatch(loadStore({ id: getState().conferenceGroupInfo?.id }))
      dispatch(fetchBookmarks({ userToken: result.token }))
      dispatch(getRocketChatToken({ token: result.token }));
      if (saveUserId && userId !== getState().userPreference.savedUserId) {
        dispatch(clearUserPreference())
        dispatch(setVisitedPresentationIds({ type: "removeAll" }))
        dispatch(setUserPreference({ name: "savedUserId", value: userId }))
      }
      if (!saveUserId) {
        dispatch(setVisitedPresentationIds({ type: "removeAll" }))
        dispatch(clearUserPreference())
      }
      return result
    } catch (error) {
      dispatch(setGlobalAlert({
        type: 'error', content: `${error.message}`
      }))
    }
  });

export const logout = createAsyncThunk(
  "logout",
  async ({ _ }, { dispatch, getState }) => {
    console.log('dispatching logout rocket')
    dispatch(logoutRocketchat({}));
    dispatch(setUserInfo({}))
    if (!getState().userPreference.savedUserId) dispatch(clearUserPreference())
    dispatch(loadStore({ id: getState().conferenceGroupInfo.id }))
  })

export const getRocketChatToken = createAsyncThunk(
  "rocketchat/login",
  async ({ token }, { dispatch }) => {
    try {
      const result = await api.get("/user/rocketchat", {}, { accessToken: token });
      return result?.data
    } catch (error) {
      dispatch(setGlobalAlert({
        type: 'error', content: `chat login failed,${error.message}`, details: error.details
      }))
    }
  }
)

export const logoutRocketchat = createAsyncThunk(
  "rocketchat/logout",
  async ({ _ }, { getState }) => {
    try {
      const result = await api.post("/user/rocketchat/logout", getState().userInfo?.rocketChat, {})
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }
)

export const fetchChildGroupsByGroupId = createAsyncThunk(
  "navmenus/fetchByGroupId",
  async (groupId) => {
    const result = await api.get("/groups", {
      regex: `${groupId}/[^/]+$`,
      web: true,
      // cache: false
    }); // get only if has webfield to render
    return Promise.all(
      result.groups.map(async (group) => {
        const result = await api.get("/groups", {
          regex: `${group.id}/[^/]+$`,
          web: true,
          // cache: false
        });
        if (!result.groups.length)
          return {
            text:
              JSON.parse(group.web).menuText ??
              group.id.replace(`${groupId}/`, ""),
            groupId: group.id,
            order: JSON.parse(group.web).order,
            visible: JSON.parse(group.web).visible ?? false,
            rawWeb: JSON.parse(group.web)
          };
        return {
          text:
            JSON.parse(group.web).menuText ??
            group.id.replace(`${groupId}/`, ""),
          submenus: result.groups.map((p) => {
            return {
              text:
                JSON.parse(p.web).menuText ?? p.id.replace(`${groupId}/`, ""),
              groupId: p.id,
              order: JSON.parse(p.web).order,
              visible: JSON.parse(p.web).visible ?? false,
              rawWeb: JSON.parse(p.web)
            };
          }),
          groupId: group.id,
          order: JSON.parse(group.web).order,
          visible: JSON.parse(group.web).visible ?? false,
          rawWeb: JSON.parse(group.web)
        };
      })
    ).then((menus) => menus)
  }
);

export const addGroup = createAsyncThunk(
  "addGroup",
  async ({ groupIdToAdd, userToken }, { dispatch, getState }) => {
    const conferenceId = getState().conferenceGroupInfo.id.replace("/Virtual", "");
    try {
      await api.post(
        "/groups",
        {
          id: groupIdToAdd,
          signatures: [`${conferenceId}`],
          writers: [`${conferenceId}`],
          readers: ["everyone"],
          signatories: [],
          web: JSON.stringify({
            menuText: "dummy menu text",
            order: "0",
            layout: [
              {
                name: "Markdown",
                option: {
                  center: false,
                },
                content: "## some markdown content",
              },
            ],
          }),
        },
        { accessToken: userToken }
      );
      dispatch(fetchChildGroupsByGroupId(getState().conferenceGroupInfo.id));
      dispatch(setGlobalAlert({
        type: 'success', content: `${groupIdToAdd} is added`
      }))
    } catch (error) {
      dispatch(setGlobalAlert({
        type: 'error', content: `${error.message}`
      }))
      dispatch(fetchChildGroupsByGroupId(getState().conferenceGroupInfo.id));
    }

  }
);

export const updateGroup = createAsyncThunk(
  "updateGroup",
  async ({ groupIdToUpdate, web, userToken }, { dispatch, getState }) => {
    const conferenceId = getState().conferenceGroupInfo.id.replace("/Virtual", "");
    try {
      await api.post(
        "/groups",
        {
          id: groupIdToUpdate,
          signatures: [`${conferenceId}`],
          writers: [`${conferenceId}`],
          readers: ["everyone"],
          signatories: [],
          web: JSON.stringify(web),
        },
        { accessToken: userToken }
      );
      dispatch(fetchChildGroupsByGroupId(getState().conferenceGroupInfo.id));
      dispatch(setGlobalAlert({
        type: 'success', content: `${groupIdToUpdate} is saved`
      }))
    } catch (error) {
      dispatch(setGlobalAlert({
        type: 'error', content: `${error.message}`
      }))
      dispatch(fetchChildGroupsByGroupId(getState().conferenceGroupInfo.id));
    }

  }
);

export const deleteGroup = createAsyncThunk(
  "deleteGroup",
  async ({ groupIdToDelete, userToken }, { dispatch, getState }) => {
    try {
      await api.delete(
        "/groups",
        {
          id: groupIdToDelete,
        },
        { accessToken: userToken }
      );
      dispatch(fetchChildGroupsByGroupId(getState().conferenceGroupInfo.id));
      dispatch(setGlobalAlert({
        type: 'success', content: `${groupIdToDelete} is deleted`
      }))
    } catch (error) {
      dispatch(setGlobalAlert({
        type: 'error', content: `${error.message}`
      }))
      dispatch(fetchChildGroupsByGroupId(getState().conferenceGroupInfo.id));
    }
  }
);

export const addSession = createAsyncThunk(
  "addSession",
  async ({ sessionContent, userToken }, { dispatch, getState }) => {
    const conferenceId = getState().conferenceGroupInfo.id.replace("/Virtual", "");
    try {
      await api.post(
        "/notes",
        {
          invitation: `${getState().conferenceGroupInfo?.id}/-/Session`,
          readers: ['everyone'],
          writers: [`${conferenceId}`],
          signatures: [`${conferenceId}`],
          content: sessionContent,
        },
        { accessToken: userToken }
      );
      dispatch(fetchSessionNotesByInvitation(`${getState().conferenceGroupInfo?.id}/-/Session`));
      dispatch(setGlobalAlert({
        type: 'success', content: "session is added"
      }))
    } catch (error) {
      dispatch(setGlobalAlert({
        type: 'error', content: `${error.message}`
      }))
      dispatch(fetchChildGroupsByGroupId(getState().conferenceGroupInfo.id));
    }

  }
);

export const updateSession = createAsyncThunk(
  "updateSession",
  async ({ updatedSession, userToken }, { dispatch, getState }) => {
    try {
      await api.post(
        "/notes",
        updatedSession,
        { accessToken: userToken }
      );
      dispatch(setGlobalAlert({
        type: 'success', content: `${updatedSession.id} is saved`
      }));
      dispatch(fetchSessionNotesByInvitation(`${getState().conferenceGroupInfo?.id}/-/Session`));
    } catch (error) {
      dispatch(setGlobalAlert({
        type: 'error', content: `${error.message}`
      }))
    }

  }
);

export const deleteSession = createAsyncThunk(
  "deleteSession",
  async ({ sessionIdToDelete, userToken }, { dispatch, getState }) => {
    try {
      await api.delete(
        "/notes",
        {
          id: sessionIdToDelete,
        },
        { accessToken: userToken }
      );
      dispatch(setGlobalAlert({
        type: 'success', content: `${sessionIdToDelete} is deleted`
      }));
      dispatch(fetchSessionNotesByInvitation(`${getState().conferenceGroupInfo?.id}/-/Session`));
    } catch (error) {
      dispatch(setGlobalAlert({
        type: 'error', content: `${error.message}`
      }))
    }
  }
);

export const addBookmark = createAsyncThunk(
  "addBookmark",
  async ({ tag, invitation, forum, signatures, userToken }, { dispatch }) => {
    dispatch(setBookmarkItemsLoading({ type: "add", itemId: forum }))
    try {
      await api.post(
        "/tags", {
        tag: tag,
        invitation: invitation,
        forum: forum,
        signatures: signatures
      },
        {
          accessToken: userToken
        }
      )
      dispatch(setBookmarkItems({ type: "add", itemId: forum }))// update ui
      dispatch(fetchBookmarks({ userToken })) // sync with api
      dispatch(setTagsChanged({ type: "add", itemId: forum }))
    } catch (error) {
      console.log(error)
      console.log(error.message)
      dispatch(setGlobalAlert({
        type: 'error', content: `${error.message}`
      }))
    } finally {
      dispatch(setBookmarkItemsLoading({ type: "remove", itemId: forum }))
    }
  }
)

export const deleteBookmark = createAsyncThunk(
  "deleteBookmark",
  async ({ tagId, tag, invitation, forum, signatures, userToken }, { dispatch }) => {
    dispatch(setBookmarkItemsLoading({ type: "add", itemId: forum }))
    try {
      await api.post(
        "/tags", {
        id: tagId,
        tag: tag,
        ddate: +dayjs(),
        invitation: invitation,
        forum: forum,
        signatures: signatures
      },
        {
          accessToken: userToken
        }
      )
      dispatch(setBookmarkItems({ type: "remove", itemId: forum }))// update ui
      dispatch(fetchBookmarks({ userToken })) // sync with api
      dispatch(setTagsChanged({ type: "add", itemId: forum }))
    } catch (error) {
      dispatch(setGlobalAlert({
        type: 'error', content: `${error.message}`
      }))
    } finally {
      dispatch(setBookmarkItemsLoading({ type: "remove", itemId: forum }))
    }

  }
)

export const fetchBookmarks = createAsyncThunk(
  "fetchBookmarks",
  async ({ userToken }, { getState }) => {
    const result = await api.get("/tags", {
      invitation: `${getState()?.conferenceGroupInfo?.id}/-/Bookmark`,//"ICLR.cc/2020/Conference/Virtual/-/Bookmark",
    },
      {
        accessToken: userToken
      })
    return result.tags
  }
)

export const loadStore = createAsyncThunk(
  'loadStore',
  async ({ id, history }, { dispatch, getState }) => {
    const token = getState().userInfo.token
    if (token && !validateToken(token)) {
      dispatch(logout({}))
      dispatch(setGlobalAlert({ type: 'error', content: "session expired, please log in again." }))
    }
    // if(token && validateToken(token)){
    //   syncTokenToCookie(token)
    // }
    try {
      const result = await api.get(
        "/groups",
        {
          id: id,
          // cache: false,
        },
        { accessToken: token }
      )
      if (result.groups[0] && JSON.parse(result.groups[0]?.web)?.footer) { // has footer means is virtual group
        dispatch(setConferenceGroupInfo(result.groups[0]))
        dispatch(fetchSessionNotesByInvitation(`${result.groups[0].id}/-/Session`));
        dispatch(fetchChildGroupsByGroupId(result.groups[0].id));
      } else {
        dispatch(clearStore())
        history?.replace('/error', { message: `Conference ${id} does not exist`, extraMessage: "Please try another conference id" })
      }
    } catch (error) {
      dispatch(setGlobalAlert({ type: 'error', content: `${error.message}` }))
    }
  }
)

// export const syncTokenToCookie = (token)=>{
//   const cookie = new Cookies()
//   cookie.set(accessTokenId, token, { path: '/', sameSite: 'lax', domain: ".openreview.net" })
// }

// const clearCookie = ()=>{
//   const cookie = new Cookies()
//   cookie.remove(accessTokenId, { path: '/', sameSite: 'lax' })
// }

const formatSessionsInfo = (sessions) => {
  if (!Object.values(sessions).length) return [];
  return Object.values(sessions).map((p) => {
    const startObj = dayjs(Number(p.content.start));
    const endObj = dayjs(Number(p.content.end));
    const durationObj = dayjs.duration(endObj.diff(startObj));

    return {
      start: {
        hour: startObj.hour(),
        formattedHour: startObj.format("HH:mm"),
        week: startObj.week(),
        formattedFull: startObj.format(),
        formattedDate: startObj.format("dddd, MMMM D, YYYY"),
        unixmm: p.content.start
      },
      end: {
        hour: endObj.hour(),
        formattedHour: endObj.format("HH:mm"),
        formattedDate: endObj.format("dddd, MMMM D, YYYY"),
        formattedFull: endObj.format(),
        unixmm: p.content.end
      },
      duration: {
        minutes: durationObj.asMinutes(),
        hours: durationObj.asHours().toFixed(1),
      },
      description: p.content.description,
      title: p.content.title,
      id: p.id,
      type: p.content.type,
    };
  });
};

const formatCalendarInfo = (sessionsInfo) => {
  const startEndTimeStamps = sessionsInfo.map((p) => p.content.start);
  if (startEndTimeStamps.length === 0) return {
    firstSundayToDisplay: null,
    firstActualDay: null,
    totalDays: 0,
    weekFirstDays: [],
  }

  const minDate = dayjs(Math.min(...startEndTimeStamps));
  const maxDate = dayjs(Math.max(...startEndTimeStamps));

  const firstWeekFirstDay = minDate.startOf("week");
  const lastWeekFirstDay = maxDate.startOf("week");

  const weekFirstDays = [firstWeekFirstDay];
  while (weekFirstDays.length && weekFirstDays[weekFirstDays.length - 1].isBefore(lastWeekFirstDay)) {
    weekFirstDays.push(weekFirstDays[weekFirstDays.length - 1].add(1, 'week'))
  }
  weekFirstDays.pop();
  weekFirstDays.push(lastWeekFirstDay);

  const firstSunToDisplay = minDate.startOf("week");
  const totalDays =
    maxDate
      .hour(0)
      .minute(0)
      .second(0)
      .diff(minDate.hour(0).minute(0).second(0), "day") + 1; // to avoid hours being used for calculation
  return {
    firstSundayToDisplay: firstSunToDisplay.format(),
    firstActualDay: minDate.format(),
    totalDays: isNaN(totalDays) ? 0 : totalDays,
    weekFirstDays: weekFirstDays.map(p => p.valueOf()),
  };
};

export const initialState = {
  conferenceGroupInfo: {},
  sessionsInfo: [],
  sessionsInfoFormatted: [],
  calendarInfo: {},
  userInfo: {},
  dynamicMenus: [],
  calendarDayViewInfo: {
    showDayView: false,
  },
  //storeTimeStamp: null,
  //isStoreStale: true,
  calendarSessionsSelected: [],
  globalAlert: null,
  bookmarkItems: [],
  bookmarkItemsLoading: [],
  //presentationView:null,
  tagForPresentationSearch: "",
  userPreference: {
    presentationView: null,
    presentationType: null,
    bookmarkTab: null,
    savedUserId: null
  },
  isModalOpen: false,
  tagsChanged: [],
  showRecommended: true,
  // conferenceSetting:{}
  visitedPresentationIds: [],
};

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setConferenceGroupId: (state, action) => {
      state.conferenceGroupId = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setConferenceGroupInfo: (state, action) => {
      state.conferenceGroupInfo = action.payload;
    },
    setFormattedSessions: (state, action) => {
      state.sessionsInfoFormatted = action.payload;
    },
    setCalendarInfo: (state, action) => {
      state.calendarInfo = action.payload;
    },
    // setRocketChatToken: (state, action) => {
    //   if (Object.keys(state.userInfo).length !== 0)
    //     state.userInfo.rocketChatToken = action.payload;
    // },
    setCalendarDayViewInfo: (state, action) => {
      state.calendarDayViewInfo = {
        //showDayView: action.payload.sessions.length ? true : false,
        showDayView: action.payload.date ? true : false,
        date: action.payload.date,
        sessions: action.payload.sessions,
      };
    },
    // setStoreTimestamp: (state) => {
    //   if (
    //     !state.storeTimeStamp ||
    //     dayjs().diff(dayjs(state.storeTimeStamp), "minute") > 5
    //   ) {
    //     state.isStoreStale = true;
    //     state.storeTimeStamp = dayjs().format();
    //   } else {
    //     state.isStoreStale = false;
    //   }
    // },
    setCalendarSessionsToDownload: (state, action) => {
      switch (action.payload.type) {
        case 'add':
          state.calendarSessionsSelected.push(action.payload.id)
          break;
        case 'remove':
          state.calendarSessionsSelected = state.calendarSessionsSelected.filter(p => p !== action.payload.id)
          break;
        case 'removeAll':
          state.calendarSessionsSelected = []
          break;
        default:
          break;
      }
    },
    setGlobalAlert: (state, action) => {
      state.globalAlert = action.payload
    },
    setBookmarkItems: (state, action) => {
      switch (action.payload.type) {
        case 'add':
          state.bookmarkItems.push({ itemId: action.payload.itemId })
          break;
        case 'remove':
          state.bookmarkItems = state.bookmarkItems.filter(p => p.itemId !== action.payload.itemId)
          break;
        case 'removeAll':
          state.bookmarkItems = []
          break;
        default:
          break;
      }
    },
    setBookmarkItemsLoading: (state, action) => {
      switch (action.payload.type) {
        case 'add':
          state.bookmarkItemsLoading.push(action.payload.itemId)
          break;
        case 'remove':
          state.bookmarkItemsLoading = state.bookmarkItemsLoading.filter(p => p !== action.payload.itemId)
          break;
        case 'removeAll':
          state.bookmarkItemsLoading = []
          break;
        default:
          break;
      }
    },
    setUserPreference: (state, action) => {
      state.userPreference[action.payload.name] = action.payload.value
    },
    clearUserPreference: (state) => {
      state.userPreference = initialState.userPreference
    },
    setTagForPresentationSearch: (state, action) => {
      state.tagForPresentationSearch = action.payload;
    },
    //clearStore:()=>initialState,
    clearStore: (state) => {
      state.conferenceGroupInfo = {};
      state.sessionsInfo = [];
      state.sessionsInfoFormatted = [];
      state.calendarInfo = {};
      state.dynamicMenus = [];
      state.calendarDayViewInfo = { showDayView: false };
      state.calendarSessionsSelected = [];
      state.globalAlert = null;
      state.bookmarkItems = [];
      state.bookmarkItemsLoading = [];
      state.tagForPresentationSearch = "";
      state.isModalOpen = false;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setTagsChanged: (state, action) => {
      switch (action.payload.type) {
        case 'add':
          state.tagsChanged.push(action.payload.itemId)
          break;
        case 'remove':
          state.tagsChanged = state.tagsChanged.filter(p => p !== action.payload.itemId)
          break;
        case 'removeAll':
          state.tagsChanged = []
          break;
        default:
          break;
      }
    },
    setShowRecommended: (state) => {
      state.showRecommended = false
    },
    setVisitedPresentationIds: (state, action) => {
      switch (action.payload.type) {
        case 'add':
          state.visitedPresentationIds.push(action.payload.id)
          break;
        case 'removeAll':
          state.visitedPresentationIds = []
          break;
        default:
          break;
      }
    }
    // setConferenceSetting:(state,action)=>{
    //   state.conferenceSetting = action.payload
    // }
  },
  extraReducers: {
    [fetchSessionNotesByInvitation.fulfilled]: (state, action) => {
      state.sessionsInfo = action.payload;
      state.sessionsInfoFormatted = formatSessionsInfo(action.payload);
      state.calendarInfo = formatCalendarInfo(
        action.payload,
        state.sessionsInfoFormatted
      );
    },
    [fetchChildGroupsByGroupId.fulfilled]: (state, action) => {
      state.dynamicMenus = action.payload;
      state.isStoreStale = false;
    },
    [fetchChildGroupsByGroupId.rejected]: (state, action) => {
      state.globalAlert = { type: "error", content: action.error.message }
    },
    [fetchBookmarks.fulfilled]: (state, action) => {
      state.bookmarkItems = action.payload?.map(p => { return { tagId: p.id, itemId: p.forum } })
    },
    [logout.fulfilled]: (state, action) => {
      state.userInfo = {}
      state.showRecommended = true;
      state.tagForPresentationSearch = "";
      // clearCookie();
    },
    [login.fulfilled]: (state, action) => {
      if (action.payload) state.userInfo = action.payload
    },
    [getRocketChatToken.fulfilled]: (state, action) => {
      if (action.payload) state.userInfo.rocketChat = action.payload;
    }
  },
});

export const selectRoot = (state) => state;

export const {
  setConferenceGroupId,
  setConferenceGroupInfo,
  setFormattedSessions,
  setCalendarInfo,
  //logout,
  // setRocketChatToken,
  setCalendarDayViewInfo,
  //setStoreTimestamp,
  setCalendarSessionsToDownload,
  setGlobalAlert,
  setBookmarkItems,
  setUserPreference,
  clearUserPreference,
  setBookmarkItemsLoading,
  setTagForPresentationSearch,
  clearStore,
  setUserInfo,
  setIsModalOpen,
  setTagsChanged,
  setShowRecommended,
  setVisitedPresentationIds
  // setConferenceSetting
} = rootSlice.actions;

export default rootSlice.reducer;
