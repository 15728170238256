import { Collapse, Form, Input, Space, Button, Checkbox } from "antd"
import 'antd/lib/checkbox/style/index.css';
import 'antd/lib/form/style/index.css';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import produce from "immer";

const CollapseComponent = ({ option }) => {
  return (
    <Collapse accordion={option.accordion} className="mb-5">
      {option.items?.map((item, index) => {
        return <Collapse.Panel key={index} header={item?.header}>
          {item?.content}
        </Collapse.Panel>
      })}
    </Collapse>
  )
}

export const CollapseComponentOptionEdit = ({ option, onOptionChange }) => {
  return (
    <>
      <Form layout="inline">
        <Form.Item label="Expand only 1 panel">
          <Checkbox checked={option.accordion} onChange={(e) => { onOptionChange({ ...option, accordion: e.target.checked }) }} />
        </Form.Item>
      </Form>
      <Form layout="inline">
        <div className="d-flex flex-column">
          {option.items?.map((item, index) => {
            return (
              <Space>
                <Form.Item label="Header">
                  <Input value={option.items[index].header} onChange={(e) => onOptionChange({
                    ...option, items: produce(option.items, draftItems => {
                      draftItems[index].header = e.target.value
                    })
                  })} />
                </Form.Item>
                <Form.Item label="Content">
                  <Input value={option.items[index].content} onChange={(e) => onOptionChange({
                    ...option, items: produce(option.items, draftItems => {
                      draftItems[index].content = e.target.value
                    })
                  })} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => onOptionChange({
                  ...option, items: produce(option.items, draftItems => {
                    draftItems.splice(index, 1)
                  })
                })} />
              </Space>
            )
          })}
        </div>
      </Form>
      <Button type="dashed" style={{width:"fit-content"}} onClick={() => onOptionChange({
        ...option, items: produce(option.items, draftItems => {
          draftItems.push({ header: "", content: "" })
        })
      })} icon={<PlusOutlined />}>
        Add a panel
       </Button>
    </>
  )
}

export default CollapseComponent
