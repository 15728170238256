/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
    // Button,
    Container,
    // Dropdown,
    // DropdownButton,
    Row,
    Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DynamicComponent from "./components/DynamicComponent";
import DynamicComponentEdit from "./components/DynamicComponentEdit";
import api from "./lib/api-client";
import useQuery from "./lib/hooks/useQuery";
import { loadStore, selectRoot } from "./rootSlice";

import { useDrop } from "react-dnd";
import DynamicComponentOrder from "./components/DynamicComponentOrder";
import Error from "./Error";
// import AddMenuGroup from "./components/home/AddMenuGroup";
import produce from 'immer'
// import Registration from "./components/Registration";
// import Registration2 from "./components/Registration2";
// import Registration3 from "./components/Registration3";
import { Button, Dropdown, Menu } from "antd";
// import 'antd/lib/divider/style/index.css';

const Home = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [webfield, setWebfield] = useState(null);
    const [layout, setLayout] = useState([])
    const [layoutToEdit, setLayoutToEdit] = useState([])
    const [footer, setFooter] = useState(webfield?.footer)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [, drop] = useDrop({ accept: "dynamicComponentEdit" });

    const {
        conferenceGroupInfo,
        // isStoreStale,
        userInfo,
    } = useSelector(selectRoot);
    let { groupId } = useParams();
    let query = useQuery();
    const editable = conferenceGroupInfo?.details?.writable;
    const availableComponents = ["Markdown", "Jumbotron Image", "Sponsors", "HappeningNow", "WelcomeWall","Another Site"];

    const handleAddComponentClick = (componentName) => {
        const nextUniqueId = layoutToEdit.map((p) => p.uniqueId).reduce((a, b) => Math.max(a, b), -1) + 1;
        switch (componentName) {
            case "Markdown":
                setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
                    draftLayout.push({
                        name: "Markdown",
                        content: "# edit markdown here",
                        option: {
                            center: false
                        },
                        uniqueId: nextUniqueId
                    })
                }))
                break;
            case "Jumbotron Image":
                setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
                    draftLayout.push({
                        name: "JumbotronImage",
                        content: "# some text here",
                        option: {
                            imageUrl: "default",
                            color: "white"
                        },
                        uniqueId: nextUniqueId
                    })
                }))
                break;
            case "Sponsors":
                setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
                    draftLayout.push({
                        name: "Sponsors",
                        uniqueId: nextUniqueId
                    })
                }))
                break;
            case "WelcomeWall":
                setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
                    draftLayout.push({
                        name: "WelcomeWall",
                        option: {
                            numOfMessages: 20,
                            allowMarkdown: true,
                            escapeHtml: false
                        },
                        uniqueId: nextUniqueId
                    })
                }))
                break;
            case "Another Site":
                setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
                    draftLayout.push({
                        name: "IFrame",
                        option: {
                            title: "",
                            src: "",
                            width: "50%",
                            height:"500px",
                        },
                        uniqueId: nextUniqueId
                    })
                }))
                break;
            default:
                setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
                    draftLayout.push({
                        name: componentName,
                        uniqueId: nextUniqueId
                    })
                }))
                break;
        }
    };

    const deleteComponent = (index) => {
        setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
            draftLayout.splice(index, 1)
        }))
    };

    const updateFooter = (index, newValue) => {
        setFooter(newValue)
    };

    // update content or option
    const updateComponent = (index, newValue, type) => {
        setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
            draftLayout[index][type] = newValue
        }))
    }

    const handleSaveClick = async () => {
        setIsLoading(true);
        try {
            await api.post(
                "/groups",
                {
                    ...conferenceGroupInfo,
                    web: JSON.stringify(produce(webfield, draft => {
                        draft.layout = layoutToEdit;
                        draft.footer = footer;
                    })),
                },
                { accessToken: userInfo.token }
            );
            dispatch(loadStore({ id: groupId, history: history }))
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const moveComponent = (uniqueId, toIndex) => {
        const index = findComponent(uniqueId);
        setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
            draftLayout.splice(index, 1)
            draftLayout.splice(toIndex, 0, layoutToEdit[index]);
        }))
    };

    const findComponent = (uniqueId) => {
        return layoutToEdit.findIndex((p) => p.uniqueId === uniqueId)
    };

    useEffect(() => {
        dispatch(loadStore({ id: groupId, history: history }))
        setError(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId]);

    useEffect(() => {
        if (Object.keys(conferenceGroupInfo).length === 0) {
            setError("something wrong, conference info is not loaded");
            return;
        }
        setError(null)
        if (Object.keys(conferenceGroupInfo).length === 0) return; // loading
        if (!conferenceGroupInfo?.web) {
            setWebfield(null)
            setError(`${conferenceGroupInfo.id} does not have anything to show`)
            return
        }
        const webObject = JSON.parse(conferenceGroupInfo.web)
        setWebfield(webObject)
        setLayout(webObject.layout?.map((p, index) => { return { ...p, uniqueId: index } }))
        setLayoutToEdit(webObject.layout?.map((p, index) => { return { ...p, uniqueId: index } }))
        setFooter(webObject.footer)
    }, [conferenceGroupInfo]);

    if (isLoading)
        return (
            <Container style={{ marginTop: "30vh" }}>
                <Row className="justify-content-center">
                    <Spinner animation="border" />
                </Row>
            </Container>
        );

    if (error) return <Error message={error} />;

    // if (query.get('action') === 'register') return <Registration />
    // if (query.get('action') === 'register2') return <Registration2 />
    // if (query.get('action') === 'register3') return <Registration3 />

    if (editable && query.get("mode") === "edit")
        return (
            <div className="mx-5" ref={drop}>
                {/* <Row className="d-flex flex-column m-5">
                    <h2>Add Menu</h2>
                    <AddMenuGroup />
                </Row>
                <Divider />
                <h2 className="mx-5">Edit Layout</h2> */}
                <div className="d-flex">
                    <div className="w-100">
                        {layoutToEdit?.map((p, index) => {
                            return (
                                <DynamicComponentEdit
                                    name={p.name}
                                    option={p.option}
                                    content={p.content}
                                    key={`${p.name}${index}`}
                                    index={index}
                                    deleteComponent={deleteComponent}
                                    updateComponent={updateComponent}
                                    stack={query.get("stack")}
                                />
                            );
                        })}
                    </div>
                    <div className="d-flex flex-column position-fixed" style={{ marginLeft: "90%", zIndex: 1 }}>
                        {layoutToEdit?.length > 1 &&
                            layoutToEdit?.map((p, index) => {
                                return (
                                    <DynamicComponentOrder
                                        moveComponent={moveComponent}
                                        findComponent={findComponent}
                                        uniqueId={p.uniqueId}
                                        index={index}
                                        key={`${p.name}${index}`}
                                    />
                                );
                            })}
                    </div>
                </div>
                <Row>
                    <DynamicComponentEdit
                        name="Footer"
                        option={footer}
                        key="footer"
                        updateComponent={updateFooter}
                    />
                </Row>
                <Row>
                    <Dropdown trigger="click" overlay={
                        <Menu>
                            {availableComponents.map((p) => {
                                return (
                                    <Menu.Item
                                        key={p}
                                        onClick={() => handleAddComponentClick(p)}
                                    >
                                        {p}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    }>
                        <Button type="primary">Add a component</Button>
                    </Dropdown>
                    {/* <DropdownButton title="Add a component" variant="secondary">
                        {availableComponents.map((p) => {
                            return (
                                <Dropdown.Item
                                    key={p}
                                    onClick={() => handleAddComponentClick(p)}
                                >
                                    {p}
                                </Dropdown.Item>
                            );
                        })}
                    </DropdownButton> */}
                    <Button className="ml-5" style={{ backgroundColor: "green", color: "white" }} onClick={handleSaveClick}>
                        Save Changes
                    </Button>
                </Row>
            </div>
        );

    return (
        <div className="">
            {layout?.map((p, index) => {
                return (
                    <DynamicComponent
                        name={p.name}
                        content={p.content}
                        option={p.option}
                        key={`${p.name}${index}`}
                    />
                );
            })}
        </div>
    );
};

export default Home;
