import { Form, Input } from "antd";
import { useEffect, useState } from "react"

const Redirection = ({ option, redirect = true }) => {
    useEffect(() => {
        if (option.url && redirect)
            window.location.replace(option.url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //return option.url?`redirecting to ${option.url}`:null
    if (!redirect) return option.url ? `redirecting to ${option.url}` : null
    return null
}

export const RedirectionOptionEdit = ({ option, onOptionChange }) => {

    // eslint-disable-next-line no-unused-vars
    const [optionState, _] = useState({ url: option?.url ?? "" });
    const [form] = Form.useForm();

    const handleFieldsChange = (_, allFields) => {
        onOptionChange({ url: allFields[0].value })
    }

    return (
        <Form onFieldsChange={handleFieldsChange} form={form} layout="vertical">
            <Form.Item label="Redirection URL" name="url" initialValue={optionState.url}>
                <Input />
            </Form.Item>
        </Form>
    )
}

export default Redirection