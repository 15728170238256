/* eslint-disable no-useless-escape */
import { Button, Empty, Form, Input, InputNumber, Modal, Radio, Space, Tabs } from "antd";
import 'antd/lib/form/style/index.css';
import 'antd/lib/tabs/style/index.css';
import 'antd/lib/input-number/style/index.css';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectRoot } from "../rootSlice";
import produce from "immer";
const { Container, Row, Col, Jumbotron } = require("react-bootstrap");

const renderColumn = (columnData, conferenceId) => {
  switch (columnData.type) {
    case "imageText":
      return <ImageText data={columnData} key={`${columnData?.id}${columnData?.type}${columnData?.title}`} />;
    case "list":
      return <List data={columnData} key={`${columnData?.id}${columnData?.type}${columnData?.title}`} conferenceId={conferenceId} />;
    default:
      return null;
  }
};

const ImageText = ({ data }) => {
  return (
    <Col className={`${data.hideOnSmallScreen ? "d-none d-lg-block" : ""}`}>
      <Container>
        <div>
          <img src={`${process.env.PUBLIC_URL}${data.image}`} width={data.imageWidth} alt="" />
        </div>
        {data.textContents.map((text) => {
          return (
            <div className="footer-small" key={text}>
              {text}
            </div>
          );
        })}
      </Container>
    </Col>
  );
};

const List = ({ data, conferenceId }) => {
  return (
    <Col>
      <Container>
        <div className="footer-bold mb-2">{data.title}</div>
        {/* eslint-disable-next-line array-callback-return */}
        {data.links.map((link) => {
          if (link) return ( //link may be undefined during edit 
            <Link to={link.url ? link.url : `${conferenceId}`} key={link.text}>
              <div className="footer-light">{link.text}</div>
            </Link>
          );
        })}
      </Container>
    </Col>
  );
};

const Footer = ({ option }) => {
  const { conferenceGroupInfo } = useSelector(selectRoot);
  let webfield = (/^[\],:{}\s]*$/.test(conferenceGroupInfo?.web?.replace(/\\["\\\/bfnrtu]/g, '@')
    .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
    .replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) ? JSON.parse(conferenceGroupInfo?.web) : null;
  if (option) webfield = {
    footer: option
  }

  const hasStore = Object.keys(conferenceGroupInfo).length > 0
  if (!hasStore) return null;

  return (
    <Jumbotron
      fluid
      className="justify-content-center mt-5 mb-0"
      style={{
        backgroundColor: `${webfield?.footer?.color ?? "lightgray"}`,
      }}
    >
      <Container>
        <Row className="d-flex justify-content-center">
          {webfield?.footer?.columns?.slice()?.sort((a, b) => a.id - b.id)?.map((columnData) => {
            return renderColumn(columnData, conferenceGroupInfo.id);
          })}
        </Row>
      </Container>
    </Jumbotron>
  );
};

const ImageTextPane = ({ columnData, updateColumnData, updateOnLoad = false }) => {
  const { conferenceGroupInfo } = useSelector(selectRoot);
  // eslint-disable-next-line no-unused-vars
  const [columnState, _] = useState(
    {
      id: columnData?.id ?? "1",
      type: columnData?.type ?? "imageText",
      hideOnSmallScreen: columnData?.hideOnSmallScreen ?? true,
      image: columnData?.image ?? `/${conferenceGroupInfo?.id?.split('/')?.[0]}.png`,
      imageWidth: columnData?.imageWidth ?? "80%",
      textContents: columnData?.textContents ?? ['line1','line2'],
    }
  );

  const [form] = Form.useForm();

  const handleFieldsChange = (_, allFields) => {
    updateColumnData({
      id: allFields[0].value,
      type: "imageText",
      hideOnSmallScreen: allFields[1].value,
      image: allFields[2].value,
      imageWidth: allFields[3].value,
      textContents: columnData?.textContents ?? ['line1','line2'],
    })
  }

  useEffect(() => {
    if (updateOnLoad)
      updateColumnData({
        id: columnData?.id ?? "1",
        type: columnData?.type ?? "imageText",
        hideOnSmallScreen: columnData?.hideOnSmallScreen ?? true,
        image: columnData?.image ?? `/${conferenceGroupInfo?.id?.split('/')?.[0]}.png`,
        imageWidth: columnData?.imageWidth ?? "80%",
        textContents: columnData?.textContents ?? ['line1', 'line2'],
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const handleAddTextClick = () => {
  //   updateColumnData({
  //     ...columnState,
  //     textContents: [...columnState.textContents, '']
  //   }
  //   )
  // }

  const [texts,setTexts]=useState(columnData?.textContents ?? ['line1','line2']);

  const updateTextContent=(text,index)=>{
    setTexts(produce(texts,draftTextContents=>{draftTextContents[index]=text}))
    updateColumnData({
      ...columnState,
      textContents:produce(columnState.textContents,draftTextContents=>{draftTextContents[index]=text})
    })
  }

  return (
    <>
      <Form onFieldsChange={handleFieldsChange} form={form} layout="vertical">
        <Form.Item label="Id" name="id" initialValue={columnState.id}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Hide on mobile" name="hideOnSmallScreen" initialValue={columnState.hideOnSmallScreen}>
          <Input />
        </Form.Item>
        <Form.Item label="Logo" name="image" initialValue={columnState.image}>
          <Input />
        </Form.Item>
        <Form.Item label="% Width" name="imageWidth" initialValue={columnState.imageWidth}>
          <Input />
        </Form.Item>
        <Form.Item noStyle={true}>Text</Form.Item>
        {
          texts?.map((textContent,index)=>{
            return (
              <Input key={index} value={textContent} onChange={(e)=>{updateTextContent(e.target.value,index)}}/>
            )
          })
        }
        {/* <Button type="info" onClick={() => handleAddTextClick()}>Add Text</Button> */}
      </Form>
    </>
  )
}

const ListPane = ({ columnData, updateColumnData, updateOnLoad = false }) => {
  // eslint-disable-next-line no-unused-vars
  const [columnState, _] = useState(
    {
      id: columnData?.id ?? "1",
      type: columnData?.type ?? "list",
      title: columnData?.title ?? "dummy",
      links: columnData?.links ?? [],
    }
  );

  const [form] = Form.useForm();

  const handleFieldsChange = (_, allFields) => {

    updateColumnData({
      id:allFields[0].value,
      type:"list",
      title:allFields[1].value,
      links:allFields.find(p=>Array.isArray(p.value)).value
    })
  }

  useEffect(() => {
    if (updateOnLoad)
      updateColumnData({
        id: columnData?.id ?? "1",
        type: columnData?.type ?? "list",
        title: columnData?.title ?? "dummy",
        links: columnData?.links ?? [],
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Form onFieldsChange={handleFieldsChange} form={form} layout="vertical">
        <Form.Item label="Id" name="id" initialValue={columnState.id}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Title" name="title" initialValue={columnState.title}>
          <Input />
        </Form.Item>
        <Form.List name="links" initialValue={columnState.links}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <Space key={field.key} style={{ display: 'flex'}} align="baseline">
                  <Form.Item {...field} name={[field.name, 'text']} label="Link Text">
                    <Input />
                  </Form.Item>
                  <Form.Item {...field} name={[field.name, 'url']} label="Link Url">
                    <Input />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Link
              </Button>
              </Form.Item>
            </>
          )
          }
        </Form.List>
      </Form>
    </>
  )
}

const NewPane = ({ columnData, updateColumnData }) => {
  const [columnType, setColumnType] = useState('')
  return (
    <>
      <Form.Item label="Column Type">
        <Radio.Group buttonStyle="solid" onChange={e=>setColumnType(e.target.value)}>
          <Radio.Button value="imageText">Image and Text</Radio.Button>
          <Radio.Button value="list">List</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {columnType==="imageText" && <ImageTextPane columnData={columnData} updateColumnData={updateColumnData} updateOnLoad={true}/>}
      {columnType==="list" && <ListPane columnData={columnData} updateColumnData={updateColumnData} updateOnLoad={true}/>}
    </>
  )
}

export const FooterOptionEdit = ({ option, onOptionChange }) => {
  // eslint-disable-next-line no-unused-vars
  const [optionState, _] = useState({ color: option?.color ?? "white" });
  const [showColumnEditModal, setShowColumnEditModal] = useState(false)

  const [form] = Form.useForm();

  const handleFieldsChange = (_, allFields) => {
    onOptionChange({ color: allFields[0].value, columns: [] })
  }

  const hanldeColumnUpdate=(updatedColumn,index)=>{
    onOptionChange(produce(option, draftOption=> {
      draftOption.columns[index]=updatedColumn
  }))
  }

  const handleTabEdit = (targetKey, action) => {
    if (action === 'remove') {
      onOptionChange(produce(option, draftOption => {
        draftOption.columns = option.columns.filter(p => p.id !== Number(targetKey))
      }))
    }
    if (action === 'add') {
      onOptionChange(produce(option, draftOption => {
        draftOption.columns.push({id:option?.columns?.map((p) => p.id).reduce((a, b) => Math.max(a, b), 0) + 1})
      }))
    }
  }

  const renderTabPane=(column,index)=>{
    if(column.type==='imageText') return <ImageTextPane columnData={column} updateColumnData={(updatedColumn)=>{hanldeColumnUpdate(updatedColumn,index)}}/>
    if(column.type==='list')  return <ListPane columnData={column} updateColumnData={(updatedColumn)=>{hanldeColumnUpdate(updatedColumn,index)}}/>
    // new tab
    return <NewPane columnData={column} updateColumnData={(updatedColumn)=>{hanldeColumnUpdate(updatedColumn,index)}}/>
  }

  return (
    <>
      <Form onFieldsChange={handleFieldsChange} form={form} >
        <Form.Item label="Background color" name="color" initialValue={optionState.color}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="info" onClick={() => setShowColumnEditModal(true)}>
            Edit Columns
        </Button>
        </Form.Item>
      </Form>
      <Modal
        title="Footer Columns"
        visible={showColumnEditModal}
        destroyOnClose={true}
        closable={false}
        okText="Preview"
        cancelButtonProps={{style:{display:'none'}}}
        onOk={() => { setShowColumnEditModal(false) }}
        zIndex={1029}
        width="800px"
      >
        <Tabs defaultValue={1} type="editable-card" onEdit={handleTabEdit}>
          {
            option?.columns?.map((column,index) => {
              return (
                <Tabs.TabPane key={`${column?.id}${column?.type}${column?.title}`} tab={`column ${column.id}-${column?.type}`} closable={true}>
                  {renderTabPane(column,index)}
                </Tabs.TabPane>
              )
            })
          }
        </Tabs>
        {
          option?.columns?.length === 0 && <Empty description="Click + button to add a footer column"/>
        }
      </Modal>
    </>
  )
}
export default Footer;
