import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import api from "../../lib/api-client";
import { setGlobalAlert } from "../../rootSlice";
import OrganizerCard from "./OrganizerCard";

const Organizers = ({ option }) => {
  const [organizerIds, setOrganizerIds] = useState([]);
  const dispatch=useDispatch()

  const getOrganizerIds = async (groupId) => {
    const organizersGroupId = `${groupId}/Organizers`;
    try {
      const result = await api.get("/groups", { id: organizersGroupId });
      const organizerTildeIds = result.groups[0].members;
      setOrganizerIds(organizerTildeIds);
    } catch (error) {
      dispatch(setGlobalAlert({
        type: "danger", content: error.message
      }))
    }
  };

  useEffect(() => {
    if (!option) return;
    getOrganizerIds(option);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  return (
    <Container className="text-center mt-5">
      <Row>
        <h3 className="float-left">Organizers</h3>
      </Row>
      <Row className="justify-content-center">
        {organizerIds.map((tildeId,index) => {
          return (
            <Col key={tildeId} xs={12} sm={4} className="mb-4">
              <OrganizerCard tildeId={tildeId} index={index+1}/>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Organizers;
