import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
//import { Link } from "react-router-dom";
import api from "../../lib/api-client";
import { setGlobalAlert } from "../../rootSlice";

const OrganizerCard = ({ tildeId, index }) => {
  const [profile, setProfile] = useState(null);
  const dispatch = useDispatch();

  const formatProfile = (profile) => {
    let preferredName = profile.content.names?.filter((p) => p.preferred)[0];
    if (!preferredName) preferredName = profile.content.names[0];
    const institution = profile.content.history?.[0]
    return {
      id: profile.id,
      name: `${preferredName.first} ${preferredName.last}`,
      institution: institution?.institution?.name,
      position: institution?.position
    };
  };

  const getOrganizerProfileInfo = async (tildeId) => {
    try {
      const result = await api.get("/profiles", { id: tildeId });
      const profileInfo = result.profiles[0];
      const formattedProfileInfo = formatProfile(profileInfo);
      setProfile(formattedProfileInfo);
    } catch (error) {
      dispatch(setGlobalAlert({
        type: "danger", content: error.message
      }))
    }
  };
  useEffect(() => {
    if (!tildeId) return;
    getOrganizerProfileInfo(tildeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tildeId]);

  return (
    <Card border='0'>
      <Card.Body>
        <Card.Img className="mb-2" src={`${process.env.PUBLIC_URL}/orgface/org${index}.jpg`} style={{ width: "70%", minWidth: "50px" }} />
        <Card.Title>
          <a href={`https://openreview.net/profile?id=${profile?.id}`} target='_blank' rel="noreferrer" style={{ color: 'black' }}>{profile?.name}</a>
        </Card.Title>
        <Card.Text className="m-0">
          {profile?.position ?? <br />}
        </Card.Text>
        <Card.Text>
          {profile?.institution ?? <br />}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default OrganizerCard;
