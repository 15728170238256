import { Form, Input, Modal, Button, Collapse } from "antd";
import 'antd/lib/table/style/index.css';
import 'antd/lib/space/style/index.css';
import 'antd/lib/date-picker/style/index.css';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSession, selectRoot } from "../../rootSlice";
import DatePicker from "./DatePicker";
import { Suspense } from "react";
import dayjs from "dayjs";
import useBreakpoint from "../../lib/hooks/useBreakpoint";

const EditSessionsTable = React.lazy(() => import('./EditSessionsTable'));

const EditSessions = ({ show, setShow }) => {
    const { userInfo, sessionsInfo } = useSelector(selectRoot);
    const dispatch = useDispatch()
    const [newSessionState, setNewSessionState] = useState({ start: null, end: null, title: "", type: "", description: "" });
    const [sessions, setSessions] = useState([]);
    const [form] = Form.useForm();

    const issm = useBreakpoint("sm")

    const handleFieldsChange = (_, allFields) => {
        setNewSessionState({
            start: allFields[0]?.value?.valueOf(),
            end: allFields[1]?.value?.valueOf(),
            title: allFields[2]?.value,
            type: allFields[3]?.value,
            description: allFields[4]?.value,
        })
    }

    const addNewSession = () => {
        dispatch(addSession({ sessionContent: newSessionState, userToken: userInfo?.token }));
        setNewSessionState({ start: null, end: null, title: "", type: "", description: "" });
        form.setFieldsValue({ start: null, end: null, title: "", type: "", description: "" });
    }

    const handleCancel = () => {
        setShow(false);
    }

    useEffect(() => {
        if (show) setSessions(sessionsInfo.map(p => {
            return {
                ...p,
                content: {
                    ...p.content,
                    start: dayjs(Number(p.content.start)), // may be string instead of number
                    end: dayjs(Number(p.content.end))
                }
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, sessionsInfo])

    return (
        <Modal
            title="Edit Sessions"
            maskClosable={false}
            visible={show}
            closable={false}
            destroyOnClose={true}
            okText={null}
            okButtonProps={{ style: { display: "none" } }}
            cancelText="Close"
            onCancel={handleCancel}
            onOk={() => { }}
            onClick={e => e.stopPropagation()}
            zIndex={1029}
            width="fit-content"
        >
            <style>
                {
                    `
                    .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header{
                        color:black;
                    }
                    `
                }
            </style>
            <Collapse defaultActiveKey="1" ghost destroyInactivePanel={true}>
                <Collapse.Panel header="Add a new session" key="1" collapsible="disabled">
                    <Form layout={`${issm ? "inline" : "vertical"}`} form={form} initialValues={newSessionState} onFieldsChange={handleFieldsChange} preserve={false}>
                        <Form.Item label="Start" name="start">
                            <DatePicker showTime onChange={() => { }} onOk={(value) => { console.log(value.valueOf()) }} />
                        </Form.Item>
                        <Form.Item label="End" name="end">
                            <DatePicker showTime onChange={() => { }} onOk={(value) => { console.log(value.valueOf()) }} />
                        </Form.Item>
                        <Form.Item label="Title" name="title">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Type" name="type">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Description" name="description">
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" disabled={!(newSessionState.title && newSessionState.type && newSessionState.description)} onClick={addNewSession}>Add</Button>
                        </Form.Item>
                    </Form>
                </Collapse.Panel>
                {sessions.length !== 0 && <Collapse.Panel header="Edit sessions" key="2">
                    <Suspense fallback="loading">
                        <EditSessionsTable sessions={sessions} />
                    </Suspense>
                </Collapse.Panel>}
            </Collapse>
        </Modal>
    )
};

export default EditSessions;
