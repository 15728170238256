/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useHistory } from "react-router-dom";
import BookmarkButton from "../BookmarkButton";
import PaperTag from "./PaperTag";
import { Image, Modal, Card, Tooltip } from 'antd';
import { FileTextOutlined, CheckOutlined } from '@ant-design/icons'
import 'antd/lib/image/style/index.css';
import 'antd/lib/card/style/index.css';
import { validateToken } from "../../lib/utility";
import dayjs from "dayjs";
import { selectRoot } from "../../rootSlice";
import { useSelector } from "react-redux";
import { useState } from "react";

const PaperCard = ({ note, sessions, shouldFilterByTag = false }) => {
  let history = useHistory();
  const { conferenceGroupInfo, visitedPresentationIds, userInfo } = useSelector(selectRoot);
  const [showAbstractModal, setShowAbstractModal] = useState(false);
  const borderStyle = {
    borderLeft: `var(--presentation-${note?.content?.presentation_type})`,
    borderLeftStyle: "solid",
    borderLeftWidth: "thick",
    height: "100%"
  }
  const handlePaperClick = () => {
    history.push(`/presentation/${note?.id}`);
  };

  return (
    <>
      <style>
        {
          `
            .ant-card-body{
              padding:10px;
            }
        `
        }
      </style>
      {/* {`${note?.content?.presentation_type}`} */}
      <Card bordered={true} hoverable={true} bodyStyle={borderStyle}>
        <div className="d-flex flex-column h-100 justify-content-between">
          <div className="d-flex justify-content-between mb-1">
            <span className="paper-card-title" onClick={handlePaperClick}>{note?.content.title}</span>
            <BookmarkButton item={note} size="x-large" margin="-11px" />
          </div>
          <div className="d-flex flex-column justify-content-between flex-fill">
            <div>
              {note?.content?.authors?.map((author, index) => {
                if (!note?.content.authorids?.[index])
                  return (
                    <span className="paper-card-author-name mr-1" key={author}>
                      {author}
                      {`${index !== note?.content?.authors?.length - 1 ? "," : ""}`}
                    </span>
                  );
                const profileLink = `https://openreview.net/profile?${note.content.authorids[index].startsWith("~") ? "id" : "email"
                  }=${note.content.authorids[index]}`;
                return (
                  <a href={profileLink} target="_blank" rel="noreferrer" style={{ color: "black" }} key={author}>
                    <span className="paper-card-author-name mr-1">
                      {author}
                      {`${index !== note?.content?.authors?.length - 1 ? "," : ""}`}
                    </span>
                  </a>
                );
              })}
            </div>
            {note.content.presentation_type === 'presentation' && sessions.length===1 && (
              <div>
                {`${dayjs(Number(sessions[0].start.unixmm) + Number(note.content.start)).format("HH:mm")}-${dayjs(Number(sessions[0].start.unixmm) + Number(note.content.end)).format("HH:mm")}`}
              </div>
            )}
            <div className="d-flex flex-column">
              {sessions?.map((session) => {
                return (
                  <Link to={`/session/${session.id}`} key={session.id} className="mb-1">
                    <span className="mr-2 paper-card-session">
                      {`${session?.title}`}<br />
                      {`${session?.start?.formattedDate} ${session?.start?.formattedHour}`}
                    </span>
                  </Link>
                );
              })}
            </div>
            {note?.content?.abstract && <div>
              {/* <Collapse ghost>
                <Collapse.Panel header="Abstract" showArrow={false}>
                  {note?.content?.abstract}
                </Collapse.Panel>
              </Collapse> */}
              <small style={{ cursor: "pointer" }} onClick={() => { setShowAbstractModal(true) }}>View Abstract <FileTextOutlined style={{ verticalAlign: "baseline" }} /></small>
            </div>}
            <div className="d-flex justify-content-center my-2">
              <Image
                style={{ maxWidth: "200px" }}
                src={`${process.env.REACT_APP_API_URL}/attachment?id=${note?.id}&name=image`}
                key={`${process.env.REACT_APP_API_URL}/attachment?id=${note?.id}&name=image`}
                fallback={`${process.env.PUBLIC_URL}/${conferenceGroupInfo?.id?.split('/')?.[0]}.png`}
              />
            </div>
            {/* <div className="d-flex flex-fill" /> */}
            <div className="mt-2 d-flex">
              <div className="w-100">
                <PaperTag noteId={note?.id} shouldFilterByTag={shouldFilterByTag} />
              </div>
              {
                validateToken(userInfo?.token) && visitedPresentationIds?.includes(note?.id) &&
                <div className="d-flex flex-shrink-1">
                  <Tooltip placement="right" title="Your have visited this paper"><CheckOutlined /></Tooltip>
                </div>
              }
            </div>
          </div>
        </div>
      </Card>
      <Modal
        title="Abstract"
        visible={showAbstractModal}
        closable={false}
        okText="Close"
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={() => { setShowAbstractModal(false) }}
        onCancel={() => { setShowAbstractModal(false) }}
        zIndex={1061}
      >
        {note.content.abstract}
      </Modal>
    </>
  );
};

export default PaperCard;
