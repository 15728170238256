import JumbotronImage from "./home/JumbotronImage";
import Sponsors from "./home/Sponsors";
import Organizers from "./home/Organizers";
import Markdown from "./Markdown";
import HappeningNow from "./home/HappeningNow";
import Footer from "./Footer";
import React from "react";
import WelcomeWall from "./home/WelcomeWall";
import IFrame from "./home/IFrame";
import Redirection from "./home/Redirection";
import CollapseComponent from "./home/CollapseComponent";

const Components = {
  JumbotronImage,
  Sponsors,
  Organizers,
  Markdown,
  HappeningNow,
  Footer,
  WelcomeWall,
  IFrame,
  Redirection,
  CollapseComponent,
};

const DynamicComponent = ({ name, option, content }) => {
  if (Components[name])
    return (
      <div>
        {React.createElement(
          Components[name],
          { key: name, option, content: content },
          {}
        )}
      </div>
    );
};

export default DynamicComponent;
