/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from "react-bootstrap";
import api from "./lib/api-client";
import { useEffect, useState } from "react";
// import Rocketchat from "./components/presentation/rocketchat";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFilePdf, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
//import loadStore from "./lib/loadStore";
import { loadStore, selectRoot, setVisitedPresentationIds } from "./rootSlice";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { exportICS } from "./lib/utility";
// import { Image, Tooltip } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
//import 'antd/lib/image/style/index.css';
import Error from "./Error";
// import OpenReviewChat from "./components/presentation/openreviewChat";
import PaperTag from "./components/session/PaperTag";
import { Button } from "antd";
import 'antd/lib/button/style/index.css';
import RocketChatHosted from "./components/presentation/RocketChatHosted";
//import WelcomeWallChat from "./components/presentation/WelcomeWallChat";

const PresentationDetailField = ({ name, content }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  if (content.length <= 200) return (
    <div className="d-flex flex-column">
      <span className="page-presentation-detail-content"><span className="page-presentation-detail-title">{name}:</span> {content}</span>
    </div>
  )

  const words = content.split(' ');
  const short = words.slice(0, 30).join(' ')
  return (
    <div className="d-flex flex-column">
      {!isExpanded && <span className="page-presentation-detail-content" onClick={() => { setIsExpanded(true) }}><span className="page-presentation-detail-title">{name}:</span> {`${short} ... (Click to expand)`}</span>}
      {isExpanded && <span className="page-presentation-detail-content" onClick={() => { setIsExpanded(false) }}><span className="page-presentation-detail-title">{name}:</span> {content}</span>}
    </div>
  )
}

const Presentation = () => {
  const [isClientJsLoading, setIsClientJsLoading] = useState(true);
  const [note, setNote] = useState(null);
  const [sessionInfo, setSessionInfo] = useState([]);
  const [noteTimeInfo, setNoteTimeInfo] = useState(null); // for presentation presentation which has start/end info in note
  const [error, setError] = useState(null)

  const {
    sessionsInfoFormatted,
    userInfo
  } = useSelector(selectRoot);

  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const loadData = async (id) => {
    try {
      const result = await api.get("/notes", { id }, { accessToken: userInfo.token });
      const groupId = result.notes[0].invitation.split("/-")[0];
      setNote(result.notes[0]);
      await createChannel(result.notes[0]?.content?.paperhash ?? id);
      if (sessionsInfoFormatted.length) return
      dispatch(loadStore({ id: groupId, history: history }))
    } catch (error) {
      error.details ? setError(`${error.details.path} ${error.details.value} ${error.details.type}`) : setError(error.message)
    }
  };

  const createChannel = async (channelId) => {
    console.log("calling create channel")
    try {
      await api.post("/user/rocketchat/channel", { channelId: channelId.replace("|", "_") }, { accessToken: userInfo.token })
    } catch (error) {
      console.log(error);
    }
  }

  const handleCalendarIconClick = () => {
    exportICS({
      eventsToExport: [{
        title: note?.content?.title,
        description: note?.content?.['TL;DR'],
        start: note?.content?.start,
        end: note?.content?.end,
      }],
      saveFileName: note?.content?.title
    })
  }

  useEffect(() => {
    if (!note) return;
    //fetch sessioninfo
    let sessions = sessionsInfoFormatted.filter((p) => note.content.sessions.includes(p.id));
    if (["qa", "expo", "poster", "presentation"].includes(note.content.presentation_type)) {
      setSessionInfo(sessions);
    };
    if (note.content.presentation_type === "presentation" && sessions.length === 1) {
      const startObj = dayjs(Number(sessions[0].start.unixmm) + Number(note.content.start));
      const endObj = dayjs(Number(sessions[0].start.unixmm) + Number(note.content.end));
      setNoteTimeInfo({
        formattedDate: startObj.format("dddd, MMMM D, YYYY"),
        formattedHourStart: startObj.format("HH:mm"),
        formattedHourEnd: endObj.format("HH:mm"),
      })
    }
    //add to visited papers
    dispatch(setVisitedPresentationIds({ type: "add", id: note?.id }))
    //run slideslive
    if (!isClientJsLoading) return;
    require("./lib/slideslive_embed");
    setIsClientJsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, sessionsInfoFormatted]);

  useEffect(() => {
    if (isClientJsLoading) return;
    // eslint-disable-next-line no-unused-vars
    const embed = new SlidesLiveEmbed("presentation-embed", {
      presentationId: note.content.slideslive,
      autoPlay: false,
      verticalEnabled: true,
      verticalWhenWidthLte: 500,
    });
  }, [isClientJsLoading]);

  useEffect(() => {
    if (!id) return;
    loadData(id);
  }, [id]);

  useEffect(() => {
    if (!userInfo.token) return;
    createChannel(note?.content?.paperhash ?? id);
  }, [userInfo])

  if (error) return <Error message={error} />
  return (
    <>
      <style>
        {`
          .presentation__details{
            font-size:80%;
          }
          // .presentation__authors{
          //   font-style:italic;
          // }
          iframe{
            width:100%;
          }
        `}
      </style>
      <Container className="mt-5 page-presentation">
        <Row className="page-presentation-title">
          <span>
            {note?.content?.title}{" "}
          </span>
        </Row>
        <Row id="author">
          {note?.content?.authors.map((author, index) => {
            if (!note?.content.authorids?.[index])
              return (
                <p className="page-presentation-author" key={author}>
                  {author}
                </p>
              );
            const profileLink = `https://openreview.net/profile?${note.content.authorids?.[index]?.startsWith("~") ? "id" : "email"
              }=${note.content.authorids?.[index]}`;

            return (
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: "black" }}
                href={profileLink}
                key={author}
              >
                <p className="page-presentation-author mr-1">
                  {author}
                  {`${index !== note?.content?.authors?.length - 1 ? "," : ""}`}
                </p>
              </a>
            );
          })}
        </Row>
        <Row id="openreview-links" className="page-presentation-openreview-links">
          {note?.content?.pdf && (
            <a
              href={`${process.env.REACT_APP_WEB_URL}${note?.content?.pdf}`}
              target="_blank"
              rel="noreferrer"
              className="page-presentation-openreview-links mx-2"
            >Paper
            </a>
          )}
          <a
            href={`${process.env.REACT_APP_WEB_URL}/forum?id=${note?.id}`}
            target="_blank"
            rel="noreferrer"
            className="page-presentation-openreview-links mx-2"
          >Reviews
            </a>
        </Row>
        <div className="page-presentation-session">
          {sessionInfo.length !== 0 && sessionInfo.map(p => (
            <Row className="mb-1">
              {`${p.start.formattedDate} ${p.start.formattedHour}-${p.end.formattedHour}`}
              {["poster", "presentation"].includes(note?.content?.presentation_type) && <Button size="small" className="ml-2" onClick={() => history.push(`/session/${p.id}`)}>{`Join: ${p.title}`}</Button>}
            </Row>
          ))}
        </div>
        {noteTimeInfo && (
          <Row className="align-items-center">
            {`${noteTimeInfo.formattedDate} ${noteTimeInfo.formattedHourStart}-${noteTimeInfo.formattedHourEnd}`}
            <CalendarOutlined style={{ marginLeft: "0.5rem", verticalAlign: "middle", cursor: "pointer" }} onClick={handleCalendarIconClick} />
          </Row>
        )}
        <Row id="details">
          {note?.content?.keywords && (
            <div className="mb-2">
              <span className="page-presentation-detail-title">Keywords: </span>
              <span className="page-presentation-detail-content">{note.content.keywords.join(",")}</span>
            </div>
          )}
          {note?.content?.abstract && <PresentationDetailField name="Abstract" content={note.content.abstract} />}
          {note?.content?.bio && <PresentationDetailField name="Bio" content={note.content.bio} />}
          {note?.content?.site && (
            <div>
              <a href={note.content.site} target="_blank" rel="noreferrer">
                <span className="page-presentation-detail-title">site</span>
              </a>
            </div>
          )}
        </Row>
        <Row className="my-2">
          {note?.id && <PaperTag noteId={note?.id} />}
        </Row>
        {/* <Row className="my-5">
          <div id="presentation-embed"></div>
        </Row>
        <Row className="my-2 d-flex justify-content-center">
          {/* <Rocketchat /> */}
        {/* <OpenReviewChat noteId={note?.id} /> */}
        {/* <RocketChatHosted noteId={note?.id} /> */}
        {/* <WelcomeWallChat noteId={note?.id}/> */}
        {/* </Row> */}
        <Row className="my-5 d-flex align-items-stretch flex-md-row flex-lg-row flex-xl-row flex-sm-column">
          <Col md={userInfo?.token ? 6 : 12} sm={12} xs={12}><div id="presentation-embed"></div></Col>
          <Col md={6} sm={12} xs={12}><RocketChatHosted noteId={note?.content?.paperhash ? note.content.paperhash.replace("|", "_") : id} /></Col>
        </Row>
      </Container>
    </>
  );
};

export default Presentation;
