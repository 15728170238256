import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import api from "../../lib/api-client";
import { setGlobalAlert } from "../../rootSlice";

const SponsorLevel = ({ groupId, level }) => {
  const [sponsorNames, setSponsorNames] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!groupId) return;
    let isUnmounted = false;
    const getSponsorsInfo = async (groupId) => {
      try {
        const result = await api.get("/groups", { id: groupId });
        const sponsorNames = result.groups[0].members;
        if (!isUnmounted) setSponsorNames(sponsorNames);
      } catch (error) {
        dispatch(setGlobalAlert({
          type: "danger", content: error.message
        }))
      }
    };
    getSponsorsInfo(groupId);
    return () => (isUnmounted = true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);
  
  return (
    <Container>
      <Row>
        <Col>
          <h3 className="float-left">{level}</h3>
        </Col>
      </Row>
      <Row>
        {sponsorNames.map((name) => {
          return (
            <Col xs={12} sm={4} key={name}>
              <img
                src={`${process.env.PUBLIC_URL}/sponsorslogo/${name}.png`}
                width="50%"
                alt=""
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default SponsorLevel;
