import { useRef } from "react";
import { useSelector } from "react-redux";
import { selectRoot } from "../../rootSlice";

const RocketChatHosted = ({ noteId }) => {
    const { userInfo } = useSelector(selectRoot);
    const rocketChatIFrame = useRef(null);

    const onRocketChatLoaded = async () => {
        if (!userInfo.token) return; // not logged in
        if (userInfo.rocketChat?.authToken) postLoginMessageToRocketChat(userInfo.rocketChat?.authToken)
    };

    const postLoginMessageToRocketChat = (userToken) => {
        rocketChatIFrame.current.contentWindow.postMessage(
            {
                externalCommand: "login-with-token",
                token: userToken,
            },
            "*"
        );
    }
    if (!noteId || !userInfo?.token) return null;
    return (
        <div className="h-100 my-sm-2 my-xs-2 m-md-0">
            <iframe
                title="chat"
                ref={rocketChatIFrame}
                onLoad={onRocketChatLoaded}
                height="100%"
                style={{minHeight:"500px"}}
                src={`${process.env.REACT_APP_ROCKET_CHAT_BASE_URL}/channel/${noteId}?layout=embedded`}
            />
        </div>
    )
}

export default RocketChatHosted