import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import api from "../../lib/api-client";
import { selectRoot, setGlobalAlert } from "../../rootSlice";
import SponsorLevel from "./SponsorLevel";

const Sponsors = ({ option }) => {
  const [sponsorGroups, setSponsorGroups] = useState([]);
  const { conferenceGroupInfo } = useSelector(selectRoot)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!conferenceGroupInfo.id) return;
    let isUnmounted = false;
    const getSponsorsGroups = async (groupId) => {
      const sponsorGroupId = `${groupId}/Sponsors`;
      try {
        const result = await api.get("/groups", { id: sponsorGroupId });
        const sponsorMemberGroupIds = result.groups[0].members;
        if (!isUnmounted) setSponsorGroups(sponsorMemberGroupIds);
      } catch (error) {
        dispatch(setGlobalAlert({
          type: "danger", content: error.message
        }))
      }
    };
    getSponsorsGroups(conferenceGroupInfo.id);
    return () => (isUnmounted = true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  return (
    <Container className="text-center my-5">
      <Row>
        <h3 className="float-left">Sponsors</h3>
      </Row>
      {sponsorGroups.map((p) => {
        return <SponsorLevel key={p} groupId={p} level={p.split("/").pop()} />;
      })}
    </Container>
  );
};

export default Sponsors;
