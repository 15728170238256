import { Comment, List, Tooltip, Avatar, Button, Input, Collapse } from 'antd';
import 'antd/lib/comment/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/list/style/index.css';
import 'antd/lib/tooltip/style/index.css';
import 'antd/lib/avatar/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/collapse/style/index.css';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Container, Form, FormGroup } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import api from '../../lib/api-client';
import { selectRoot, setGlobalAlert } from '../../rootSlice'
import Markdown from '../Markdown';
import relativeTime from "dayjs/plugin/relativeTime";
import useInterval from '../../lib/hooks/userInterval';
import DOMPurify from 'dompurify'
import AvatarImage from '../AvatarImage';

const { TextArea } = Input;
const { Panel } = Collapse;

const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <Form>
        <FormGroup>
            <TextArea rows={4} onChange={onChange} value={value} placeholder="post a message here" />
        </FormGroup>
        <FormGroup>
            <Button htmlType="submit" loading={submitting} onClick={e => { e.preventDefault(); onSubmit() }} type="primary">Post a message</Button>
        </FormGroup>
    </Form>
    // <>
    //     <Form.Item>
    //         <TextArea rows={4} onChange={onChange} value={value} placeholder="post a message here" />
    //     </Form.Item>
    //     <Form.Item>
    //         <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
    //             Post a message
    //   </Button>
    //     </Form.Item>
    // </>
);

const WelcomeWall = ({ option }) => {
    const { userInfo, conferenceGroupInfo } = useSelector(selectRoot)
    const [messages, setMessages] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [newMessageText, setNewMessageText] = useState('')
    const [isExpanded, setIsExpanded] = useState(undefined)
    const dispatch = useDispatch();

    const getWelcomeWallMessages = async () => {
        try {
            const optionLimit = parseInt(option.numOfMessages) > 0 ? parseInt(option.numOfMessages) : 20
            const result = await api.get('/notes', {
                invitation: `${conferenceGroupInfo}/-/welcomewall`,
                limit: optionLimit
            }, { accessToken: userInfo?.token })

            setMessages(result.notes)
        } catch (error) {
            dispatch(setGlobalAlert({
                type: "danger", content: error.message
            }))
        }
    }

    const postNewWelcomeWallMessage = async () => {
        if (!newMessageText.trim() || !userInfo?.token) return
        setIsSubmitting(true)
        try {
            await api.post('/notes', {
                invitation: `${conferenceGroupInfo}/-/welcomewall`,
                readers: ['everyone'],
                writers: [`${conferenceGroupInfo.replace("Virtual", "")}`,],
                signatures: [userInfo.user.profile.id],
                content: {
                    author: `${userInfo.user.profile.first} ${userInfo.user.profile.last}`,
                    message: newMessageText
                }
            }, { accessToken: userInfo?.token })
        } catch (error) {
            dispatch(setGlobalAlert({
                type: "danger", content: error.message
            }))
        } finally {
            setIsSubmitting(false)
            setNewMessageText('')
            getWelcomeWallMessages()
        }
    }

    useEffect(() => {
        dayjs.extend(relativeTime)
        if (!userInfo?.token) return;
        getWelcomeWallMessages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useInterval(() => {
        getWelcomeWallMessages()
    }, 5000)

    return (
        <Container className="my-5">
            <List
                style={{ maxHeight: "500px", overflowY: "scroll" }}
                className="comment-list"
                itemLayout="horizontal"
                dataSource={messages}
                renderItem={item => (
                    <li>
                        <Comment
                            //actions={[<span key="comment-basic-reply-to">reply</span>]}
                            author={item.content.author}
                            avatar={<Avatar
                                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                alt="Han Solo"
                            />}
                            content={option.allowMarkdown ? <Markdown content={item.content.message} escapeHtml={option.escapeHtml} /> : item.content.message}
                            datetime={<Tooltip title={dayjs(item.cdate).format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{dayjs(item.cdate).fromNow()}</span>
                            </Tooltip>}
                        />
                    </li>
                )}
            />
            {userInfo?.token &&
                <Collapse className="mt-2" goast bordered={false} accordion="true" style={{ width: isExpanded ? undefined : 'fit-content' }} onChange={key => setIsExpanded(key)}>
                    <Panel showArrow={false} header="Add a welcome message" style={{ border: 'none' }} key="1">
                        <Comment
                            avatar={
                                <AvatarImage />
                            }
                            content={
                                <Editor
                                    onChange={e => setNewMessageText(DOMPurify.sanitize(e.target.value))}
                                    onSubmit={postNewWelcomeWallMessage}
                                    submitting={isSubmitting}
                                    value={newMessageText}
                                />
                            }
                        />
                    </Panel>
                </Collapse>}
        </Container>
    );
}

export const WelcomeWallOptionEdit = ({ option, onOptionChange }) => {
    const [numOfMessages, setNumOfMessages] = useState(option?.numOfMessages ?? 20)
    const [allowMarkdown, setAllowMarkdown] = useState(option?.allowMarkdown ?? true)
    const [escapeHtml, setEscapeHtml] = useState(option?.escapeHtml ?? false)

    useEffect(() => {
        onOptionChange({ numOfMessages: numOfMessages, allowMarkdown: allowMarkdown, escapeHtml: escapeHtml })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numOfMessages, allowMarkdown, escapeHtml])

    return (
        <Form>
            <FormGroup>
                <Form.Label>Number of messages to display:</Form.Label>
                <Form.Control type="number" value={numOfMessages} onChange={e => setNumOfMessages(parseInt(e.target.value) || 20)} />
            </FormGroup>
            <FormGroup>
                <Form.Label>Allow Markdown:</Form.Label>
                <Form.Check type="checkbox" checked={allowMarkdown} onChange={e => setAllowMarkdown(e.target.checked)} />
            </FormGroup>
            <FormGroup>
                <Form.Label>Disable HTML in Markdown <span style={{ color: "red" }}><small>dangerous to uncheck</small></span>:</Form.Label>
                <Form.Check type="checkbox" checked={escapeHtml} onChange={e => setEscapeHtml(e.target.checked)} />
            </FormGroup>
        </Form>
    )
}

export default WelcomeWall