import { Input, Tag, Tooltip, Modal, Form, Select } from 'antd';
import {
    SyncOutlined
} from '@ant-design/icons';
import 'antd/lib/modal/style/index.css';
import 'antd/lib/select/style/index.css';
import '../../antdAnimation.css'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addBookmark, deleteBookmark, selectRoot, setGlobalAlert, setIsModalOpen, setTagForPresentationSearch, setTagsChanged } from '../../rootSlice';
import { getTagColor, validateToken } from '../../lib/utility';
import api from '../../lib/api-client';
import dayjs from "dayjs";

const { confirm } = Modal;

const PaperTag = ({ noteId, shouldFilterByTag = { filter: false, prompt: false } }) => {
    const [showAddTagModal, setShowAddTagModal] = useState(false)
    const [isSavingTag, setIsSavingTag] = useState(false)
    const [newTagType, setNewTagType] = useState(null);
    const [newTagValue, setNewTagValue] = useState('') // for freetext
    const [newTagValues, setNewTagValues] = useState([]) // for dropdown
    const { userInfo, bookmarkItems, tagsChanged, conferenceGroupInfo } = useSelector(selectRoot)
    const [tags, setTags] = useState([])
    const dispatch = useDispatch()

    const enableAddButton = newTagType?.type === "dropdown" ? newTagValues.length : newTagValue

    // refetch note when bookmark change
    const isBookmarked = bookmarkItems.some(p => p.itemId === noteId)

    const addNewTag = async () => {
        setIsSavingTag(true);
        if (newTagType?.type === "dropdown") {
            newTagValues.forEach(async p => await addSingleNewTag(p))
        } else {
            await addSingleNewTag(newTagValue)
        }
        await getTags()
        dispatch(setTagsChanged({ type: "add", itemId: noteId }))
        setNewTagValue('')
        setNewTagValues([])
        setIsSavingTag(false);
        setShowAddTagModal(false);
    }
    const addSingleNewTag = async (tagValue) => {
        if (!tagValue.trim()) return;
        if (tags.some(p => p.tag.toLowerCase() === tagValue.trim().toLowerCase())) {
            dispatch(setGlobalAlert({
                type: 'danger', content: "tags should not duplicate"
            }))
            return;
        }
        if (tagValue.trim() === "Recommended") {
            dispatch(setGlobalAlert({
                type: 'warning', content: "This is reserved for system recommendation"
            }))
            return;
        }
        try {
            if (tagValue === "Bookmarked") { // bookmark tag, reducer call
                dispatch(
                    addBookmark(
                        {
                            tag: "Bookmarked",
                            invitation: `${conferenceGroupInfo?.id}/-/Bookmark`,
                            forum: noteId,
                            signatures: [userInfo?.user?.profile?.id],
                            userToken: userInfo?.token,
                        }
                    )
                )
            } else { // freetext tag local call
                await api.post(
                    "/tags", {
                    tag: tagValue,
                    invitation: `${conferenceGroupInfo?.id}/-/Tag`,
                    forum: noteId,
                    signatures: [userInfo?.user?.profile?.id]
                },
                    {
                        accessToken: userInfo?.token
                    }
                )
            }
        } catch (error) {
            dispatch(setGlobalAlert({
                type: 'danger', content: `${error.message}`
            }))
        }
    }

    // const addNewTag = async () => {
    //     if (!newTagValue.trim()) {
    //         setNewTagValue('')
    //         setShowAddTagModal(false);
    //         return;
    //     }
    //     if (tags.some(p => p.tag.toLowerCase() === newTagValue.trim().toLowerCase())) {
    //         dispatch(setGlobalAlert({
    //             type: 'danger', content: "tags should not duplicate"
    //         }))
    //         return
    //     }

    //     if (newTagValue.trim() === "Recommended") {
    //         dispatch(setGlobalAlert({
    //             type: 'warning', content: "This is reserved for system recommendation"
    //         }))
    //         return
    //     }
    //     setIsSavingTag(true);
    //     //setShowNewTagInput(false);
    //     try {
    //         if (newTagValue === "Bookmarked") { // bookmark tag, reducer call
    //             dispatch(
    //                 addBookmark(
    //                     {
    //                         tag: "Bookmarked",
    //                         invitation: 'ICLR.cc/2020/Conference/Virtual/-/Bookmark',
    //                         forum: noteId,
    //                         signatures: [userInfo?.user?.profile?.id],
    //                         userToken: userInfo?.token,
    //                     }
    //                 )
    //             )
    //         } else { // freetext tag local call
    //             await api.post(
    //                 "/tags", {
    //                 tag: newTagValue,
    //                 invitation: 'ICLR.cc/2020/Conference/Virtual/-/Tag',
    //                 forum: noteId,
    //                 signatures: [userInfo?.user?.profile?.id]
    //             },
    //                 {
    //                     accessToken: userInfo?.token
    //                 }
    //             )
    //             await getTags()
    //             dispatch(setTagsChanged({ type: "add", itemId: noteId }))
    //         }
    //     } catch (error) {
    //         dispatch(setGlobalAlert({
    //             type: 'danger', content: `${error.message}`
    //         }))
    //     } finally {
    //         setIsSavingTag(false)
    //         setShowAddTagModal(false);
    //         setNewTagValue('')
    //     }
    // }

    const deleteTag = async (tagObj) => {
        try {
            if (tagObj.tag === "Bookmarked") { // bookmark tag, reducer call
                dispatch(
                    deleteBookmark(
                        {
                            tagId: tagObj.id,
                            tag: "Bookmarked",
                            invitation: `${conferenceGroupInfo?.id}/-/Bookmark`,
                            forum: noteId,
                            signatures: [userInfo?.user?.profile?.id],
                            userToken: userInfo?.token,
                        }
                    )
                )
            } else { // freetext tag local call
                await api.post(
                    "/tags", {
                    id: tagObj.id,
                    tag: tagObj.tag,
                    ddate: +dayjs(),
                    invitation: `${conferenceGroupInfo?.id}/-/Tag`,
                    forum: noteId,
                    signatures: [userInfo?.user?.profile?.id]
                },
                    {
                        accessToken: userInfo?.token
                    }
                )
                await getTags()
                dispatch(setTagsChanged({ type: "add", itemId: noteId }))
            }
        } catch (error) {
            dispatch(setGlobalAlert({
                type: 'danger', content: `${error.message}`
            }))
        }
    }

    const handleDeleteTagClick = (e, tagObj) => {
        e.preventDefault();
        confirm({
            title: 'Delete tag',
            content: `delete tag ${tagObj.tag} ?`,
            okType: 'danger',
            okText: 'Delete',
            cancelText: 'no',
            zIndex: 1061,
            onOk() {
                deleteTag(tagObj)
            },
            onCancel() { },
        });
    }

    const getTags = async () => {
        const result = await api.get('/tags', { forum: noteId, cache: false }, { accessToken: userInfo.token })
        result.tags.forEach(p => { if (p.invitation === `${conferenceGroupInfo?.id}/-/Recommendation`) p.tag = "Recommended" })
        setTags(result.tags)
    }

    // show papers of clicked tag
    const handleTagClick = (tagObj) => {
        if (shouldFilterByTag.filter) {
            // if (tagObj.tag === "Recommended") {
            //     //dispatch(setGlobalAlert({ type: "warning", content: "go to bookmarks page to view all recommended papers" }));
            //     return;
            // }
            dispatch(setTagForPresentationSearch(tagObj.tag))
        }
        if (shouldFilterByTag.prompt) dispatch(setGlobalAlert({ type: "warning", content: "go to bookmarks page to view all tagged items" }));
    }

    // useEffect(() => {
    //     if (!showNewTagInput) return;
    //     newTagValueInputRef.current.focus();
    // }, [showNewTagInput])

    useEffect(() => {
        if (userInfo.token) getTags() // userinfo change will cause bookmark to change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBookmarked])

    useEffect(() => {
        dispatch(setIsModalOpen(showAddTagModal))
        // fetch invitation to decide add tag type when modal is open
        const getTagInvitation = async () => {
            try {
                const result = await api.get('/invitations', { id: `${conferenceGroupInfo?.id}/-/Tag` }, { accessToken: userInfo?.token })
                if (!showAddTagModal) return;
                console.log(result?.invitations[0]?.reply?.content?.tag?.["value-dropdown"])
                if (result?.invitations[0]?.reply?.content?.tag?.["value-dropdown"]?.length) {
                    setNewTagType({ type: "dropdown", options: result.invitations[0].reply.content.tag["value-dropdown"] })
                } else {
                    setNewTagType({ type: "freetext" })
                }
            } catch (error) {

            }
        }
        if (showAddTagModal) {
            getTagInvitation()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAddTagModal])

    useEffect(() => {
        if (tagsChanged.some(p => p === noteId)) {
            getTags();
            dispatch(setTagsChanged({ type: "remove", itemId: noteId }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(tagsChanged)])

    if (!validateToken(userInfo?.token)) return null;
    return (
        <>
            <style>
                {
                    `
                .tag-input{
                    width: 78px;
                    margin-right: 8px;
                    vertical-align: top;
                }
                .ant-tag-close-icon{
                    vertical-align:baseline;
                }
                .ant-tag{
                    cursor:pointer;
                }
              `
                }
            </style>
            {
                tags?.map((tagObj) => {
                    return tagObj.tag.length > 11
                        ? <Tooltip title={tagObj.tag} key={tagObj.id}>
                            <Tag color={getTagColor(tagObj.tag)} closable={tagObj.tag !== "Recommended"} onClose={(e) => handleDeleteTagClick(e, tagObj)} className="mb-1" onClick={() => handleTagClick(tagObj)}>
                                {tagObj.tag.length > 10 ? `${tagObj.tag.slice(0, 10)}...` : tagObj.tag}
                            </Tag>
                        </Tooltip>
                        : <Tag key={tagObj.id} color={getTagColor(tagObj.tag)} closable={tagObj.tag !== "Recommended"} onClose={(e) => handleDeleteTagClick(e, tagObj)} className="mb-1" onClick={() => handleTagClick(tagObj)}>
                            {tagObj.tag}
                        </Tag>
                })
            }
            <Tag onClick={() => { setShowAddTagModal(true) }} icon={isSavingTag ? <SyncOutlined spin /> : undefined}>
                Add tag
            </Tag>
            <Modal
                title="Add a tag"
                visible={showAddTagModal}
                maskClosable={false}
                closable={false}
                destroyOnClose={true}
                okText="Add"
                cancelText="cancel"
                onCancel={() => { setShowAddTagModal(false); setNewTagValue(''); }}
                confirmLoading={isSavingTag}
                onOk={addNewTag}
                okButtonProps={{ disabled: !enableAddButton }}
                onClick={e => e.stopPropagation()}
                zIndex={1061}
            >
                <Form layout="horizontal" id="add-tag-form">
                    <Form.Item label="Tag">
                        {   // freetext unless specified in conference setting
                            newTagType?.type === "dropdown"
                                ? <Select
                                    onChange={e => setNewTagValues(e)}
                                    dropdownStyle={{ zIndex: 1062 }}
                                    getPopupContainer={() => document.getElementById('add-tag-form')}
                                    mode="multiple"
                                    notFoundContent="Please select from available tags"
                                >
                                    {
                                        newTagType.options.map((option) => {
                                            return <Select.Option key={option} value={option}>{option}</Select.Option>
                                        })}
                                </Select>
                                : <Input autoFocus={true} placeholder="tag content" allowClear maxLength={100} value={newTagValue} onChange={e => setNewTagValue(e.target.value)} onPressEnter={addNewTag} />
                        }
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )

}

export default PaperTag