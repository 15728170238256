import { Form, Input, Button, Steps, Checkbox, Upload, Result } from "antd";
import 'antd/lib/form/style/index.css';
import 'antd/lib/steps/style/index.css';
import 'antd/lib/upload/style/index.css';
import { UploadOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Error from "./Error";
import api from "./lib/api-client";
import { loadStore, selectRoot, setGlobalAlert } from "./rootSlice";
import BraintreeWebDropIn from "braintree-web-drop-in";

const RegistrationForm = ({ registrationFormFields, setStep, registrationNote }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [costLabel, setCostLabel] = useState(registrationNote ? registrationNote.content?.["cost"] : registrationFormFields?.["cost"]?.["value-dropdown"][1])
    const { userInfo, conferenceGroupInfo } = useSelector(selectRoot);
    const [buttonLoading, setButtonLoading] = useState(false);
    // const [fileList, setFileList] = useState([]);

    const handleRegistrationFormSubmit = async (values) => {
        //create note here.
        setButtonLoading(true);
        try {
            const result = await api.post('/notes', {
                id: registrationNote ? registrationNote.id : undefined,
                invitation: `${conferenceGroupInfo.id}/-/Registration`,
                readers: [userInfo.user.profile.id],
                writers: [],
                signatures: [userInfo.user.profile.id],
                content: {
                    i_am_a_student: values["i_am_a_student"] ? "true" : "false",
                    student_id: values["student_id"]?.[0]?.response?.url ?? "",
                    cost: costLabel.toString(),
                    open_for_recruitment_by_sponsor: values["open_for_recruitment_by_sponsor"] ? "true" : "false"
                }
            }, { accessToken: userInfo.token })
        } catch (error) {
            dispatch(setGlobalAlert({ type: "error", content: error.message }))
        } finally {
            setButtonLoading(false);
        }
        setStep(1);
    }

    const handleStudentCheckboxChange = (e) => {
        setCostLabel(registrationFormFields["cost"]["value-dropdown"][e.target.checked ? 0 : 1])

    }

    // const checkFileType = (file, fileTypes) => {
    //     if(fileTypes.map(p => `image/${p}`).includes(file.type)){
    //         return true;
    //     }
    //     dispatch(setGlobalAlert({type:"error",content:'file type is not correct'}));
    //     return false;
    // }

    // const updateFileList = (info)=>{
    //     console.log(info.fileList.filter(file => !!file.status))
    //     setFileList((info.fileList.filter(file => !!file.status)));
    // }


    const renderFormControl = (ContentObject, field) => {
        if (field === "cost") return costLabel && <span key={field} className="h3">Price: {costLabel} USD</span>

        if (field === "i_am_a_student")
            return <Form.Item key={field} name={field} valuePropName="checked">
                <Checkbox onClick={handleStudentCheckboxChange}>{`${field[0].toUpperCase()}${field.slice(1)}`.replaceAll('_', ' ')}</Checkbox>
            </Form.Item>

        if (ContentObject[field]["value-regex"]) {
            // input
            return (
                <Form.Item key={field} name={field} label={`${field[0].toUpperCase()}${field.slice(1)}`.replaceAll('_', ' ')} rules={[{ required: registrationFormFields[field].required }]}>
                    <Input />
                </Form.Item>
            )
        }

        if (ContentObject[field]["value-radio"]) {
            return (
                <Form.Item key={field} name={field} valuePropName="checked">
                    <Checkbox>{`${field[0].toUpperCase()}${field.slice(1)}`.replaceAll('_', ' ')}</Checkbox>
                </Form.Item>
            )
        }

        if (ContentObject[field]["value-file"]) {
            return (
                <Form.Item key={field} name={field}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => (e.fileList)}
                    hidden={!form.getFieldValue("i_am_a_student")}
                    extra={<><span>.jpg/.jpeg/.png files only. </span><span style={{ color: "red" }}>Uploading invalid student Id will cause extra charge</span></>}
                >
                    <Upload
                        action={`${process.env.REACT_APP_API_URL}/attachment/`}
                        method="put"
                        headers={{ Authorization: `Bearer ${userInfo?.token}` }}
                        data={() => ({ invitationId: `${conferenceGroupInfo.id}/-/Registration`, name: "student_id" })}
                        accept={registrationFormFields[field]["value-file"].fileTypes.map(p => `image/${p}`).join(',')}
                        // fileList={fileList}
                        maxCount={1}
                    // beforeUpload={(file, _) => checkFileType(file, registrationFormFields[field]["value-file"].fileTypes)}
                    // onChange={updateFileList}
                    >
                        <Button icon={<UploadOutlined />}>{`Upload ${field[0].toUpperCase()}${field.slice(1)}`.replaceAll('_', ' ')}</Button>
                    </Upload>
                </Form.Item>
            )
        }
    }

    const renderFormControlExisting = (ContentObject, field) => {
        if (field === "cost") return costLabel && <span key={field} className="h3">Price: {costLabel} USD</span>

        if (field === "i_am_a_student")
            return <Form.Item key={field} name={field} valuePropName="checked" initialValue={registrationNote.content[field] === "true"}>
                <Checkbox onClick={handleStudentCheckboxChange}>{`${field[0].toUpperCase()}${field.slice(1)}`.replaceAll('_', ' ')}</Checkbox>
            </Form.Item>

        if (ContentObject[field]["value-regex"]) {
            // input
            return (
                <Form.Item key={field} name={field} initialValue={registrationNote.content[field]} label={`${field[0].toUpperCase()}${field.slice(1)}`.replaceAll('_', ' ')} rules={[{ required: registrationFormFields[field].required }]}>
                    <Input />
                </Form.Item>
            )
        }

        if (ContentObject[field]["value-radio"]) {
            return (
                <Form.Item key={field} name={field} valuePropName="checked" initialValue={registrationNote.content[field] === "true"}>
                    <Checkbox>{`${field[0].toUpperCase()}${field.slice(1)}`.replaceAll('_', ' ')}</Checkbox>
                </Form.Item>
            )
        }

        if (ContentObject[field]["value-file"]) {
            return (
                <Form.Item key={field} name={field}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => (e.fileList)}
                    hidden={!form.getFieldValue("i_am_a_student")}
                    extra={<><span>.jpg/.jpeg/.png files only. </span><span style={{ color: "red" }}>Uploading invalid student Id will cause extra charge</span></>}
                >
                    <Upload
                        action={`${process.env.REACT_APP_API_URL}/attachment/`}
                        method="put"
                        headers={{ Authorization: `Bearer ${userInfo?.token}` }}
                        data={() => ({ invitationId: `${conferenceGroupInfo.id}/-/Registration`, name: "student_id" })}
                        accept={registrationFormFields[field]["value-file"].fileTypes.map(p => `image/${p}`).join(',')}
                        // fileList={fileList}
                        maxCount={1}
                    // beforeUpload={(file, _) => checkFileType(file, registrationFormFields[field]["value-file"].fileTypes)}
                    // onChange={updateFileList}
                    >
                        <Button icon={<UploadOutlined />}>{`Upload ${field[0].toUpperCase()}${field.slice(1)}`.replaceAll('_', ' ')}</Button>
                    </Upload>
                </Form.Item>
            )
        }
    }

    useEffect(() => {
        if (registrationNote) {
            setCostLabel(registrationNote.content.cost);
            return;
        }
        setCostLabel(registrationFormFields?.["cost"]?.["value-dropdown"][1]);
    }, [registrationFormFields, registrationNote])


    if (!registrationFormFields && !registrationNote) return null;

    if (registrationNote) return (
        <Form form={form} onFinish={handleRegistrationFormSubmit}>
            {
                Object.keys(registrationFormFields) // get all keys
                    ?.map((p) => ({ key: p, order: registrationFormFields[p]?.order })) // get key and order
                    ?.sort((a, b) => a?.order - b?.order) // get sorted key and order by order
                    ?.map(q => q.key) // get sorted key
                    ?.map(field => {
                        return renderFormControlExisting(registrationFormFields, field)
                    })
            }
            <Form.Item>
                <Button htmlType="submit" type="primary" className="mt-5" loading={buttonLoading}>Submit</Button>
            </Form.Item>
        </Form>
    )

    return (
        <Form form={form} onFinish={handleRegistrationFormSubmit}>
            {
                Object.keys(registrationFormFields) // get all keys
                    ?.map((p) => ({ key: p, order: registrationFormFields[p].order })) // get key and order
                    ?.sort((a, b) => a.order - b.order) // get sorted key and order by order
                    ?.map(q => q.key) // get sorted key
                    ?.map(field => {
                        return renderFormControl(registrationFormFields, field)
                    })
            }
            <Form.Item>
                <Button htmlType="submit" type="primary" className="mt-5" loading={buttonLoading}>Submit</Button>
            </Form.Item>
        </Form>
    )
}

const BrainTreePaymentForm = ({ setStep, getRegistrationNote }) => {
    const braintreeContainerRef = useRef(null);
    const [brainTreeInstance, setBrainTreeInstance] = useState(null);
    const { userInfo, conferenceGroupInfo } = useSelector(selectRoot);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const initializeBrainTree = async () => {
        try {
            const result = await BraintreeWebDropIn.create({ authorization: process.env.REACT_APP_BRAINTREE_CLIENT_TOKEN, container: braintreeContainerRef.current });
            setBrainTreeInstance(result);
        } catch (error) {
            dispatch(setGlobalAlert({ type: "error", content: error.message }))
        }
    }

    const handleSubmitPayment = async () => {
        if (!brainTreeInstance) return;
        setIsLoading(true)
        try {
            let result = await brainTreeInstance.requestPaymentMethod();
            console.log(result);
            //call api with nounce
            result = await api.post('/user/registration/pay', { conferenceId: conferenceGroupInfo.id, clientToken: result.nonce }, { accessToken: userInfo.token });
            await getRegistrationNote();
            setIsLoading(false);
        } catch (error) {
            dispatch(setGlobalAlert({ type: "error", content: error.message }))
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        initializeBrainTree()
    }, [])

    return (
        <Container>
            <div ref={braintreeContainerRef} id="braintree-container"></div>
            {brainTreeInstance && <Button type="primary" onClick={handleSubmitPayment} loading={isLoading}>Submit Payment</Button>}
        </Container>
    )
}

const PaymentStep = ({ registrationNote, setStep, getRegistrationNote }) => {
    if (registrationNote?.content?.["payment_status"] === "completed") return "you have completed registration";
    if (registrationNote?.content?.["payment_status"]) return "your payment has been submitted";

    return (
        <>
            {registrationNote?.content?.["payment_status"] && "previous payment did not go through. try again"}
            <BrainTreePaymentForm setStep={setStep} getRegistrationNote={getRegistrationNote} />
        </>
    )
}

const Registration = () => {
    const { conferenceGroupInfo, userInfo } = useSelector(selectRoot);
    const [registrationFormFields, setRegistrationFormFields] = useState(null);
    const [registrationNote, setRegistrationNote] = useState(null)
    const [currentStep, setCurrentStep] = useState(-1);
    const [registrationStatus, setRegistrationStatus] = useState(null)
    const dispatch = useDispatch();
    let { groupId } = useParams();
    const history = useHistory();

    let title = conferenceGroupInfo?.id;
    try {
        const webJson = JSON.parse(conferenceGroupInfo?.web);
        title = webJson?.title;
    } catch (error) { }

    const getRegistrationNote = async () => {
        try {
            const result = await api.get('/notes', { invitation: `${groupId}/-/Registration`, tauthor: true, cache: false }, { accessToken: userInfo.token });
            if (result.count === 1) {
                // submitted registration form before
                setRegistrationNote(result.notes[0])
                result.notes[0].content?.["payment_status"] ? setCurrentStep(2) : setCurrentStep(1)
            }
            if (result.count === 0) {
                // new
                setRegistrationStatus('formNotCompleted');
                getRegistrationInvitation()
                setCurrentStep(0);
            }
        } catch (error) {
            dispatch(setGlobalAlert({ type: "error", content: error.message }))
        }
    }

    const getRegistrationInvitation = async () => {
        try {
            const result = await api.get('/invitations', { id: `${groupId}/-/Registration` }, { accessToken: userInfo.token })
            if (result?.invitations?.length === 1) setRegistrationFormFields(result.invitations[0]?.reply?.content);
        } catch (error) {

        }
    }


    useEffect(() => {
        if (!groupId) return;
        if (Object.keys(conferenceGroupInfo).length && conferenceGroupInfo.id === groupId) return;
        dispatch(loadStore({ id: groupId, history: history }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId]);

    useEffect(() => {
        if (!groupId) return;
        if (!conferenceGroupInfo?.id) return;

        getRegistrationInvitation(); // also needed for existing note to get cost incase user change
        getRegistrationNote();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // clear states
        setRegistrationFormFields(null);
        setRegistrationNote(null);
        getRegistrationInvitation();
        getRegistrationNote();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo])

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <RegistrationForm registrationFormFields={registrationFormFields} setStep={setCurrentStep} registrationNote={registrationNote} />
            case 1:
                return <PaymentStep registrationNote={registrationNote} setStep={setCurrentStep} getRegistrationNote={getRegistrationNote} />
            case 2:
                return <Result
                    status="success"
                    title="Your payment info has been submitted for processing"
                    subTitle={`Transaction ID: ${registrationNote?.content?.["transaction_ids"]?.[registrationNote?.content?.["transaction_ids"]?.length - 1]}`}
                />
            default:
                break;
        }
    }


    if (!conferenceGroupInfo?.id) return <Error message="Conference does not exist." extraMessage="Please try again with correct conference id." />
    if (!userInfo?.token) return <Error message="You are not authorized to view this page." extraMessage="Please login." />
    return (
        <Container className="d-flex flex-column w-50">
            <h4 className="mt-2">{`Register for ${title}`}</h4>
            {[0, 1].includes(currentStep) && <Steps className="my-5" current={currentStep} size="small" onChange={(e) => { setCurrentStep(e) }}>
                <Steps.Step title="Participant Information" />
                <Steps.Step title="Payment" disabled={!registrationNote} />
            </Steps>}
            {renderStep()}
        </Container>
    );
}

export default Registration;