import { configureStore } from "@reduxjs/toolkit";
import {
  //persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate
} from 'redux-persist'
//import storage from 'redux-persist/lib/storage'
import rootReducer, { initialState } from "./rootSlice";
import localForage from 'localforage'
//import sessionStorage from "redux-persist/lib/storage/session";
//import { createStore, applyMiddleware } from 'redux';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

const migrations = {
  2: (state) => initialState,
};

const persistConfig = {
  key: "root",
  version: 2,
  //storage: sessionStorage,
  storage: localForage,
  migrate: createMigrate(migrations, { debug: false })
};

const syncConfig = {
  whitelist: ['logout/fulfilled', 'login/fulfilled']
};

const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }).concat(createStateSyncMiddleware(syncConfig))
})

initMessageListener(store);

export default store
