import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectRoot } from "../../rootSlice";
import PaperCard from "./PaperCard-ant";
import CardsSkeleton from "./CardsSkeleton";
import { Empty } from "antd";

const PaperCards = ({ notes, showSessionsInfo = true, isLoading, empty, shouldFilterByTag = false }) => {
  const { sessionsInfoFormatted } = useSelector(selectRoot);

  if (isLoading)
    return (
      <Container>
      <Row className="justify-content-center w-100 mt-2">
        <CardsSkeleton />
      </Row>
      </Container>)
  if(notes.length===0) return (
    <Container>
    <Row className="justify-content-center w-100 mt-2">
      {empty??<Empty/>}
    </Row>
    </Container>
  )
  return (
    <Container className="p-1 w-100">
      <Row className="justify-content-start w-100 mt-2 mx-0">
        {notes?.map((note) => {
          let sessionsAssociated = [];
          if (showSessionsInfo) sessionsAssociated = sessionsInfoFormatted?.filter((p) =>
            note?.content?.sessions?.includes(p.id)
          );
          return (
            <Col
              key={note.id}
              lg={4}
              sm={6}
              xs={12}
              className="mb-5 d-flex justify-content-center p-0 p-lg-3 p-md-2 p-sm-1"
            >
              <PaperCard note={note} key={note.id} sessions={sessionsAssociated} shouldFilterByTag={shouldFilterByTag} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default PaperCards;
