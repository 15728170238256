import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { selectRoot, logout } from "../rootSlice";
import { useDispatch, useSelector } from "react-redux";
//import { deleteUser } from "../lib/rocketchat";
import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import useQuery from "../lib/hooks/useQuery";
import GlobalAlert from "./GlobalAlert";
import AvatarImage from "./AvatarImage";
import { useState } from "react";
import { EditFilled } from "@ant-design/icons";
// import AddMenuGroup from "./home/AddMenuGroup";
import LoginModal from "./LoginModal";
//import { Badge } from "antd";
import 'antd/lib/badge/style/index.css';
import EditMenu from "./home/EditMenu";

const Navigation = () => {
  const {
    userInfo,
    dynamicMenus,
    conferenceGroupInfo,
  } = useSelector(selectRoot);
  const dispatch = useDispatch();
  let query = useQuery();
  let location = useLocation();
  const [expandNavBar, setExpandNavBar] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showEditMenuModal, setShowEditMenuModal] = useState(false)

  const showEditButton = conferenceGroupInfo?.details?.writable && query.get("mode") === null && location.pathname === `/${conferenceGroupInfo.id}`;;
  const isEdit =
    conferenceGroupInfo?.details?.writable && query.get("mode") === "edit" && location.pathname === `/${conferenceGroupInfo.id}`;
  const hasStore = Object.keys(conferenceGroupInfo).length > 0

  const handleLogoutClick = () => {
    dispatch(logout({}));
  };

  const handleCloseToggleNavBar = () => {
    if (expandNavBar) setExpandNavBar(false)
  }

  const CloseToggleNavLink = ({ to, className, activeStyle, children }) => {
    return (
      <NavLink to={to} className={className} activeStyle={activeStyle} onClick={handleCloseToggleNavBar}>
        {children}
      </NavLink>
    )
  }

  const renderMenuLink = (menu) => {
    switch (menu.name) {
      case "schedule":
        return (
          <CloseToggleNavLink key="schedule" to={`/calendar/${conferenceGroupInfo.id}`} className="nav-link" activeStyle={{ color: "black" }}>
            Schedule
          </CloseToggleNavLink>
        )
      case "presentations":
        return (
          <CloseToggleNavLink key="presentations" to={`/presentations/${conferenceGroupInfo.id}`} className="nav-link" activeStyle={{ color: "black" }}>
            Presentations
          </CloseToggleNavLink>
        )
      default:
        return renderGroupMenu(menu)
    }
  }

  const renderGroupMenu = (menu) => {
    if (!menu.visible) return null;
    if (menu.submenus && menu.submenus?.filter(p => p.visible)?.length) {
      return (
        <NavDropdown title={`${menu.text}`} id={menu.groupId} key={menu.text}>
          {menu.submenus.slice().filter(p => p.visible).sort((p, q) => { if (!p.order) return 1; if (!q.order) return -1; return p.order - q.order }).map((submenu, index) => {
            return (
              <NavDropdown.Item key={`${submenu.text}${index}`} as="div">
                <div className="d-flex justify-content-between">
                  <CloseToggleNavLink to={`/group/${submenu.groupId}`} className="nav-link">
                    {submenu.text}
                  </CloseToggleNavLink>
                </div>
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      )
    }
    return (
      <CloseToggleNavLink key={menu.groupId} to={`/group/${menu.groupId}`} className="nav-link" activeStyle={{ color: "black" }}>
        {menu.text}
      </CloseToggleNavLink>
    )
  }

  useEffect(() => {
    if (userInfo?.token) setShowLoginModal(false)
  }, [userInfo])

  if (!hasStore) return null;

  return (
    <>
      <Navbar expand="xl" variant="light" className="text-nowrap navigation-bar px-5" style={{ width: "100vw" }} expanded={expandNavBar}>
        <NavLink to={`/${conferenceGroupInfo.id}`}>
          <Navbar.Brand>
            <img src={`${process.env.PUBLIC_URL}/${conferenceGroupInfo?.id?.split('/')?.[0]}.png`} alt=""></img>
          </Navbar.Brand>
        </NavLink>
        <Navbar.Toggle aria-controls="navbar-collapse" onClick={() => setExpandNavBar(!expandNavBar)} />
        <Navbar.Collapse id="navbar-collapse">
          <Nav className="ml-auto">
            {
              isEdit && <>
                <EditFilled className="mb-1" style={{ alignSelf: "center", color: "#1890ff" }} onClick={() => setShowEditMenuModal(true)} />
                <EditMenu show={showEditMenuModal} setShow={setShowEditMenuModal} />
              </>
            }
            {
              showEditButton && <CloseToggleNavLink to={`${location.pathname}?mode=edit`} className="nav-link">Edit Layout</CloseToggleNavLink>
            }
            {dynamicMenus.slice().concat([{name:"schedule",order:10},{name:"presentations",order:20}]).sort((p, q) => { if (!p.order) return 1; if (!q.order) return -1; return p.order - q.order }).map((menu) => {
              return renderMenuLink(menu)
            })}
            {userInfo?.token ? (
              <NavDropdown title={(<>
                <AvatarImage />
                {` ${userInfo.user.profile.first} ${userInfo.user.profile.last}`}
              </>)} id="userDropdown">
                <NavDropdown.Item as="div" className="disabled">
                  <CloseToggleNavLink to={`/uploadavatar`} className="nav-link">
                    Upload Avatar
                  </CloseToggleNavLink>
                </NavDropdown.Item>
                <NavDropdown.Item as="div">
                  <CloseToggleNavLink to={`/bookmark`} className="nav-link">
                    Bookmarks
                  </CloseToggleNavLink>
                </NavDropdown.Item>
                {conferenceGroupInfo?.id && <NavDropdown.Item as="div">
                  <CloseToggleNavLink to={`/registration/${conferenceGroupInfo.id}`} className="nav-link">
                    Register
                  </CloseToggleNavLink>
                </NavDropdown.Item>}
                <NavDropdown.Item onClick={handleLogoutClick} >
                  <span className="nav-link">
                    Log Out
                  </span>
                </NavDropdown.Item>
              </NavDropdown>

            ) :
              <span className="nav-link" onClick={() => setShowLoginModal(true)} style={{ cursor: "pointer" }}>
                Login
                </span>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <GlobalAlert />
      <LoginModal show={showLoginModal} setShow={setShowLoginModal} />
    </>
  );
};

export default Navigation;
