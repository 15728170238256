import { Container } from "react-bootstrap"
import { Upload,Image } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/lib/upload/style/index.css';
import 'antd/lib/image/style/index.css';
import 'antd/lib/modal/style/index.css';
import 'antd/lib/slider/style/index.css';
import { useDispatch, useSelector } from "react-redux";
import {selectRoot, setGlobalAlert} from './rootSlice'

const UploadAvatar = () => {
    const dispatch=useDispatch()
    const {userInfo}=useSelector(selectRoot)

    const checkFileType = (file) => {
        if(!['image/jpeg','image/png'].includes(file.type)){
            dispatch(setGlobalAlert({
                type: 'error', content: 'only jpeg and png files are allowed'
            }))
            return false
        }
        return true
    }

    const onChange=({file,event})=>{
        if(file.status==='error'){
            const errorDetail=file.response?.errors?.[0]
            dispatch(setGlobalAlert({
                type: "error", content: errorDetail?`${errorDetail.path} ${errorDetail.value} ${errorDetail.type}`:"something wrong"
            }))
        }
        if(file.status==='done'){
            dispatch(setGlobalAlert({
                type: "success", content: "avatar updated, please log out and log in"
            }))
        }
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };
    
    const constructBody=(file)=>{
        return {
            invitationId:'~/-/profiles/avatar',
            name:'avatar'
        }
    }

    return (
        <Container className="mt-5">
            <style>
                {
                    `
                    .ant-modal.zoom-appear{
                        opacity:1;
                    }
                    `
                }
            </style>
            <ImgCrop rotate>
                <Upload
                    action={`${process.env.REACT_APP_API_URL}/attachment/avatar`}
                    method="put"
                    listType="picture-card"
                    showUploadList={false}
                    onChange={onChange}
                    onPreview={onPreview}
                    beforeUpload={checkFileType}
                    data={constructBody}
                    headers= {{Authorization:`Bearer ${userInfo?.token}`}}
                >
                    Upload picture
                </Upload>
            </ImgCrop>
        </Container>
    )
}

export default UploadAvatar