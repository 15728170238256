import { Route, Switch } from "react-router-dom";
// import Calendar from "./Calendar";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import Home from "./Home";
import "bootstrap/dist/css/bootstrap.min.css";
//import 'antd/dist/antd.css'
//import "./antdused.css"
//import "./antd.css"
import Session from "./Session";
import Group from "./Group";
import Presentation from "./Presentation";
// import Login from "./Login";
import Error from "./Error";
import Presentations from "./Presentations";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import UploadAvatar from "./UploadAvatar";
import GroupIdMissingAction from "./components/Error/GroupIdMissingAction";
import Bookmarks from "./Bookmarks";
import { BackTop } from "antd";
import 'antd/lib/back-top/style/index.css';
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { selectRoot } from "./rootSlice";
import { useEffect } from "react";
import Calendar2 from "./Calendar2";
import CalendarICLR from "./CalendarICLR";
import Registration from "./Registration";

const App = () => {
  const { conferenceGroupInfo, userInfo } = useSelector(selectRoot);
  // const dispatch = useDispatch();
  let title = conferenceGroupInfo?.id;
  let shortCalendar = false;
  try {
    const webJson = JSON.parse(conferenceGroupInfo?.web);
    title = webJson?.title;
    shortCalendar = webJson?.calendar;
  } catch (error) {

  }
  const conferenceSpecificSettingId = conferenceGroupInfo?.id?.split('/')?.[0]

  useEffect(() => { // register listener for iframe ready msg from embed forum
    const embedForumReadyHandler = (e) => {
      if (e?.origin !== `${process.env.REACT_APP_WEB_URL}`) return
      if (e?.data?.ready) e?.source.postMessage({
        command: "login",
        token: userInfo.token,
      }, `${process.env.REACT_APP_WEB_URL}`);
    }
    window.addEventListener('message', embedForumReadyHandler)
    return () => {
      window.removeEventListener('message', embedForumReadyHandler)
    }
  }, [userInfo])

  // useEffect(()=>{
  //   if(conferenceGroupInfo?.id){
  //     console.log(`import ./${favIcoName}.css`)
  //     import(`./${favIcoName}.css`).catch(e=>console.log(e))
  //   }
  // },[conferenceGroupInfo])

  useEffect(() => {
    if(!conferenceGroupInfo?.id) return;

    // add conference specific css
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = `/style/${conferenceSpecificSettingId}.css`;
    head.appendChild(link);

    // load conference specific setting
    // const loadConferenceSettings = async () => {
    //   const conferenceSetting = await import(`./ConfOptions/${conferenceSpecificSettingId}.json`).catch(e => console.log(e));
    //   dispatch(setConferenceSetting(conferenceSetting?.default))
    // }
    // loadConferenceSettings()

    return () => { 
      head.removeChild(link); 
      // dispatch(setConferenceSetting({}))
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conferenceGroupInfo?.id]);

  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{ height: "100%", minHeight: "100vh" }}
    >
      <style>
        {
          `
          html, body {
            //overflow-x:hidden;
            // overflow-wrap:break-word; 
          } 
          body{
            overflow-x:hidden;
          }
          `
        }
      </style>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/${conferenceSpecificSettingId??"default"}.ico`} />
      </Helmet>
      <span style={{display:"none"}}>{process.env.REACT_APP_VERSION}</span>
      <Navigation />
      <div className="no-margin mb-5 flex-grow-1">
        <Switch>
          {/* <Route path="/login">
            <Login />
          </Route> */}
          <Route path="/uploadavatar">
            <UploadAvatar />
          </Route>
          <Route path="/bookmark">
            <Bookmarks />
          </Route>
          <Route path="/session/:id">
            <Session />
          </Route>
          <Route path="/presentation/:id">
            <Presentation />
          </Route>
          <Route path="/error">
            <Error />
          </Route>
          <Route path="/" exact>
            <Error message="Conference group id is missing" extraMessage="Please add the conference group id in URI" cleanUp={true} extra={<GroupIdMissingAction />} />
          </Route>
          <Route path="/group/:groupId+">
            <DndProvider backend={HTML5Backend}>
              <Group />
            </DndProvider>
          </Route>
          <Route path="/calendar/:groupId+">
            {/* <Calendar /> */}
            {shortCalendar? <CalendarICLR /> : <Calendar2 />}
          </Route>
          <Route path="/presentations/:groupId+">
            <Presentations />
          </Route>
          <Route path="/registration/:groupId+">
            <Registration />
          </Route>
          {/* <Route path="/footer/:groupId+">
            <Footer />
          </Route> */}
          <Route path="/:groupId+">
            <DndProvider backend={HTML5Backend}>
              <Home />
            </DndProvider>
          </Route>
          <Route>
            <Error message="no such route" />
          </Route>
        </Switch>
      </div>
      <Footer />
      <BackTop><div style={{ height: "40px", width: "40px", textAlign: "center", backgroundColor: '#1088e9', color: '#fff', lineHeight: '40px', }}>Top</div></BackTop>
    </div>
  );
};

export default App;
