// import { Link } from "react-router-dom";
import { SessionCard } from "../session/SessionCard";
// import Duration from "./Duration";

const ListDay = ({ date, sessions }) => {
  return (
    <div>
      <h3>{date.format("dddd, MMMM D, YYYY")}</h3>
      {sessions?.map((session) => {
        return (
          // <div key={session.id} className={session.type??'poster'}>
          //   <p>
          //     <Link to={`/session/${session.id}`}>
          //        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          //       {/* <a style={{color:"black",textDecoration:"none"}}> */}
          //         <strong style={{color:"black",textDecoration:"none",cursor:"pointer"}}>{session.title}</strong>
          //       {/* </a> */}
          //     </Link>
          //   </p>
          //   <p>{session.description}</p>
          //   <p>
          //     {session.start.formattedHour} to {session.end.formattedHour}{" "}
          //     <Duration
          //       durationHours={session.duration.hours}
          //       durationMinutes={session.duration.minutes}
          //     />
          //   </p>
          //   <hr />
          // </div>
          <SessionCard key={session?.id} session={session}/>
        );
      })}
    </div>
  );
};

export default ListDay;
