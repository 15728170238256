import jwtDecode from "jwt-decode";
import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import FileSaver from "file-saver"
import * as ics from "ics";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const validateToken = (token) => {
  let payload;
  try {
    payload = jwtDecode(token);
  } catch (error) {
    payload = null;
  }

  // Validate JWT expiration date and issuer
  if (
    !payload ||
    payload.exp * 1000 < Date.now() ||
    payload.iss !== "openreview"
  ) {
    return null;
  }

  return token;
};

export const isDateOverlapping = ({ date1Start, date1End, date2Start, date2End }) => {
  const isOverlapping =
    (date2End.isAfter(date1Start) && date2End.isSameOrBefore(date1End)) ||
    (date2Start.isSameOrAfter(date1Start) && date2Start.isBefore(date1End)) ||
    (date2Start.isSameOrBefore(date1Start) && date2End.isSameOrAfter(date1End));

  return isOverlapping;
}

export const exportICS = async ({ eventsToExport, saveFileName }) => {
  const formattedEvents = eventsToExport.map((event) => {
    const startObject = dayjs(Number(event.start));
    const endObj = dayjs(Number(event.end));
    return {
      start: [
        startObject.year(),
        startObject.month() + 1,
        startObject.date(),
        startObject.hour(),
        startObject.minute(),
      ],
      end: [
        endObj.year(),
        endObj.month() + 1,
        endObj.date(),
        endObj.hour(),
        endObj.minute(),
      ],
      title: event.title,
      description: event.description,
      productId: "openreview/live"
    };
  });
  const result = await ics.createEvents(formattedEvents);
  const blob = new Blob([result.value], {
    type: "text/calendar;charset=utf-8",
  });
  FileSaver.saveAs(blob, `${saveFileName}.ics`);
}

export const calendarColor = {
  "poster":"lightblue",
  "expo":"lightgreen",
  "qa":"lightsalmon",
  "presentation":"lightpink",
}

export const presentationTypeTagColor = {
  "poster":"geekblue",
  "expo":"green",
  "qa":"orange",
  "presentation":"pink",
}

const antTagColorList=["magenta","red","volcano","orange","gold","lime","green","cyan","blue","geekblue","purple"]
let tagColor={
  "Bookmarked": "blue",
  "Recommended": "red",
}

export const getTagColor=(tagContent)=>{
  if(!tagColor[tagContent]) tagColor[tagContent]=antTagColorList[Math.floor(Math.random() * Math.floor(antTagColorList.length))]
  return tagColor[tagContent]
}

export const globalAlertTypes=["success","error","info","warning"]

export const getErrorMessage=(apiErrorObj)=>{
  if(typeof(apiErrorObj.details)==="string") return apiErrorObj.details
  if(typeof(apiErrorObj.details)==="object") return `${apiErrorObj.details.path} ${apiErrorObj.details.value} ${apiErrorObj.details.type}`
  return apiErrorObj.message
}

export const prettyId=(id, onlyLast) =>{
  const lowercaseExceptions = [
    'conference',
    'workshop',
    'submission',
    'recommendation',
    'paper',
    'review',
    'reviewer',
    'reviewers',
    'official',
    'public',
    'meta',
    'comment',
    'question',
    'acceptance',
    'pcs',
    'affinity',
    'bid',
    'tpms',
  ]

  if (!id) {
    return ''
  } else if (id.indexOf('~') === 0) {
    return id.substring(1).replace(/_|\d+/g, ' ').trim()
  } else if (id === 'everyone' || id === '(anonymous)' || id === '(guest)') {
    return id
  } else {
    let tokens = id.split('/')
    if (onlyLast) {
      const sliceIndex = tokens.findIndex(p=>p.match(/^[pP]aper\d+$/))//findIndex(tokens, token => token.match(/^[pP]aper\d+$/))
      tokens = tokens.slice(sliceIndex)
    }

    const transformedId = tokens
      .map((token) => {
        let transformedToken = token
          .replace(/\..+/g, '') // remove text after dots, ex: uai.org
          .replace(/^-$/g, '') // remove dashes
          .replace(/_/g, ' ') // replace undescores with spaces

        // if the letters in the token are all lowercase, replace it with empty string
        const lettersOnly = token.replace(/\d|\W/g, '')
        if (lettersOnly && lettersOnly === lettersOnly.toLowerCase()
          && lowercaseExceptions.indexOf(token) < 0) {
          transformedToken = ''
        }

        return transformedToken
      })
      .filter(formattedToken => formattedToken)
      .join(' ')

    return transformedId || id
  }
}