import { Button } from "antd";
import { useRef } from "react";
//import { Button } from "react-bootstrap";
import { useDrag, useDrop } from "react-dnd";

const DynamicComponentOrder = ({ uniqueId, findComponent, moveComponent }) => {
  const ref = useRef(null);

  const originalIndex = findComponent(uniqueId);
  const [{ isDragging }, drag] = useDrag({
    item: { type: "dynamicComponentEdit", uniqueId, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    // end: (dropResult, monitor) => {
    //   const { id: droppedId, originalIndex } = monitor.getItem();
    //   const didDrop = monitor.didDrop();
    //   // if (!didDrop) {
    //   //     moveComponent(droppedId, originalIndex);
    //   // }
    // },
    canDrag: () => true,
  });
  const [, drop] = useDrop({
    accept: "dynamicComponentEdit",
    canDrop: () => false,
    hover({ uniqueId: draggedUniqueId }) {
      if (draggedUniqueId !== uniqueId) {
        const toIndex = findComponent(uniqueId);
        moveComponent(draggedUniqueId, toIndex);
      }
    },
  });

  drag(drop(ref));
  return (
    <Button
      type="primary"
      className="mb-2"
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {`${uniqueId + 1}`}
    </Button>
  );
};

export default DynamicComponentOrder;
