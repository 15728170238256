import { useDispatch, useSelector } from "react-redux"
import { addBookmark, deleteBookmark, selectRoot } from "../rootSlice"
import Icon from '@ant-design/icons';
import { validateToken } from "../lib/utility";
import { SyncOutlined } from '@ant-design/icons';

const bookmarkSvg = () => {
    return <svg viewBox="0 0 200 400" width="1em" height="1em" fill="currentColor">
        <path d="M 0 0 v -1 h 200 v 400 l -100 -100 l -100 100 z"></path>
    </svg>
}

const BookmarkIcon = props => <Icon component={bookmarkSvg} {...props} />

const BookmarkButton = ({ item, size, margin }) => {
    const dispatch = useDispatch()
    const { bookmarkItems, userInfo, bookmarkItemsLoading,conferenceGroupInfo } = useSelector(selectRoot)

    const isBookmarked = bookmarkItems.some(p => p.itemId === item.id)
    const isLoading = bookmarkItemsLoading.some(p => p === item.id)

    const handleBookmarkIconClick = () => {
        isBookmarked
            ? dispatch(
                deleteBookmark(
                    {
                        tagId: bookmarkItems.filter(p => p.itemId === item.id)[0].tagId,
                        tag: "Bookmarked",
                        invitation: `${conferenceGroupInfo?.id}/-/Bookmark`,//'ICLR.cc/2020/Conference/Virtual/-/Bookmark',
                        forum: item.id,
                        signatures: [userInfo?.user?.profile?.id],
                        userToken: userInfo?.token,
                    }
                )
            ) : dispatch(
                addBookmark(
                    {
                        tag: "Bookmarked",
                        invitation: `${conferenceGroupInfo?.id}/-/Bookmark`,
                        forum: item.id,
                        signatures: [userInfo?.user?.profile?.id],
                        userToken: userInfo?.token,
                    }
                )
            )
    }

    if (!validateToken(userInfo?.token)) return null
    if (isLoading) return <span className=""><SyncOutlined spin style={{ height: 'fit-content',fontSize: "x-small",verticalAlign:"top" }} className="mx-2 mt-1" /></span>
    return <BookmarkIcon style={{ fontSize: `${size}`, color: `${isBookmarked ? 'blue' : 'gray'}`, margin: `${margin}` }} className="mx-1" onClick={handleBookmarkIconClick} />
}

export default BookmarkButton