import { useEffect } from "react"
//import { Alert } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { selectRoot, setGlobalAlert } from "../rootSlice"
import { message } from 'antd';
import 'antd/lib/message/style/index.css';
import 'antd/lib/icon/style/index.css';
//import { Redirect } from "react-router-dom";
import { globalAlertTypes } from "../lib/utility";

const GlobalAlert = () => {
    const { globalAlert } = useSelector(selectRoot)
    const dispatch = useDispatch()

    // const handleGlobalAlertClose = () => {
    //     dispatch(setGlobalAlert(null))
    // }
    // useEffect(() => {
    //     if (!globalAlert) return
    //     setTimeout(() => {
    //         dispatch(setGlobalAlert(null))
    //     }, 4000);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [globalAlert])

    // if (globalAlert) return <Alert variant={globalAlert.type ?? "primary"} onClose={handleGlobalAlertClose} dismissible>{globalAlert.content}</Alert>
    // return null

    useEffect(() => {
        if (globalAlert?.content) {
            globalAlertTypes.includes(globalAlert.type) // check if type is valid
                ? message[globalAlert.type]({
                    content:
                        <>
                            {
                                globalAlert.content === "Failed to fetch"
                                    ? "API is not working"
                                    : <>
                                        <span>{`${globalAlert.content}`}</span>{' '}
                                        <span>{globalAlert?.details?.error}</span>
                                    </>
                            }
                        </>, duration: 3
                })
                : message.info({ content: `${globalAlert.content}`, duration: 2 });
            dispatch(setGlobalAlert(null))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalAlert])

    return <style>
        {
            `
                .ant-message{
                    z-index:1062
                }
            `
        }
    </style>
}

export default GlobalAlert