import { Skeleton } from 'antd';
import { Card, Col, Row } from 'react-bootstrap';
import 'antd/lib/skeleton/style/index.css'

const CardsSkeleton = () => {
    return (
        <Row className="justify-content-center w-100">
            {[...Array(9).keys()]?.map((index) => {
                return (
                    <Col
                        key={index}
                        lg={4}
                        sm={6}
                        xs={12}
                        className="mb-5 d-flex justify-content-center"
                    >
                        <CardSkeleton />
                    </Col>
                );
            })}
        </Row>
    )
}

const CardSkeleton = () => {
    return (
        <>
            <style>
                {
                    `
            .card,.card-header,.card-header:first-child{
              border:none;
              border-radius:0;
            }
          `
                }
            </style>
            <Card bg="light" style={{ width: "100%" }}>
                <Card.Header
                    className="p-0"
                >
                    <Skeleton.Input active={true}/>
                </Card.Header>
                <Card.Body>
                    <Row className="d-flex justify-content-center my-2">
                        <Skeleton.Image active={true}/>
                    </Row>
                    <Row>
                        <Skeleton active={true}/>
                    </Row>
                    <Row className="mt-2">
                        <Skeleton.Button active={true}/>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default CardsSkeleton