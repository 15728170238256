// import { DropdownButton, Dropdown } from "react-bootstrap";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRoot, setCalendarSessionsToDownload, setGlobalAlert } from "../../rootSlice";
import { exportICS } from "../../lib/utility";
import { Button, Dropdown, Menu } from "antd";
import 'antd/lib/dropdown/style/index.css';
import 'antd/lib/menu/style/index.css';

const Download = () => {
  const dispatch = useDispatch()
  const { conferenceGroupInfo, sessionsInfo, calendarSessionsSelected } = useSelector(selectRoot)

  const downloadCalendar = async (downloadAll = true, saveFileName = 'calendar') => {
    if (!downloadAll && calendarSessionsSelected.length === 0) {
      dispatch(setGlobalAlert({ type: 'error', content: 'select at least 1 session to export to choose export all' }))
      return;
    }
    let sessionsInfoFiltered = downloadAll ? sessionsInfo : sessionsInfo.filter(p => calendarSessionsSelected.includes(p.id))
    exportICS({
      eventsToExport: sessionsInfoFiltered.map(session => {
        return { start: session.content.start, end: session.content.end, title: session.content.title, description: session.content.description }
      }),
      saveFileName: saveFileName
    })
    if (!downloadAll) dispatch(setCalendarSessionsToDownload({ type: 'removeAll' }))
  };

  useEffect(() => {
    dayjs.extend(duration);
  }, []);
  return (
    // <DropdownButton menuAlign="left" title={calendarSessionsSelected.length!==0?`${calendarSessionsSelected.length} session selected`:"Download Calendar"}>
    //   <Dropdown.Item disabled={calendarSessionsSelected.length===0} onClick={() => downloadCalendar(false, conferenceGroupInfo.id)} as="div">{`Download Selected`}</Dropdown.Item>
    //   <Dropdown.Item onClick={() => downloadCalendar(true, conferenceGroupInfo.id)} as="div">Download All</Dropdown.Item>
    // </DropdownButton>
    <Dropdown trigger="click" overlay={
      <Menu>
        <Menu.Item key="selected" disabled={calendarSessionsSelected.length===0} onClick={() => downloadCalendar(false, conferenceGroupInfo.id)}>Download Selected</Menu.Item>
        <Menu.Item key="all" onClick={() => downloadCalendar(true, conferenceGroupInfo.id)}>Download All</Menu.Item>
      </Menu>
    }>
      <Button type="primary">{calendarSessionsSelected.length!==0?`${calendarSessionsSelected.length} session selected`:"Download Calendar"}</Button>
    </Dropdown>
  );
};

export default Download;
