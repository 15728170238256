import { useEffect, useState } from "react";
import {
  // Button,
  Container,
  // Dropdown,
  // DropdownButton,
  // Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DynamicComponent from "./components/DynamicComponent";
import DynamicComponentEdit from "./components/DynamicComponentEdit";
import api from "./lib/api-client";
import useQuery from "./lib/hooks/useQuery";
import { selectRoot } from "./rootSlice";

import { useDrop } from "react-dnd";
import DynamicComponentOrder from "./components/DynamicComponentOrder";
import Error from "./Error";
import produce from 'immer'
import { getErrorMessage } from "./lib/utility";
import { Button, Dropdown, Form, Input, Menu } from "antd";

const Group = () => {
  const [groupObject, setGroupObject] = useState(null);
  const [layout, setLayout] = useState([]);
  const [layoutToEdit, setLayoutToEdit] = useState([]);
  const [menuText, setMenuText] = useState('');
  const [order, setOrder] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [, drop] = useDrop({ accept: "dynamicComponentEdit" });
  const [isLayoutStale, setIsLayoutStale] = useState(false)

  const {
    conferenceGroupId,
    conferenceGroupInfo,
    userInfo,
  } = useSelector(selectRoot);
  let { groupId } = useParams();
  let query = useQuery();
  const history = useHistory();
  const editable = conferenceGroupInfo?.details?.writable;
  const availableComponents = ["Markdown", "Jumbotron Image", "Sponsors", "HappeningNow", "Another Site", "Redirection", "Collapse"];


  const handleAddComponentClick = (componentName) => {
    const nextUniqueId = layoutToEdit?.map((p) => p.uniqueId).reduce((a, b) => Math.max(a, b), 0) + 1;
    switch (componentName) {
      case "Markdown":
        setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
          draftLayout.push({
            name: "Markdown",
            content: "# edit markdown here",
            option: {
              center: false
            },
            uniqueId: nextUniqueId
          })
        }))
        break;
      case "Jumbotron Image":
        setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
          draftLayout.push({
            name: "JumbotronImage",
            content: "# some text here",
            option: {
              imageUrl: "default",
              color: "white"
            },
            uniqueId: nextUniqueId
          })
        }))
        break;
      case "Sponsors":
        setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
          draftLayout.push({
            name: "Sponsors",
            uniqueId: nextUniqueId
          })
        }))
        break;
      case "Another Site":
        setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
          draftLayout.push({
            name: "IFrame",
            option: {
              title: "",
              src: "",
              width: "50%",
              height: "500px",
            },
            uniqueId: nextUniqueId
          })
        }))
        break;
      case "Redirection":
        setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
          draftLayout.push({
            name: "Redirection",
            option: {
              url: "",
            },
            uniqueId: nextUniqueId
          })
        }))
        break;
      case "Collapse":
        setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
          draftLayout.push({
            name: "CollapseComponent",
            option: {
              accordion:true,
              items:[],
            },
            uniqueId: nextUniqueId
          })
        }))
        break;
      default:
        setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
          draftLayout.push({
            name: componentName,
            uniqueId: nextUniqueId
          })
        }))
        break;
    }
  };

  const deleteComponent = (index) => {
    setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
      draftLayout.splice(index, 1)
    }))
  };

  // update content or option
  const updateComponent = (index, newValue, type) => {
    setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
      draftLayout[index][type] = newValue
    }))
  }

  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      await api.post(
        "/groups",
        {
          ...groupObject,
          web: JSON.stringify(produce({}, draft => {
            draft.layout = layoutToEdit;
            draft.menuText = menuText;
            draft.order = order;
            draft.visible = visible;
          })),
        },
        { accessToken: userInfo.token }
      );
      setIsLayoutStale(true)
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const moveComponent = (uniqueId, toIndex) => {
    const index = findComponent(uniqueId);
    setLayoutToEdit(layoutToEdit => produce(layoutToEdit, draftLayout => {
      draftLayout.splice(index, 1)
      draftLayout.splice(toIndex, 0, layoutToEdit[index]);
    }))
  };

  const findComponent = (uniqueId) => {
    return layoutToEdit.findIndex((p) => p.uniqueId === uniqueId)
  };

  useEffect(() => {
    if (!groupId && !isLayoutStale) return;
    let isUnmounted = false;
    const getGroupContent = async (groupId) => {
      setIsLoading(true)
      setError(null)
      try {
        const result = await api.get(
          "/groups",
          { id: groupId },
          { accessToken: userInfo.token }
        );
        if (!isUnmounted) {
          setGroupObject(result.groups[0])
          const webObject = JSON.parse(result.groups[0]?.web);
          if (webObject.footer) history.replace(`/${groupId}`); // has footer means the groups is a conference home
          setMenuText(webObject.menuText ?? groupId.replace(`${conferenceGroupId}/`, ""))
          setOrder(webObject.order ?? 0);
          setVisible(webObject.visible ?? false)
          setLayout(webObject.layout.map((p, index) => ({ ...p, uniqueId: index })))
          setLayoutToEdit(webObject.layout.map((p, index) => ({ ...p, uniqueId: index })))
        }
      } catch (error) {
        if (error.details?.type === 'forbidden') {
          setError("You are not authorized to view this page.")
          return
        }
        setError(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    };
    getGroupContent(groupId);
    return () => (isUnmounted = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, isLayoutStale]);

  if (isLoading)
    return (
      <Container style={{ marginTop: "30vh" }}>
        <Row className="justify-content-center">
          <Spinner animation="border" />
        </Row>
      </Container>
    );

  if (error) return <Error message={error} />;

  if (editable && query.get("mode") === "edit")
    return (
      <div className="mx-5" ref={drop}>
        <Row className="ml-5">
          <Form layout="inline">
            {/* <Form.Label>Text to appear in menubar</Form.Label>
            <Form.Control
              value={menuText}
              onChange={(e) => setMenuText(e.target.value)}
            /> */}
            <Form.Item label="Text to appear in menubar">
              <Input value={menuText} onChange={(e) => setMenuText(e.target.value)} />
            </Form.Item>
            <Form.Item label="Display Order">
              <Input value={order} onChange={(e) => setOrder(e.target.value)} />
            </Form.Item>
          </Form>
        </Row>
        <div className="d-flex">
          <div className="w-100">
            {layoutToEdit?.map((p, index) => {
              return (
                <DynamicComponentEdit
                  name={p.name}
                  option={p.option ?? { conferenceGroupId: conferenceGroupId }}
                  content={p.content}
                  key={`${p.name}${index}`}
                  index={index}
                  deleteComponent={deleteComponent}
                  updateComponent={updateComponent}
                  stack={query.get("stack")}
                />
              );
            })}
          </div>
          <div className="d-flex flex-column position-fixed" style={{ marginLeft: "90%", zIndex: 1 }}>
            {layoutToEdit?.length > 1 &&
              layoutToEdit?.map((p, index) => {
                return (
                  <DynamicComponentOrder
                    moveComponent={moveComponent}
                    findComponent={findComponent}
                    uniqueId={p.uniqueId}
                    index={index}
                    key={`${p.name}${index}`}
                  />
                );
              })}
          </div>
        </div>
        <Row>
          {/* <DropdownButton title="Add a component" variant="secondary">
            {availableComponents.map((p) => {
              return (
                <Dropdown.Item
                  key={p}
                  onClick={() => handleAddComponentClick(p)}
                >
                  {p}
                </Dropdown.Item>
              );
            })}
          </DropdownButton> */}
          <Dropdown trigger="click" overlay={
            <Menu>
              {availableComponents.map((p) => {
                return (
                  <Menu.Item
                    key={p}
                    onClick={() => handleAddComponentClick(p)}
                  >
                    {p}
                  </Menu.Item>
                );
              })}
            </Menu>
          }>
            <Button type="primary">Add a component</Button>
          </Dropdown>
          <Button className="ml-5" style={{ backgroundColor: "green", color: "white" }} onClick={handleSaveClick}>
            Save Changes
          </Button>
        </Row>
      </div>
    );

  return (
    <div className="container mt-5">
      {layout?.map((p, index) => {
        return (
          <DynamicComponent
            name={p.name}
            content={p.content}
            option={p.option ?? { conferenceGroupId: conferenceGroupId }}
            key={`${p.name}${index}`}
          />
        );
      })}
    </div>
  );
};

export default Group;
