import JumbotronImage, { JumbotronImageOptionEdit } from "./home/JumbotronImage";
import Sponsors from "./home/Sponsors";
import Organizers from "./home/Organizers";
import Markdown, { MarkdownOptionEdit } from "./Markdown";
import HappeningNow from "./home/HappeningNow";
import Footer, { FooterOptionEdit } from "./Footer";
import React from "react";
import { Col, Row } from "react-bootstrap";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/webpack-resolver";
import Beautify from 'ace-builds/src-noconflict/ext-beautify';
import WelcomeWall, { WelcomeWallOptionEdit } from "./home/WelcomeWall";
import IFrame, { IFrameOptionEdit } from "./home/IFrame";
import Redirection, { RedirectionOptionEdit } from "./home/Redirection";
import CollapseComponent, { CollapseComponentOptionEdit } from "./home/CollapseComponent";
import { useDispatch } from "react-redux";
import { setGlobalAlert } from "../rootSlice";
import { DeleteFilled } from "@ant-design/icons";
// import DOMPurify from 'dompurify'

const Components = {
  JumbotronImage: { component: JumbotronImage, showContent: true, showDelete: true },
  Sponsors: { component: Sponsors, showDelete: true },
  Organizers: { component: Organizers, showDelete: true },
  Markdown: { component: Markdown, showContent: true, showDelete: true },
  HappeningNow: { component: HappeningNow, showDelete: true },
  Footer: { component: Footer },
  WelcomeWall: { component: WelcomeWall, showDelete: true },
  IFrame: { component: IFrame, showDelete: true },
  Redirection: { component: Redirection, showDelete: true },
  CollapseComponent: { component: CollapseComponent, showDelete: true },
};

const DynamicComponentEdit = ({
  name,
  option,
  content,
  index,
  deleteComponent,
  updateComponent,
  stack = false
}) => {
  const dispatch = useDispatch();
  const handleContentTextAreaChange = (e) => {
    updateComponent(index, e, "content")
  };
  const handleOptionTextAreaChange = (e) => {
    let parsedOption = {
      center: false
    };
    try {
      parsedOption = JSON.parse(e);
      updateComponent(index, parsedOption, "option");
    } catch (error) {
      dispatch(setGlobalAlert({ type: "error", content: error.message }))
    }
  };

  const handleDeleteButtonClick = () => {
    deleteComponent(index);
  };

  const handleOptionObjChange = (updatedOptionObj) => {
    updateComponent(index, updatedOptionObj, "option");
  }

  const renderOption = (name, content, option) => {
    switch (name) {
      case "JumbotronImage":
        return <JumbotronImageOptionEdit content={content} option={option} onOptionChange={handleOptionObjChange} />
      case "Markdown":
        return <MarkdownOptionEdit content={content} option={option} onOptionChange={handleOptionObjChange} />
      case "Sponsors":
        return null
      case "HappeningNow":
        return null
      case "WelcomeWall":
        return <WelcomeWallOptionEdit content={content} option={option} onOptionChange={handleOptionObjChange} />
      case "IFrame":
        return <IFrameOptionEdit content={content} option={option} onOptionChange={handleOptionObjChange} />
      case "Redirection":
        return <RedirectionOptionEdit content={content} option={option} onOptionChange={handleOptionObjChange} />
      case "CollapseComponent":
        return <CollapseComponentOptionEdit content={content} option={option} onOptionChange={handleOptionObjChange} />
      case "Footer":
        return <FooterOptionEdit content={content} option={option} onOptionChange={handleOptionObjChange} />
      default:
        return (
          <div className="mt-2">
            Option:
            <AceEditor
              mode="json"
              theme="github"
              onChange={(e) => handleOptionTextAreaChange(e)}
              value={JSON.stringify(option, null, 4)}
              editorProps={{ $blockScrolling: true }}
              setOptions={{}}
              width="100%"
              wrapEnabled={true}
              maxLines={Infinity}
              minLines={1}
              showPrintMargin={false}
              commands={Beautify.commands}
            />
          </div>
        )
    }
  }

  if (Components[name])
    return (
      <div className="mb-5 w-100">
        <div className="d-flex">
          <div className="h5 mr-2">{name}</div>
          {Components[name].showDelete && <DeleteFilled className="mb-1" style={{ alignSelf: "center", color: "#f5222d" }} onClick={handleDeleteButtonClick} />}
        </div>
        <Row className={`d-flex ${stack ? "flex-column" : "flex-row"}`}>
          <div className="d-flex flex-column w-50">
            {Components[name].showContent && <div className="mt-2">
              Content:
              <AceEditor
                mode="markdown"
                theme="github"
                onChange={(e) => handleContentTextAreaChange(e)}
                value={content}
                editorProps={{ $blockScrolling: true }}
                setOptions={{}}
                width="100%"
                wrapEnabled={true}
                maxLines={Infinity}
                minLines={20}
                showPrintMargin={false}
                commands={Beautify.commands}
                //onBlur={()=>{updateComponent(index,DOMPurify.sanitize(content),'content');console.log(123)}}
                onBlur={() => { updateComponent(index, content, 'content') }}
              />
            </div>}
            {renderOption(name, content, option)}
          </div>
          <Col className="mt-2">
            {React.createElement(
              Components[name].component,
              { key: name, option: option, content: content, redirect: false },
              {}
            )}
          </Col>
        </Row>
      </div>
    );
};

export default DynamicComponentEdit;
