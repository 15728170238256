import { useDispatch, useSelector } from "react-redux";
import { loadStore, selectRoot, setCalendarDayViewInfo, setCalendarSessionsToDownload } from "./rootSlice";
import dayjs from "dayjs";
import { Table, ListGroup } from "react-bootstrap";
import ListDay from "./components/calendar/ListDay";
import { useEffect } from "react";
import Download from "./components/calendar/Download";
import { Link, useHistory, useParams } from "react-router-dom";
import CalendarOneDayView2 from "./components/calendar/CalendarOneDayView2";
import useBreakpoint from "./lib/hooks/useBreakpoint";
import { Empty, Card, Popover, Radio, Button } from "antd";
import 'antd/lib/empty/style/index.css';
import 'antd/lib/popover/style/index.css';
import { EditFilled } from "@ant-design/icons";
import { calendarColor, isDateOverlapping } from "./lib/utility";
import React, { Suspense } from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import Duration from "./components/calendar/Duration";
import BookmarkButton from "./components/BookmarkButton";
import { useState } from "react";
import EditSessions from "./components/calendar/EditSessions";

const PaperTag = React.lazy(() => import("./components/session/PaperTag"));

const CalendarItem = ({ session }) => {
  let history = useHistory();
  const dispatch = useDispatch()
  const { calendarSessionsSelected } = useSelector(selectRoot)

  const calendarItemClick = () => {
    history.push(`/session/${session?.id}`);
  };

  const handleAddSessionToDownloadClick = () => {
    calendarSessionsSelected.includes(session?.id)
      ? dispatch(setCalendarSessionsToDownload({ type: "remove", id: session?.id }))
      : dispatch(setCalendarSessionsToDownload({ type: "add", id: session?.id }))
  }

  const PopoverContent = (
    <>
      <p>{session?.start?.formattedDate}</p>
      <p>
        {session?.start?.formattedHour} to {session?.end.formattedHour}{" "}<span style={{ color: 'red' }}>{`${session?.start.formattedDate === session?.end.formattedDate ? '' : session?.end.formattedDate}`}</span>{" "}
        <Duration
          durationHours={session?.duration?.hours}
          durationMinutes={session?.duration?.minutes}
        />
      </p>
      <hr />
      <p>{session?.description}</p>
      <Suspense fallback={<div>Loading...</div>}>
        <PaperTag noteId={session.id} shouldFilterByTag={{ filter: false, prompt: true }} />
      </Suspense>
    </>
  )

  const PopoverTitle = (
    <>
      <style>
        {
          `
        .ant-popover-title{
          background-color:${calendarColor[session?.type ?? 'poster']}
        }
        `
        }
      </style>
      <Link to={`/session/${session?.id}`} style={{ color: "black" }}> {session?.title}</Link>
      <CalendarOutlined style={{ marginLeft: "0.5rem", verticalAlign: "text-bottom", cursor: "pointer", color: `${calendarSessionsSelected.includes(session?.id) ? "green" : "black"}` }} onClick={handleAddSessionToDownloadClick} />
    </>
  )

  return (
    <div>
      <style>
        {
          `
            .item{
              padding: 0;
              font-size: medium;
              padding-bottom: 0.5rem;
              border-radius: 0;
              cursor:pointer;
              min-height:30px;
            }
            .item:hover {
              background-color:lightgray;
            }
            .dot{
              margin: 0 4px;
              box-sizing: content-box;
              width: 0;
              height: 0;
              border: 4px solid;
              border-radius: 4px;
              border-radius: calc(var(--fc-daygrid-event-dot-width, 8px)/2);
            }
          `
        }
      </style>
      <div
        variant="secondary"
        className={`d-flex justify-content-between item`}
        style={{
          borderTopWidth: `${calendarSessionsSelected.includes(session?.id) ? "thick" : "thin"}`,
          borderTopColor: `${calendarSessionsSelected.includes(session?.id) ? "green" : "lightgray"}`,
          // borderTopWidth:"thin",
          borderTopStyle: "solid",
          // borderTopColor:"lightgray"
        }}
      >
        <Popover
          content={PopoverContent}
          title={PopoverTitle}
          trigger="click"
          placement="bottom"
        >
          <div className="w-100 d-flex justify-content-between ml-1 align-items-center" onDoubleClick={calendarItemClick}>
            <div className="d-flex justify-content-start align-items-center">
              <div className="dot" style={{color:`var(--presentation-${session?.type ?? 'poster'})`}}/>
              <span>{session?.title}</span>
            </div>
            <span className="mr-1">{session?.start?.formattedHour}</span>
          </div>
        </Popover>
        <div className="d-flex flex-shrink-1">
          <BookmarkButton item={session} size="larger" />
        </div>

      </div>
    </div>
  )
};

const CalendarDay = ({ date, sessions, setCalendarView, dayViewOffset }) => {
  const dispatch = useDispatch();
  const { sessionsInfoFormatted } = useSelector(selectRoot);
  const sessionsOfDateToShow = sessionsInfoFormatted?.filter((p) =>
      isDateOverlapping({
        date1Start: dayjs(p.start.formattedFull),
        date1End: dayjs(p.end.formattedFull),
        date2Start: dayjs(date).hour(0).minute(0).second(0),
        date2End: dayjs(date).hour(24).minute(0).second(0)
      })
    );
  const handleCalendarDayClicked = () => {
    dispatch(setCalendarDayViewInfo({ date: date.format(), sessions: sessionsOfDateToShow }));
    setCalendarView(dayViewOffset)
  };
  return (
    <>
      <style>
        {`
        .highlight{
          color: white;
          background-color:blue
        }
        .no-border{
          border:none;
        }
        .no-padding{
          padding: 0!important;
        }
        .calendar__title{
          cursor:pointer
        }
      `}
      </style>
      <Card bordered={false} style={{backgroundColor:"transparent"}} bodyStyle={{padding:"0"}}>
        <div className="d-flex justify-content-center"><Button type="link" size="large" onClick={handleCalendarDayClicked} style={{ textAlign: "center" }}>{`${date.format('dddd MMM D')}`}</Button></div>
        {sessions?.filter(p => dayjs(p.start.formattedFull).isSame(date, "day")).map((session) => {
          return <CalendarItem key={session.id} session={session} />;
        })}
      </Card>
    </>
  );
};

const CalendarICLR = ({ sessionsToDisplay = [] }) => {
  let { groupId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    sessionsInfoFormatted,
    calendarInfo,
    // calendarDayViewInfo,
    conferenceGroupInfo
  } = useSelector(selectRoot);
  const issm = useBreakpoint("sm")

  const [calendarView, setCalendarView] = useState('week')
  const [showEditSessionsModal, setShowEditSessionsModal] = useState(false)

  const isPartialCalendar = sessionsToDisplay.length
  const canEdit = conferenceGroupInfo?.details?.writable;

  const renderCalendar = () => {
    if(issm){
      return (
        <div className="mx-3">
          <div className="mb-2 d-flex justify-content-center">
            {canEdit && <div className="d-flex flex-shrink-1">
                <EditFilled className="mb-1" style={{ alignSelf: "center", color: "#1890ff",verticalAlign:"bottom" }} onClick={() => setShowEditSessionsModal(true)} />
                <EditSessions show={showEditSessionsModal} setShow={setShowEditSessionsModal} />
              </div>}
            <Radio.Group value={calendarView} buttonStyle="solid" onChange={e => setCalendarView(e.target.value)} size="large">
              <Radio.Button value="week">Week</Radio.Button>
              {[...Array(calendarInfo.totalDays).keys()].map((day,index)=>{
                const date = dayjs(calendarInfo.firstActualDay).add(index, "day");
                return <Radio.Button key={index} value={index}>{date.format('ddd')}</Radio.Button>
              })}
              <Radio.Button value="list">List</Radio.Button>
            </Radio.Group>
          </div>
          {renderCalendarView()}
        </div>
      )
    }
    else{
      return (
        <ListGroup>
          {[...Array(calendarInfo?.totalDays ?? 0).keys()]?.map((day, index) => {
            const date = dayjs(calendarInfo.firstActualDay).add(index, "day");
            const sessionsOfDate = isPartialCalendar ? sessionsToDisplay?.filter((p) =>
              dayjs(p.start.formattedFull).isSame(date, "day")
            ) : sessionsInfoFormatted?.filter((p) =>
              dayjs(p.start.formattedFull).isSame(date, "day")
            );
            return (
              <ListGroup.Item key={date}>
                <ListDay date={date} sessions={sessionsOfDate}></ListDay>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      )
    }
  }

  const renderCalendarView = () => {
    switch (calendarView) {
      case "week":
        return (
          <>
            <Table bordered>
              <tbody>
                {
                  <tr>
                    {[...Array(calendarInfo.totalDays).keys()].map((day, index) => {
                      const date = dayjs(calendarInfo.firstActualDay).add(index, "day");
                      const sessionsOfDay = sessionsInfoFormatted?.filter((p) =>
                        isDateOverlapping({
                          date1Start: dayjs(p.start.formattedFull),
                          date1End: dayjs(p.end.formattedFull),
                          date2Start: dayjs(date).hour(0).minute(0).second(0),
                          date2End: dayjs(date).hour(24).minute(0).second(0)
                        })
                      );

                      return (
                        <td key={index} className="p-0" style={{ backgroundColor: `${dayjs().isSame(date, "day") ? 'rgba(255, 255, 40, 0.1)' : undefined}` }}>
                          <CalendarDay date={date} sessions={sessionsOfDay} hightlight={dayjs().isSame(date, "day")} setCalendarView={setCalendarView} dayViewOffset={index} />
                        </td>
                      );
                    })}
                  </tr>
                }
              </tbody>
            </Table>
            <div className="float-right mr-5 mt-2">
              <Download />
            </div>
          </>
        )        
      case "list":
        return (
          <div className="d-flex justify-content-center">
            <ListGroup className="w-100">
              {[...Array(calendarInfo?.totalDays ?? 0).keys()]?.map((day, index) => {
                const date = dayjs(calendarInfo.firstActualDay).add(index, "day");
                const sessionsOfDate = isPartialCalendar ? sessionsToDisplay?.filter((p) =>
                  dayjs(p.start.formattedFull).isSame(date, "day")
                ) : sessionsInfoFormatted?.filter((p) =>
                  dayjs(p.start.formattedFull).isSame(date, "day")
                );
                return (
                  <ListGroup.Item key={date}>
                    <ListDay date={date} sessions={sessionsOfDate}></ListDay>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>
        )
      default:
        return (
          <div className="d-none d-sm-block my-5" style={{ position: "relative" }}>
            <CalendarOneDayView2 />
          </div>
        )
    }
  }

  useEffect(() => {
    if (!groupId) return;
    if (calendarInfo && sessionsInfoFormatted.length && conferenceGroupInfo?.id === groupId) return;
    dispatch(loadStore({ id: groupId, history: history }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    if (calendarView === "week" || calendarView==="list") { dispatch(setCalendarDayViewInfo({ sessions: [] })); return; };
    // first day + calenderview
    const dateToShow=dayjs(calendarInfo.firstActualDay).add(calendarView,"day").format();
    const sessionsOfDateToShow = sessionsInfoFormatted?.filter((p) =>
      isDateOverlapping({
        date1Start: dayjs(p.start.formattedFull),
        date1End: dayjs(p.end.formattedFull),
        date2Start: dayjs(dateToShow).hour(0).minute(0).second(0),
        date2End: dayjs(dateToShow).hour(24).minute(0).second(0)
      })
    );
    dispatch(setCalendarDayViewInfo({ date: dateToShow, sessions: sessionsOfDateToShow }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarView])


  if (calendarInfo?.totalDays === 0) return (
    <div className="my-5 d-flex flex-column align-items-center">
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span style={{ color: "black" }}>conference does not have any session right now.</span>}
      />
      {
        canEdit &&
        <>
          <Button size="large" type="primary" style={{ width: "fit-content" }} onClick={() => setShowEditSessionsModal(true)}>Add a session</Button>
          <EditSessions show={showEditSessionsModal} setShow={setShowEditSessionsModal} />
        </>
      }
    </div>
  )

  return (
    <div className="my-5 page-calendar">
      {
        renderCalendar()
      }
    </div>
  );
};

export default CalendarICLR;
