import { Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import BookmarkButton from "../BookmarkButton";
import Duration from "../calendar/Duration";
import CardsSkeleton from "./CardsSkeleton";
//import PaperTag from "./PaperTag";
import React, { Suspense } from 'react';
// import { calendarColor } from "../../lib/utility";

const PaperTag = React.lazy(() => import("./PaperTag"));

export const SessionCard = ({ session }) => {
  let history = useHistory();
  const handleSessionTitleClick = () => {
    history.push(`/session/${session?.id}`);
  }
  return (
    <>
      <style>
        {
          `
            .title{
              font-size: large;
              line-height: 1.25rem;
              cursor:pointer;
            }
            .author-name{
                font-size:small;
                margin:0
            }
            .session-info{
              font-size: 0.75rem;
              line-height: 1.25rem;
              color:#777;
            }
            .card,.card-header,.card-header:first-child{
              border:none;
              border-radius:0;
            }
          `
        }
      </style>
      <Card bg="light" style={{ width: "100%" }}>
        <Card.Header
          className="p-0"
          style={{ backgroundColor: `var(--presentation-${session?.type ?? 'poster'}` }}
        >
          <div className="d-flex justify-content-between">
            <span className="p-2 title w-80" onClick={handleSessionTitleClick}>{session.title}</span>
            <BookmarkButton item={session} size="x-large" />
          </div>
        </Card.Header>
        <Card.Body className="d-flex flex-column justify-content-between">
          <Row>
            {session.description}
          </Row>
          <Row>
            {session.start?.formattedDate}
          </Row>
          <Row>
            {session?.start?.formattedHour} to {session?.end.formattedHour}
            <Duration
              durationHours={session?.duration?.hours}
              durationMinutes={session?.duration?.minutes}
            />
          </Row>
          <Row className="mt-2">
            {/* <PaperTag noteId={session?.id} /> */}
            <Suspense fallback={<div>Loading...</div>}>
              <PaperTag noteId={session?.id} />
            </Suspense>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

const SessionCards = ({ sessions, isLoading, empty }) => {

  if (isLoading)
    return <CardsSkeleton />
  if (sessions.length === 0) return (
    <Row className="justify-content-center w-100 mt-2">
      {empty}
    </Row>
  )

  return (
    <Row className="justify-content-left mt-2">
      {sessions?.map((session) => {
        return (
          <Col
            key={session.id}
            lg={4}
            sm={6}
            xs={12}
            className="mb-5 d-flex justify-content-center"
          >
            <SessionCard session={session} key={session.id} />
          </Col>
        );
      })}
    </Row>
  );
};

export default SessionCards;