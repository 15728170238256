import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { selectRoot } from '../rootSlice';

const AvatarImage = () => {
    const { userInfo } = useSelector(selectRoot)
    return (
        <Avatar src={`${process.env.REACT_APP_API_URL}/attachment/avatar?id=${userInfo.user?.profile?.avatar}`} >
            {`${userInfo.user?.profile?.first?.[0] ?? ''} ${userInfo.user?.profile?.last?.[0] ?? ''}`.trim()}
        </Avatar>
    )
}

export default AvatarImage