import { Input, Radio, Form } from "antd";
import 'antd/lib/form/style/index.css';
import { useState } from "react";
import { Container, Jumbotron } from "react-bootstrap";
import Markdown from "../Markdown";

const JumbotronImage = ({ content, option }) => {
  return (
    <>
      <style>
        {
          `
          .jumbotron-image{
            width:100%;
            background-image:url(${(option?.imageUrl && option?.imageUrl !== "default") ? option.imageUrl : `${process.env.PUBLIC_URL}/jumbotron.jpg`});
            background-size:cover;
            background-repeat:no-repeat;
          }
          .jumbotron-text{
            // font-weight:bold;
            color:${option?.color ?? 'white'}!important;
          }
          h1 {
            --minFontSize: 0.5rem;
            --maxFontSize: 2.5rem;
            --scaler: 2rem;
            font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
          }
        `
        }
      </style>
      <Jumbotron fluid className="jumbotron-image">
        <Container className="text-center p-5 jumbotron-text">
          <Markdown content={content} option={option} />
        </Container>
      </Jumbotron>
    </>
  );
};

export const JumbotronImageOptionEdit = ({ option, onOptionChange }) => {

  // eslint-disable-next-line no-unused-vars
  const [optionState, _] = useState({ imageUrl: option?.imageUrl ?? "default", textColor: option?.color ?? "white" });

  const [form] = Form.useForm();

  const handleFieldsChange = (_, allFields) => {
    onOptionChange({ imageUrl: allFields[0].value, color: allFields[1].value })
  }

  return (
    <Form onFieldsChange={handleFieldsChange} form={form} layout="vertical">
      <Form.Item label="Background Image Url" name="imageUrl" initialValue={optionState.imageUrl}>
        <Input />
      </Form.Item>
      <Form.Item label="Text color" name="textColor" initialValue={optionState.textColor} >
        <Radio.Group buttonStyle="solid">
          <Radio.Button value="white">white</Radio.Button>
          <Radio.Button value="black">black</Radio.Button>
          <Radio.Button value="green">green</Radio.Button>
          <Radio.Button value="red">red</Radio.Button>
          <Radio.Button value="yellow">yellow</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </Form>
  )
}

export default JumbotronImage;

