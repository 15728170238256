import { Button } from "antd"
import { useHistory } from "react-router-dom"

const GroupIdMissingAction=()=>{
    const history=useHistory();
    const handleICLRButtonClick=()=>{
        history.replace('/ICLR.cc/2021/Conference/Virtual')
    }
    return (
        <Button size="large" type="primary" onClick={handleICLRButtonClick}>Try ICLR 2021</Button>
    )
}

export default GroupIdMissingAction