/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Duration from "./components/calendar/Duration";
import Panigation from "./components/Pagination";
// import PaperCard from './components/session/PaperCard'
import PaperCards from "./components/session/PaperCards";
// import PaperList from "./components/session/PaperList";
// import PaperListOR from "./components/session/PaperListOR";
import api from "./lib/api-client";
//import loadStore from "./lib/loadStore";
import { loadStore, selectRoot } from "./rootSlice";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { exportICS } from "./lib/utility";
import BookmarkButton from './components/BookmarkButton'
import Error from "./Error";
import PaperTag from "./components/session/PaperTag";
import { CalendarOutlined } from '@ant-design/icons';

const Session = () => {
  const {
    sessionsInfoFormatted,
  } = useSelector(selectRoot);
  const [sessionInView, setSessionInView] = useState(null);
  const [totalPages, setTotalPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sessionNotes, setSessionNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [goToPage, setGoToPage] = useState(null)
  const [error, setError] = useState(null)

  let { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleCalendarIconClick = () => {
    exportICS({
      eventsToExport: [{
        ...sessionInView,
        start: sessionInView.start.unixmm,
        end: sessionInView.end.unixmm
      }],
      saveFileName: sessionInView.title
    })
  }

  useEffect(() => {
    setSessionInView(
      sessionsInfoFormatted.filter((p) => p.id === sessionId)[0]
    );
  }, [sessionsInfoFormatted, sessionId]);

  useEffect(() => {
    if (!sessionId || !sessionInView) return;
    let isUnmounted = false;
    const getNotesForPage = async (pageNum) => {
      setIsLoading(true);
      setCurrentPage(pageNum);
      const result = await api.get("/notes", {
        "content.sessions": sessionId,
        limit: 100,
        offset: 100 * (pageNum - 1),
      });
      if (!isUnmounted) {
        sessionInView?.type === 'presentation' ? setSessionNotes(result.notes.sort((a, b) => a.content.start - b.content.start)) : setSessionNotes(result.notes);
        setTotalPages(Math.ceil(result.count / 100));
        setIsLoading(false);
      }
    };
    getNotesForPage(goToPage ?? 1);
    return () => (isUnmounted = true);
  }, [sessionId, goToPage, sessionInView]);

  useEffect(() => {
    if (!id) return;
    // let isUnmounted = false;
    setSessionId(id);
    const loadData = async (id) => {
      try {
        const result = await api.get("/notes", { id });
        const groupId = result.notes[0].invitation.split("/-")[0];
        if (sessionsInfoFormatted.length) return;
        dispatch(loadStore({ id: groupId, history: history }))
      } catch (error) {
        error.details?setError(`${error.details.path} ${error.details.value} ${error.details.type}`):setError(error.message)
      }
    };
    loadData(id);
    // return () => (isUnmounted = true);
  }, [id]);

  if(error) return <Error message={error}/>


  return (
    <Container>
      {sessionInView && (
        <Row>
          <Col className="mb-5 d-flex justify-content-center">
            <Container className="text-center">
              <h1>{sessionInView?.title}</h1>
              <h4>{sessionInView?.description}</h4>
              <div>{`${sessionInView?.start?.formattedHour} ${sessionInView?.start?.formattedDate}`}
                {/* <FontAwesomeIcon
                  icon={faCalendarCheck}
                  color="black"
                  className="mx-1"
                  style={{ cursor: "pointer" }}
                  onClick={handleCalendarIconClick}
                /> */}
                <CalendarOutlined style={{marginLeft:"0.5rem",verticalAlign:"middle",cursor:"pointer"}} onClick={handleCalendarIconClick}/>
                <BookmarkButton item={sessionInView} />
              </div>
              <div>
                <Duration
                  durationHours={sessionInView?.duration?.hours}
                  durationMinutes={sessionInView?.duration?.minutes}
                />
              </div>
              <PaperTag noteId={sessionInView.id}/>
            </Container>
          </Col>
        </Row>
      )}
      {/* <PaperListOR notes={sessionNotes} showSessionsInfo={false} isLoading={isLoading} /> */}
      <PaperCards notes={sessionNotes} showSessionsInfo={false} isLoading={isLoading}/>
      {sessionNotes.length !== 0 && totalPages !== 1 && !isLoading && (
        <Panigation
          totalPages={totalPages}
          currentPage={currentPage}
          goPage={(pageNum) => setGoToPage(pageNum)}
        />
      )}
    </Container>
  );
};

export default Session;
