
import { Result } from 'antd';
import 'antd/lib/result/style/index.css'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearStore } from './rootSlice';

const Error = ({ message, extraMessage, cleanUp = false, extra }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [messageToDisplay,setMessageToDisplay]=useState(message)
  const [extraMessageToDisplay,setExtraMessageToDisplay]=useState(extraMessage)
  
  useEffect(()=>{
    if (location.state?.message) setMessageToDisplay(location.state.message)
    if (location.state?.extraMessage) setExtraMessageToDisplay(location.state.extraMessage)
    if(cleanUp) dispatch(clearStore())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Result
      status="warning"
      title={messageToDisplay}
      subTitle={extraMessageToDisplay}
      extra={extra}
    ></Result>
  )
};

export default Error;
