import { Collapse, Container, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { selectRoot } from "../../rootSlice";
import { Link, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Empty } from 'antd';
import 'antd/lib/tag/style/index.css';
import 'antd/lib/spin/style/index.css';
// import { calendarColor, presentationTypeTagColor } from "../../lib/utility";
import { FilePdfOutlined } from '@ant-design/icons';

const PaperListORRow = ({ note, sessions }) => {
  const [openAbstract, setOpenAbstract] = useState(false);
  let history = useHistory();
  const handlePaperClick = () => {
    history.push(`/presentation/${note?.id}`);
  };

  return (
    <>
      <style>
        {
          `
            .title{
              font-weight:bold;
              cursor:pointer
              font-size: 0.75rem;
              line-height: 1.25rem;
              cursor:pointer;
            }
            .author-name{
                font-size:small;
                margin:0
            }
            .toggle-abstract{
                font-size:small;
                cursor:pointer
            }
            .abstract{
                font-size:small;
            }
            .presentation-type{
                font-style:italic;
                color:white;
            }
            .session-info{
              font-size: 0.8rem;
              line-height: 1.5rem;
              color:#777;
            }
            .presentation-time{
              color:black;
            }
          `
        }
      </style>
      <Row id='title' className="d-flex justify-content-start">
        <p className="title m-0" onClick={handlePaperClick} style={{ color: `var(--presentation-${note?.content?.presentation_type})` }}>
          {note?.content?.title}
        </p>
        {note?.content?.pdf && (
          <a
            href={`https://openreview.net${note?.content?.pdf}`}
            target="_blank"
            rel="noreferrer"
          >
            {/* <FontAwesomeIcon icon={faFilePdf} color="steelblue" /> */}
            <FilePdfOutlined style={{ verticalAlign: "text-top", color: "black", marginLeft: "0.5rem" }} />
          </a>
        )}
      </Row>
      {/* <Row id='tag'>
        {
          note?.content?.presentation_type && sessions.length !== 0 &&
          <Tag color={presentationTypeTagColor[note?.content?.presentation_type]}>{`${note?.content?.presentation_type}`}</Tag>
        }
      </Row> */}
      <Row id='author' className="mt-1">
        {note?.content?.authors?.map((author, index) => {
          if (!note?.content.authorids?.[index])
            return (
              <p className="author-name" key={author}>
                {author}
                {`${index !== note?.content?.authors?.length - 1 ? "," : ""}`}
              </p>
            );
          const profileLink = `https://openreview.net/profile?${note.content.authorids[index].startsWith("~") ? "id" : "email"
            }=${note.content.authorids[index]}`;
          return (
            <a href={profileLink} target="_blank" rel="noreferrer" style={{ color: "black" }} key={author}>
              <p className="author-name mr-1">
                {author}
                {`${index !== note?.content?.authors?.length - 1 ? "," : ""}`}
              </p>
            </a>
          );
        })}
      </Row>
      <Row id='session'>
        {sessions?.map((session) => {
          return (
            <Link to={`/session/${session.id}`} key={session.id}>
              <span className="mr-2 session-info">
                {`${session?.title} 
                    (${session?.start?.formattedDate} ${session?.start?.formattedHour})
                    `}
                {note.content.presentation_type === 'presentation' && sessions.length === 1 &&
                  <span className="presentation-time">{`${dayjs(Number(sessions[0].start.unixmm) + Number(note.content.start)).format("HH:mm")}-${dayjs(Number(sessions[0].start.unixmm) + Number(note.content.end)).format("HH:mm")}`}</span>
                }
              </span>
            </Link>
          );
        })}
      </Row>
{/* 
      {note.content.presentation_type === 'presentation' && (
        <Row>
          {`${dayjs.unix(note.content.start / 1000).format("HH:mm")}-${dayjs.unix(note.content.end / 1000).format("HH:mm")}`}
        </Row>
      )} */}
      <Row className="mb-2" id='abstract'>
        {note?.content?.abstract && (
          <>
            <span
              onClick={() => setOpenAbstract(!openAbstract)}
              className="toggle-abstract"
            >
              {`${openAbstract ? "Hide " : "Show "}`}details
            </span>
            <Collapse in={openAbstract}>
              <div style={{ borderLeft: "2px solid #ddd" }}>
                <p className="abstract ml-2">
                  <strong>Abstract</strong>:{note?.content?.abstract}
                </p>
              </div>
            </Collapse>
          </>
        )}
      </Row>
    </>
  );
};

const PaperListOR = ({ notes, showSessionsInfo = true, isLoading, empty }) => {
  const { sessionsInfoFormatted } = useSelector(selectRoot);

  if (isLoading)
    return (
      <Container style={{ marginTop: "30vh" }}>
        <Row className="justify-content-center">
          <Spinner animation="border" />
        </Row>
      </Container>
    );

  if (notes.length === 0)
    return (
      <Row className="justify-content-center">
        {empty ?? <Empty />}
      </Row>
    )

  return (
    <Container>
      {notes?.map((note) => {
        let sessionsAssociated = [];
        if (showSessionsInfo)
          sessionsAssociated = sessionsInfoFormatted?.filter((p) =>
            note?.content?.sessions?.includes(p.id)
          );
        return (
          <PaperListORRow
            note={note}
            key={note.id}
            sessions={sessionsAssociated}
          />
        );
      })}
    </Container>
  );
};

export default PaperListOR;
