import { Input, Form } from "antd";
import { useState } from "react";
import { Container, Row } from "react-bootstrap";

const IFrame = ({ option }) => {

    return (
        <Container className="text-center my-5">
            <Row className="iframe-title"><h3>{option?.title}</h3></Row>
            <iframe
                title={`${option?.title}`}
                src={option?.src}
                style={{ width: `${option?.width ?? "500px"}`, height: `${option?.height ?? "400px"}` }}>
            </iframe>
        </Container>
    )
}

export const IFrameOptionEdit = ({ option, onOptionChange }) => {

    // eslint-disable-next-line no-unused-vars
    const [optionState, _] = useState({
        title: option?.title ?? "",
        src: option?.src ?? "",
        width: option?.width ?? "",
        height: option?.height ?? ""
    });

    const [form] = Form.useForm();

    const handleFieldsChange = (_, allFields) => {
        onOptionChange({
            title: allFields[0].value,
            src: allFields[1].value,
            width: allFields[2].value,
            height: allFields[3].value,
        })
    }

    return (
        <Form onFieldsChange={handleFieldsChange} form={form} layout="vertical">
            <Form.Item label="Title" name="title" initialValue={optionState.title}>
                <Input />
            </Form.Item>
            <Form.Item label="Source" name="src" initialValue={optionState.src}>
                <Input />
            </Form.Item>
            <Form.Item label="Width" name="width" initialValue={optionState.width}>
                <Input />
            </Form.Item>
            <Form.Item label="Height" name="height" initialValue={optionState.height}>
                <Input />
            </Form.Item>
        </Form>
    )
}
export default IFrame