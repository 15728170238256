import { useEffect, useState } from "react";
import { Pagination, Row } from "react-bootstrap";

const PaginationComponent = ({goPage,totalPages,currentPage}) => {
  const [disableFirst, setDisableFirst] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [disableLast, setDisableLast] = useState(false);
  const [pagesToRender,setPagesToRender]=useState([])

  useEffect(()=>{
    const dynamicPages = Math.floor(window.innerWidth / 100);
    if(totalPages>dynamicPages){
        const startingPage= Math.min(Math.max(currentPage - Math.floor(dynamicPages/2), 1), Math.max(totalPages - (dynamicPages-1), 1))
        setPagesToRender([...Array(dynamicPages).keys()].map(p=>p+startingPage))
        return
    }
    setPagesToRender([...Array(totalPages).keys()].map(p=>p+1))
  },[totalPages, currentPage])

  useEffect(() => {
    if (currentPage === 1 && totalPages === 1) {
      setDisableFirst(true);
      setDisablePrev(true);
      setDisableNext(true);
      setDisableLast(true);
      return;
    }
    if (currentPage === 1) {
      setDisableFirst(true);
      setDisablePrev(true);
      setDisableNext(false);
      setDisableLast(false);
      return;
    }
    if (currentPage === totalPages) {
      setDisableFirst(false);
      setDisablePrev(false);
      setDisableNext(true);
      setDisableLast(true);
      return;
    }
    setDisableFirst(false);
    setDisablePrev(false);
    setDisableNext(false);
    setDisableLast(false);
  }, [totalPages, currentPage]);
  return (
    <Row className="justify-content-center">
      {/* <style type="text/css"> to customize style
        {`
          .pagination>li.active>span {
            background-color: red !important;
          }
        `}
      </style> */}
      <Pagination className="text-dark">
        <Pagination.First disabled={disableFirst} onClick={()=>{goPage(1)}}/>
        <Pagination.Prev disabled={disablePrev} onClick={()=>{goPage(currentPage-1)}}/>
        {pagesToRender[0]!==1 && <Pagination.Ellipsis disabled/>}
        {
            pagesToRender.map((p)=>{
                return <Pagination.Item key={p} active={p===currentPage} onClick={()=>{goPage(p)}}>{p}</Pagination.Item>
            })
        }
        {pagesToRender[pagesToRender.length-1]!==totalPages && <Pagination.Ellipsis disabled/>}
        <Pagination.Next disabled={disableNext} onClick={()=>{goPage(currentPage+1)}}/>
        <Pagination.Last disabled={disableLast} onClick={()=>{goPage(totalPages)}}/>
      </Pagination>
    </Row>
  );
};

export default PaginationComponent;
