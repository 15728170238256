import { useSelector } from "react-redux";
import { selectRoot } from '../../rootSlice'
import dayjs from "dayjs";
import { useEffect } from "react";
import { useState } from "react";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
// import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
// import { CloseOutlined } from '@ant-design/icons';
import { Empty } from "antd";

const CalendarOneDayView2 = () => {
  const { calendarDayViewInfo } = useSelector(selectRoot);
  const { date, sessions } = calendarDayViewInfo;
  const [sessionsWithPositonInfo, setSessionsWithPositionInfo] = useState([]);

  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isToday)


  const getOverlappingLevels = (sessions, date) => {
    if (!sessions.length) return 1;
    const sessionToRemove = sessions[0];
    const restOfSessions = sessions.filter((p) => p.id !== sessionToRemove.id);
    const overlappingSessions = getOverlappingItems(sessions, sessionToRemove, date)

    let currentLevel=1
    if(overlappingSessions.length===1) currentLevel=0
    return currentLevel + getOverlappingLevels(restOfSessions,date);
  };

  const getOverlappingItems = (sessions, session, date) => {
    const startOfDay = dayjs(date).hour(0).minute(0).second(0)
    const endOfDay = dayjs(date).hour(24).minute(0).second(0)
    return sessions.filter(p => {
      const pStart = dayjs(p.start.formattedFull).isSame(dayjs(date), 'day') ? dayjs(p.start.formattedFull) : startOfDay;
      const pEnd = dayjs(p.end.formattedFull).isSame(dayjs(date), 'day') ? dayjs(p.end.formattedFull) : endOfDay
      const sessionStart = dayjs(session.start.formattedFull).isSame(dayjs(date), 'day') ? dayjs(session.start.formattedFull) : startOfDay;
      const sessionEnd = dayjs(session.end.formattedFull).isSame(dayjs(date), 'day') ? dayjs(session.end.formattedFull) : endOfDay

      const isOverlapping =
        (pEnd.isAfter(sessionStart) && pEnd.isSameOrBefore(sessionEnd)) ||
        (pStart.isSameOrAfter(sessionStart) && pStart.isBefore(sessionEnd)) ||
        (pStart.isSameOrBefore(sessionStart) && pEnd.isSameOrAfter(sessionEnd));
      return isOverlapping
    })
  }

  const getPosisitonInfo = (sessions,date) => {
    const sessionWidthShares=Array(sessions.length)
    const sessionLeftShares=Array(sessions.length)
    const overlappingLevels = sessions.map((session) => {// overlapping level of each session
      const overlappingItems = getOverlappingItems(sessions, session, date)
      return getOverlappingLevels(overlappingItems, date)
    })
    const maxOverlappingLevels=overlappingLevels.reduce((a,b)=>Math.max(a,b)) // to decide width of each share

    //get sessionWidthShares
    sessions.forEach((session, index) => {
      const overlappingItems = getOverlappingItems(sessions, session, date)
      if(overlappingItems.length===maxOverlappingLevels){
        overlappingItems.forEach((overlappingItem)=>{
          const indexInSession=sessions.findIndex((p)=>p.id===overlappingItem.id)
          sessionWidthShares[indexInSession]=1
        })
      }
      if(overlappingItems.length===1) sessionWidthShares[index]=maxOverlappingLevels
      if(!sessionWidthShares[index]){
        let levels=overlappingLevels[index]
        let totalRemainingWidth=maxOverlappingLevels

        overlappingItems.forEach((overlappingItem)=>{
          const indexInSession=sessions.findIndex((p)=>p.id===overlappingItem.id)
          if(sessionWidthShares[indexInSession]){
            levels=levels-1
            totalRemainingWidth=totalRemainingWidth-sessionWidthShares[indexInSession]
          }
        })
        sessionWidthShares[index] = totalRemainingWidth / levels // undecided sessions share remaining width equally
      }
    })

    //get sessionLeftShares
    sessions.forEach((session, index) => {
      const overlappingItems = getOverlappingItems(sessions, session, date)
      const sessionIndexInOverlappingItems=overlappingItems.findIndex((p)=>p.id===session.id)
      const overlappingIndexes=overlappingItems.map((p)=>sessions.findIndex((q)=>q.id===p.id))
      const widthOfOverlappingItems = sessionWidthShares.filter((p, index) => overlappingIndexes.includes(index))
      const left = widthOfOverlappingItems.filter((p, index) => index < sessionIndexInOverlappingItems).reduce((a, b) => a + b, 0) // sum of width share of sessions at left of session
      sessionLeftShares[index] = left
    })

    return{
      sessionWidthShares,
      sessionLeftShares,
      eachShare:90/maxOverlappingLevels
    }
  }

  useEffect(() => {
    if (!sessions?.length) return;
    const { sessionWidthShares, sessionLeftShares, eachShare } = getPosisitonInfo(sessions, date)
    setSessionsWithPositionInfo(
      sessions.map((session, index) => {

        let sessionStart = dayjs(session.start.formattedFull);
        if (!sessionStart.isSame(dayjs(date), "day")) {
          sessionStart = dayjs(date).hour(0).minute(0).second(0);
        }
        const startOfDay = sessionStart.startOf('day')
        const minutesPassedSinceStartOfDay = dayjs.duration(sessionStart.diff(startOfDay)).asMinutes()

        const durationOfDay = dayjs.duration(dayjs(session.end.formattedFull).diff(sessionStart)).asMinutes()
        const bottomMintues = ((1440 - minutesPassedSinceStartOfDay - durationOfDay) / 1440) * 100

        return {
          ...session,
          top: `${(minutesPassedSinceStartOfDay / 1440) * 100 + 5}%`,
          left: `${sessionLeftShares[index] * eachShare + 10}%`,
          right: `${90 - sessionLeftShares[index] * eachShare - sessionWidthShares[index] * eachShare}%`,
          bottom: `${bottomMintues < 0 ? -5 : (bottomMintues - 5)
            }%`,
        };
      })
    );


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  if (!calendarDayViewInfo?.sessions?.length) return <Empty description={`No session on ${dayjs(date).format("MMMM D YYYY")}`}/>;
  return (
    <div style={{ marginTop: "6rem" }}>
      <div className="text-center h3">
        {dayjs(date).format("MMMM D YYYY dddd")}
      </div>
      <div
        className="d-flex justify-content-between mt-5"
        style={{ height: "1000px" }}
      >
        <style>
          {`
        .hour-item{
          position:absolute;
        }
        .hourText{
          margin-top:-10px
        }
        .grid-item{
          position:absolute;
          border-top-style:solid;
          border-top-width:thin;
          border-top-color:lightgray;
        }
        .current-time{
          position:absolute;
          border-top-style:solid;
          border-top-width:thick;
          border-top-color:red;
        }
        .event-item{
          position:absolute;
          border-left:solid;
          border-left-width:wide;
          opacity:0.8
        }
        .poster{
          background-color:var(--presentation-poster);
          border-color:darkblue;
        }
        .expo{
          background-color:var(--presentation-expo);
          border-color:darkgreen;
        }
        .qa{
          background-color:var(--presentation-qa);
          border-color:darksalmon;
        }
        .presentation{
          background-color:var(--presentation-presentation);
          border-color:darkred;
        }
      `}
        </style>
        <div className="d-flex">
          {[...Array(24).keys()].map((h, index) => {
            return (
              <div
                className="hour-item"
                style={{ top: `${(index / 24) * 100 + 5}%` }}
                key={index}
              >
                <div className="hourText">
                  {dayjs().hour(h).minute(0).format("h A")}
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex align-items-stretch">
          {[...Array(24).keys()].map((h, index) => {
            return (
              <div
                key={index}
                className="grid-item"
                style={{
                  top: `${(index / 24) * 100 + 5}%`,
                  right: "0%",
                  left: "10%",
                }}
              />
            );
          })}
          {dayjs(date).isToday() && <div
            key="line for current time"
            className="current-time"
            style={{
              top: `${(dayjs().diff(dayjs().hour(0).minute(0).second(0), "m") / (24 * 60)) * 100 + 5}%`,
              right: "0%",
              left: "10%",
            }}
          />}
          {sessionsWithPositonInfo?.map((session) => {
            return (
              <Link to={`/session/${session.id}`} key={session.id}>
                <div
                  className={`event-item ${session.type ?? "poster"}`}
                  style={{
                    top: `${session.top}`,
                    left: `${session.left}`,
                    right: `${session.right}`,
                    bottom: `${session.bottom}`,
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="d-flex flex-column ml-2"
                    style={{ overflow: "hidden", whiteSpace: "nowrap", color:"black" }}
                  >
                    <span>{`${session.title} ${session.description}`}</span>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CalendarOneDayView2;
