import { Form, Input, Modal, Space, Table, Typography, Checkbox } from "antd";
import { DeleteFilled, EditFilled, SaveFilled } from "@ant-design/icons";
import 'antd/lib/table/style/index.css';
import 'antd/lib/space/style/index.css';
import 'antd/lib/switch/style/index.css';
import { useEffect, useState } from "react";
// import { useState } from "react";
// import { Alert, Button, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
//import { useHistory, useLocation } from "react-router-dom";
import { selectRoot, addGroup, setGlobalAlert, deleteGroup, updateGroup } from "../../rootSlice";
import Search from "antd/lib/input/Search";

const { Text } = Typography;

const EditableCell = ({
  editable,
  children,
  dataIndex,
  record,
  editing,
}) => {

  const renderCell = () => {
    if (editable) {
      if (editing) {
        if (dataIndex === "visible") {
          return <Form.Item name={dataIndex} valuePropName="checked">
            <Checkbox />
          </Form.Item>
        } else {
          return <Form.Item name={dataIndex}>
            <Input />
          </Form.Item>
        }
      } else {
        if (dataIndex === "visible") {
          return <Checkbox disabled={true} checked={record[dataIndex]} />
        } else {
          return children;
        }
      }
    } else {
      return children;
    }
  }

  return (
    <td>
      {
        renderCell()
      }
    </td>
  )
};

const EditMenu = ({ show, setShow }) => {
  const { conferenceGroupInfo, userInfo, dynamicMenus } = useSelector(selectRoot);
  const [showModal, setShowModal] = useState(show)
  const [menus, setMenus] = useState(dynamicMenus);
  const dispatch = useDispatch()
  const [groupIdToAdd, setGroupIdToAdd] = useState(
    `${conferenceGroupInfo.id}/groupIdHere`
  );
  const [addGroupLoading, setAddGroupLoading] = useState(false)
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();

  const isEditing = (record) => { return record.groupId === editingKey };

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.groupId);
  }

  const properties = [
    {
      title: 'MenuText', dataIndex: 'text', editable: true, width: "40%", shouldCellUpdate: (record, prevRecord) => true, onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'text',
        title: 'MenuText',
        editing: isEditing(record)
      })
    },
    { title: 'Group Id', dataIndex: 'groupId', width: "40%", shouldCellUpdate: (record, prevRecord) => true },
    {
      title: 'Visible', dataIndex: 'visible', editable: true, width: "5%", shouldCellUpdate: (record, prevRecord) => true, onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'visible',
        title: 'Visible',
        editing: isEditing(record)
      })
    },
    {
      title: 'Order', dataIndex: 'order', editable: true, width: "5%", shouldCellUpdate: (record, prevRecord) => true, onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'order',
        title: 'Order',
        editing: isEditing(record)
      })
    },
    {
      title: 'Action', dataIndex: 'operation', width: "10%", render: (_, record) => {
        const editable = isEditing(record);
        return editable
          ? (<Space>
            <SaveFilled onClick={() => handleSave(record.groupId)} />
            <DeleteFilled style={{ color: "#f5222d" }} onClick={() => handleDelete(record)} />
          </Space>)
          : (<EditFilled onClick={() => edit(record)} />)
      }
    }
  ]

  const handleDelete = (record) => {
    dispatch(deleteGroup({ groupIdToDelete: record.groupId, userToken: userInfo.token }))
  }

  const handleAddMenuGroup = () => {
    setAddGroupLoading(true);
    if (groupIdToAdd.includes(" ")) {
      dispatch(setGlobalAlert({ type: "error", content: "group id can't contain space" }));
      return;
    }
    if (!groupIdToAdd.startsWith(`${conferenceGroupInfo.id}/`)) {
      dispatch(setGlobalAlert({ type: "error", content: "group id does not match parent group" }));
      return;
    }
    dispatch(
      addGroup({ groupIdToAdd: groupIdToAdd, userToken: userInfo.token })
    );
  }

  const handleSave = (groupId) => {
    const originalGroup = menus.filter(p => p.groupId === editingKey)[0];
    const formValues = form.getFieldsValue();
    const updatedGroupWeb =
    {
      ...originalGroup.rawWeb,
      menuText: formValues.text,
      order: formValues.order,
      visible: formValues.visible,
    };
    dispatch(
      updateGroup({ groupIdToUpdate: groupId, web: updatedGroupWeb, userToken: userInfo.token })
    )
  }

  useEffect(() => {
    setShowModal(show)
  }, [show])

  useEffect(() => {
    setAddGroupLoading(false);
    setMenus(dynamicMenus.map(p => [p, p.submenus]).flat(2).filter(q => q))
  }, [dynamicMenus])

  return (
    <Modal
      title="Edit Menu"
      maskClosable={false}
      visible={showModal}
      closable={false}
      destroyOnClose={true}
      okText={null}
      okButtonProps={{ style: { display: "none" } }}
      cancelText="Close"
      onCancel={() => { setShow(false); setGroupIdToAdd(`${conferenceGroupInfo.id}/groupIdHere`) }}
      onOk={() => { }}
      onClick={e => e.stopPropagation()}
      zIndex={1029}
      width="fit-content"
      bodyStyle={{ minWidth: "500px" }}
    >
      <Space direction="vertical" className="w-100">
        <Text strong>Add a New Page</Text>
        <Search
          placeholder={``}
          enterButton="Add"
          value={groupIdToAdd}
          onChange={e => setGroupIdToAdd(e.target.value)}
          onSearch={handleAddMenuGroup}
          loading={addGroupLoading}
        />
        {
          menus?.length > 0 &&
          <>
            <Text strong>Edit Existing Pages</Text>
            <style>
              {
                `
              .ant-form-item{
                margin:0;
              }
            `
              }
            </style>
            <Form form={form} component={false}>
              <Table
                rowKey={(record) => record.groupId}
                components={{ body: { cell: EditableCell } }}
                dataSource={menus}
                columns={properties}
                pagination={false}
                style={{ maxHeight: "500px", overflowY: "scroll" }}
              />
            </Form>
          </>
        }
      </Space>
    </Modal>
  )
};

export default EditMenu;
