import { useEffect, useState } from "react";
import { Form, FormGroup, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

const Markdown = ({ content, option, escapeHtml = false }) => {
  return (
    // should center if used with other components in a page;
    // will break markdown layout if always center
    <>
      {option?.center === true ? (
        <Row className="justify-content-center my-5 markdown" style={{ margin: 0 }}>
          <ReactMarkdown source={content} escapeHtml={escapeHtml} />
        </Row>
      ) : (
          <ReactMarkdown className="markdown" source={content} escapeHtml={escapeHtml} />
        )}
    </>
  );
};

export const MarkdownOptionEdit = ({ option, onOptionChange }) => {
  const [center, setCenter] = useState(option?.center ?? false)

  useEffect(() => {
    onOptionChange({ center: center })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center])

  return (
    <Form>
      <FormGroup as={Row}>
        <Form.Label className="mr-5 ml-2">Center:</Form.Label>
        <Form.Check type="checkbox" checked={center} onChange={e => setCenter(e.target.checked)} />
      </FormGroup>
    </Form>
  )
}

export default Markdown
