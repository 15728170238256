import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRoot, login } from "../rootSlice";
import { Button, Form, Input, Checkbox, Modal } from 'antd';
import 'antd/lib/form/style/index.css';
import 'antd/lib/checkbox/style/index.css';
import 'antd/lib/input/style/index.css';

const LoginModal = ({ show, setShow }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [showModal, setShowModal] = useState(show)
    const { userInfo, userPreference, globalAlert } = useSelector(selectRoot)


    const [loginState, setLoginState] = useState({ userId: userPreference.savedUserId ?? "", password: "", saveUserId: userPreference.savedUserId ? true : false });
    const [isLoggingIn, setIsLoggingIn] = useState(false)

    const handleLoginBtnClick = (e) => {
        setIsLoggingIn(true);
        dispatch(login(loginState));
    };

    const handleFieldsChange = (_, allFields) => {
        setLoginState({ userId: allFields[0].value, password: allFields[1].value, saveUserId: allFields[2].value })
    }

    useEffect(() => {
        setIsLoggingIn(false)
    }, [globalAlert])

    useEffect(() => {
        setShowModal(show)
        if (show) {
            form.setFieldsValue({
                userId: userPreference.savedUserId ?? "",
                password: "",
                saveUserId: userPreference.savedUserId ? true : false
            });
            setLoginState({
                userId: userPreference.savedUserId ?? "",
                password: "",
                saveUserId: userPreference.savedUserId ? true : false
            });
            setIsLoggingIn(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    useEffect(() => {
        if (userInfo?.token) {
            setIsLoggingIn(false);
        }
    }, [userInfo])

    return (
        <>
            <style>
                {
                    `
                    .ant-modal-close{
                        outline:none!important;
                    }
                `
                }
            </style>
            <Modal
                title="Log in"
                maskClosable={false}
                visible={showModal}
                destroyOnClose={true}

                onCancel={() => { setShow(false) }}
                onOk={() => { setShow(false) }}
                footer={null}
                centered
                zIndex={1029}
            >
                <Form onFieldsChange={handleFieldsChange} form={form}>
                    <Form.Item label="Username" name="userId">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Password" name="password">
                        <Input.Password />
                    </Form.Item>
                    <Form.Item name="saveUserId" valuePropName="checked" >
                        <Checkbox>remember username</Checkbox>
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" disabled={!(loginState.userId && loginState.password)} loading={isLoggingIn} onClick={handleLoginBtnClick}>
                            Submit
              </Button>
                    </Form.Item>
                </Form>
                {!userPreference.savedUserId && <a href="https://openreview.net/signup" target="_blank" rel="noreferrer">New to OpenReview? Signup</a>}
            </Modal>
        </>
    )
}

export default LoginModal;
