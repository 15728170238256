import { Empty, Tabs, Tag } from "antd";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PaperCards from "./components/session/PaperCards";
import Error from "./Error";
import api from "./lib/api-client";
import { selectRoot, setGlobalAlert, setUserPreference } from "./rootSlice";

import 'antd/lib/tag/style/index.css';
import 'antd/lib/tabs/style/index.css';
import { exportICS, getTagColor } from "./lib/utility";
import SessionCards from "./components/session/SessionCard";
import { CalendarOutlined } from '@ant-design/icons';

const RecommendationTab = ({ invitation }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [items, setItems] = useState([])
    const { userInfo, tagsChanged } = useSelector(selectRoot)
    const dispatch = useDispatch();

    const getRecommendedItems = async () => {
        try {
            const result = await api.get("/tags", { invitation: invitation, details: "forumContent" }, { accessToken: userInfo.token })
            setItems(result.tags?.sort((p, q) => q.tag - p.tag)?.map(r => r.forumContent))
        } catch (error) {
            dispatch(setGlobalAlert({ type: "error", message: error.message }))
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getRecommendedItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        //getRecommendedItems() // recommended notes won't change because of tag
    }, [tagsChanged])

    return <PaperCards notes={items} isLoading={isLoading} empty={<Empty description="No Recommended paper found" />} />
}

const BookmarkTab = ({ invitation }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [bookmarkedSessions, setBookmarkedSessions] = useState([])
    const [bookmarkedNotes, setBookmarkedNotes] = useState([])
    const { userInfo, conferenceGroupInfo, sessionsInfoFormatted, tagsChanged } = useSelector(selectRoot)
    const dispatch = useDispatch();

    const getBookmarkedItems = async () => {
        try {
            // result has bookmarked sessions and papers
            const result = await api.get("/tags", { invitation: invitation, details: "forumContent" }, { accessToken: userInfo.token })
            //setBookmarkedSessions(result.tags?.map(p=>p.forumContent).filter(q=>q.invitation===`${conferenceGroupInfo.id}/-/Session`))
            const bookmarkedSessionsIds = result.tags?.filter(p => p.forumContent?.invitation === `${conferenceGroupInfo.id}/-/Session`).map(q => q.forumContent.id)
            setBookmarkedSessions(sessionsInfoFormatted.filter(p => bookmarkedSessionsIds.includes(p.id)))
            setBookmarkedNotes(result.tags?.map(p => p.forumContent).filter(q => q.invitation === `${conferenceGroupInfo.id}/-/Presentation`))
        } catch (error) {
            dispatch(setGlobalAlert({ type: "error", message: error.message }))
        } finally {
            setIsLoading(false)
        }
    }

    const handleBookmarkDownload = (type) => {
        if (type === "sessions") {
            exportICS({
                eventsToExport: bookmarkedSessions.map(session => {
                    return {
                        start: session.start.unixmm, 
                        end: session.end.unixmm, 
                        title: session.title, 
                        description: session.description
                    }
                }), saveFileName: "bookmarked sessions"
            })
        }
        if (type === "papers") {
            exportICS({
                eventsToExport: bookmarkedNotes.map(note => {
                    return {
                        start: note?.content?.start, 
                        end: note?.content?.end, 
                        title: note?.content?.title, 
                        description: note?.content?.['TL;DR']
                    }
                }), saveFileName: "bookmarked papers"
            })
        }
    }

    useEffect(() => {

        getBookmarkedItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getBookmarkedItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsChanged])

    return (
        <Container>
            <Row className="d-flex flex-column mb-5">
                <div>
                    <span>Bookmarked Sessions</span>
                    {bookmarkedSessions.length!==0 && <CalendarOutlined style={{ marginLeft: "0.5rem", verticalAlign: "text-bottom" }} onClick={() => handleBookmarkDownload("sessions")} />}
                </div>
                <SessionCards sessions={bookmarkedSessions} isLoading={isLoading} empty={<Empty description="No Bookmarked sessions found" />} />
            </Row>
            <Row>
                <div>
                    <span>Bookmarked Papers</span>
                    {bookmarkedNotes.length!==0 && <CalendarOutlined style={{ marginLeft: "0.5rem", verticalAlign: "text-bottom" }} onClick={() => handleBookmarkDownload("papers")} />}
                </div>
                <PaperCards notes={bookmarkedNotes} isLoading={isLoading} empty={<Empty description="No Bookmarked paper found" />} />
            </Row>
        </Container>
    )
}

const TagTab = ({ invitation }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [taggedSessions, setTaggedSessions] = useState([])
    const [taggedNotes, setTaggedNotes] = useState([])
    const { userInfo, conferenceGroupInfo, sessionsInfoFormatted, tagsChanged } = useSelector(selectRoot)
    const [allTags, setAllTags] = useState([])
    const [selectedTag, setSelectedTag] = useState('')
    const dispatch = useDispatch();

    const handleTagClick = (tag) => {
        if (selectedTag === tag) {
            setSelectedTag('');
            return
        }
        setSelectedTag(tag);
    }

    const getAllTaggedItems = async () => {
        try {
            // result has bookmarked sessions and papers
            const result = await api.get("/tags", { invitation: invitation, details: "forumContent" }, { accessToken: userInfo.token })
            //setBookmarkedSessions(result.tags?.map(p=>p.forumContent).filter(q=>q.invitation===`${conferenceGroupInfo.id}/-/Session`))
            const taggedSessionsIds = result.tags?.filter(p => p.forumContent?.invitation === `${conferenceGroupInfo.id}/-/Session`).map(q => q.forumContent.id)
            setTaggedSessions(sessionsInfoFormatted.filter(p => taggedSessionsIds.includes(p.id)))
            setTaggedNotes(result.tags?.map(p => p.forumContent)
                .filter(q => q.invitation === `${conferenceGroupInfo.id}/-/Presentation`)
                .filter((r, index, self) => // remove duplicates caused by notes having multiple tags
                    index === self.findIndex((s) => (s.id === r.id))));
        } catch (error) {
            dispatch(setGlobalAlert({ type: "error", message: error.message }))
        } finally {
            setIsLoading(false)
        }
    }
    const getAllTags = async () => {
        try {
            const result = await api.get("/tags", { invitation: invitation }, { accessToken: userInfo.token })
            setAllTags([...new Set(result.tags.map(p => p.tag))])
        } catch (error) {
            dispatch(setGlobalAlert({ type: "error", message: error.message }))
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const getTaggedItemsByTag = async () => {
            try {
                // result has bookmarked sessions and papers
                const result = await api.get("/tags", { tag: selectedTag, details: "forumContent" }, { accessToken: userInfo.token })
                //setBookmarkedSessions(result.tags?.map(p=>p.forumContent).filter(q=>q.invitation===`${conferenceGroupInfo.id}/-/Session`))
                const bookmarkedSessionsIds = result.tags?.filter(p => p.forumContent?.invitation === `${conferenceGroupInfo.id}/-/Session`).map(q => q.forumContent.id)
                setTaggedSessions(sessionsInfoFormatted.filter(p => bookmarkedSessionsIds.includes(p.id)))
                setTaggedNotes(result.tags?.map(p => p.forumContent).filter(q => q.invitation === `${conferenceGroupInfo.id}/-/Presentation`))
            } catch (error) {
                dispatch(setGlobalAlert({ type: "error", message: error.message }))
            } finally {
                setIsLoading(false)
            }
        }
        if (selectedTag === '') {
            getAllTaggedItems()
            getAllTags()
            return;
        }
        getTaggedItemsByTag()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTag])

    useEffect(() => {
        getAllTaggedItems()
        getAllTags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsChanged])

    return (
        <div>
            <div className="mb-5">
                <p>All tags</p>
                {
                    allTags.length
                        ? allTags.map(tag => {
                            return <Tag style={{ lineHeight: 'auto', fontSize: "large", opacity: `${tag === selectedTag ? '1' : '0.5'}` }} key={tag} color={getTagColor(tag)} onClick={() => handleTagClick(tag)}>{tag}</Tag>
                        })
                        : <Empty style={{ textAlign: 'center' }} description="No Tags" image={null} />
                }
            </div>
            <Row className="d-flex flex-column mb-5 mx-2">
                <p>Tagged Sessions</p>
                <SessionCards sessions={taggedSessions} isLoading={isLoading} empty={<Empty description="No Tagged sessions found" />} />
            </Row>
            <Row className="mx-2">
                <p>Tagged Papers</p>
                <PaperCards notes={taggedNotes} isLoading={isLoading} empty={<Empty description="No Tagged paper found" />} />
            </Row>
        </div>
    )
}

const Bookmarks = () => {
    const { userInfo, conferenceGroupInfo, userPreference } = useSelector(selectRoot);
    const [key, setKey] = useState(userPreference.bookmarkTab ?? "Recommendation");
    const dispatch = useDispatch();

    const handleTabSelect = (activeKey) => {
        setKey(activeKey);
        dispatch(setUserPreference({ name: "bookmarkTab", value: activeKey }))
    }

    if (!Object.keys(conferenceGroupInfo).length) return <Error message="Conference does not exist." extraMessage="Please try again with correct conference id." />
    if (!userInfo?.token) return <Error message="You are not authorized to view this page." extraMessage="Please login." />
    return (
        <>
            <Container>
                {/* <style>
                    {
                        `
                            .nav-item,.nav-link{
                                color:black;
                            }
                        `
                    }
                </style> */}
                <Tabs activeKey={key} type="card" onChange={handleTabSelect}>
                    <Tabs.TabPane key="Bookmark" tab="Bookmarked">
                        <BookmarkTab invitation={`${conferenceGroupInfo.id}/-/Bookmark`} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="Recommendation" tab="Recommended">
                        <RecommendationTab invitation={`${conferenceGroupInfo.id}/-/Recommendation`} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="Tag" tab="Tagged">
                        <TagTab invitation={`${conferenceGroupInfo.id}/-/Tag`} />
                    </Tabs.TabPane>
                </Tabs>
            </Container>
        </>
    )
}

export default Bookmarks