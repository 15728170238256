import { useEffect, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import { useDispatch, useSelector } from "react-redux";
import { selectRoot, fetchSessionNotesByInvitation } from "../../rootSlice";
import { Button, Container, Row, Jumbotron } from "react-bootstrap";
import { Link } from "react-router-dom";

const HappeningNow = () => {
  const [targetDate, setTargetDate] = useState(dayjs());
  const [countDownDuration, setCountDownDuration] = useState(null);
  const [happeningSessions, setHappeningSessions] = useState([]);
  const [upcomingSession, setUpcomingSession] = useState(null);

  const dispatch = useDispatch();
  const { conferenceGroupId, sessionsInfo } = useSelector(selectRoot);

  dayjs.extend(duration);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isBetween);

  const updateTime = () => {
    if (!targetDate) return;
    if (targetDate.isSameOrBefore(dayjs())) return;
    setCountDownDuration(dayjs.duration(targetDate.diff(dayjs())));
  };

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      updateTime();
    }, 1000);
    return () => {
      clearInterval(countDownInterval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDownDuration]);

  useEffect(() => {
    if (!sessionsInfo?.length) return;
    const firstUpcomingSession = sessionsInfo.filter((session) =>
      dayjs().isSameOrBefore(dayjs(Number(session.content.start)))
    )[0];
    const happeningSessions = sessionsInfo.filter((session) =>
      dayjs().isBetween(
        dayjs(Number(session.content.start)),
        dayjs(Number(session.content.end)),
        null,
        "()"
      )
    );

    setUpcomingSession(firstUpcomingSession);
    setHappeningSessions(happeningSessions);

    firstUpcomingSession
      ? setTargetDate(dayjs(Number(firstUpcomingSession.content.start)))
      : setTargetDate(null);
  }, [sessionsInfo]);

  useEffect(() => {
    if (!targetDate) return;
    setCountDownDuration(dayjs.duration(targetDate.diff(dayjs.now)));
  }, [targetDate]);

  useEffect(() => {
    if (sessionsInfo.length) return;
    if (conferenceGroupId)
      dispatch(fetchSessionNotesByInvitation(`${conferenceGroupId}/-/Session`));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Jumbotron fluid className="w-100">
      <Container className="text-center mb-5">
        <Row>
          <h3 className="float-left">Happening</h3>
        </Row>
        <Row className="d-flex justify-content-center">
          <h5>Now:</h5>
        </Row>
        <Row className="d-flex flex-column">
          {happeningSessions.length ? (
            happeningSessions.map((session) => {
              return (
                <Container key={session.id}>
                  <Row className="justify-content-center">
                    <Link to={`/session/${session.id}`}>
                      <Button variant="info">{session.content.title}</Button>
                    </Link>
                  </Row>
                  <Row className="justify-content-center">
                    <strong>{session.content.description}</strong>
                  </Row>
                </Container>
              );
            })
          ) : (
            <Row className="justify-content-center">
              <h6>There's no ongoing sessions</h6>
            </Row>
          )}
        </Row>
        {upcomingSession && (
          <>
            <hr />
            <Row className="d-flex justify-content-center">
              <h5>Soon:</h5>
            </Row>
            <Row className="justify-content-center">
              <Link to={`/session/${upcomingSession?.id}`}>
                <Button variant="info">
                  {upcomingSession?.content?.title}
                </Button>
              </Link>
            </Row>
            <Row className="justify-content-center">
              <strong>{upcomingSession?.content?.description}</strong>
            </Row>
            <Row className="justify-content-center mt-2">
              <Container style={{ width: "40%" }}>
                <Row className="justify-content-between">
                  <CountDownSingle
                    number={countDownDuration?.days()}
                    unitText="Day"
                  />
                  <CountDownSingle
                    number={countDownDuration?.hours()}
                    unitText="Hour"
                  />
                  <CountDownSingle
                    number={countDownDuration?.minutes()}
                    unitText="Minute"
                  />
                  <CountDownSingle
                    number={countDownDuration?.seconds()}
                    unitText="Second"
                  />
                </Row>
              </Container>
            </Row>
          </>
        )}
      </Container>
    </Jumbotron>
  );
};

const CountDownSingle = ({ number, unitText }) => {
  return (
    <div className="d-flex flex-column">
      <p>{number}</p>
      {`${number === 1 || number === 0 ? unitText : `${unitText}s`}`}
    </div>
  );
};

export default HappeningNow;
